import {
  Dialog,
  useTheme,
  useMediaQuery,
  Typography,
  Stack,
  Box,
  Button,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { pxToRem } from "px2rem2px";
import IconBtnCircular from "../../components/IconBtnCircular";
import VideoPlayer from "../course/components/VideoPlayer";

import { ReactComponent as Close } from "../../images/close.svg";
import { subscribeToFreeCourse } from "../../api/course";
import { useNavigate, useParams } from "react-router-dom";
import RichTextDisplay from "../../components/displayRichText";
import { formatDateDuration, isTodayBetweenDates } from "../../utils/format";
import { useAuth } from "../../utils/AuthContext";
import LoginModal from "../auth/LoginModal";
import { useModal } from "mui-modal-provider";
import LoginSubscribeModal from "./LoginSubscribeModal";

const TypographyStyled = styled(Typography)({
  fontSize: "12px",
  fontWeight: 600,
  color: "#BFBEBB",
});

const Hr = styled(Divider)({
  fontSize: "14px",
  fontWeight: 600,
  color: "#BFBEBB",
  margin: "30px 0",
  "&::after, &::before": {
    borderTop: "1px solid #BFBEBB",
  },
  "& span": {
    padding: "0 18px",
  },
});

const PreviewModal = (props) => {
  const {id}=useParams()
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { openSubscriptionModal, course, ...restProps } = props;
  const navigate = useNavigate();

  const { checkAuth } = useAuth();
  const isAuthenticated = checkAuth();

  const { showModal, hideModal } = useModal();

  const handleSubscribe = () => {
    if (!isAuthenticated) {
      const modal = showModal(LoginSubscribeModal, {
        openLoginModal: () => {
          hideModal(modal.id);
          // showModal(LoginModal);
          showModal(LoginModal, { onRedirectOpenModal: 'previewModal' });
        },
        loginMessage: "You need to log in first to subscribe to this course.",
        hideModal: () => hideModal(modal.id),
      
      });
    
    } else {
      if (course.is_free) {
        subscribeToFreeCourse(course.id);
        navigate(`/course/${course.slug}`);
      }
      openSubscriptionModal(course.subscription_price, "normal");
    }
  };

  const handleBuy = () => {
    if (!isAuthenticated) {
      const modal = showModal(LoginSubscribeModal, {
        openLoginModal: () => {
          hideModal(modal.id);
          // showModal(LoginModal);
          showModal(LoginModal, { onRedirectOpenModal: 'previewModal' });
        },
        loginMessage: "You need to log in first to buy this course.",
        hideModal: () => hideModal(modal.id),
      });
    } else {
      openSubscriptionModal(course.lifetime_price, "lifetime");
    }
  };

  const isOnSale =
    course?.is_on_sale &&
    isTodayBetweenDates(course?.sale_start_date, course?.sale_end_date);

  return (
    <Dialog
      {...restProps}
      fullScreen={fullScreen}
      scroll="body"
      disableEscapeKeyDown
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          return;
        }
      }}
      sx={{
        "& .MuiPaper-root": {
          maxWidth: fullScreen ? "100%" : "80%",
          width: fullScreen ? "100%" : "80%",
          borderRadius: fullScreen ? 0 : pxToRem(20),
          verticalAlign: "center",
          backgroundColor: "#F7F6F2",
        },
        zIndex: 1500,
      }}
    >
      <Stack flexDirection={{ xs: "column-reverse", md: "row" }}>
        <Stack
          width={{ xs: "100%", md: 300 }}
          bgcolor="#EDECE8"
          textAlign="center"
          p={{ xs: "30px 25px", ssm: "40px 50px" }}
        >
          {course.is_free ? (
            <Button
              onClick={handleSubscribe}
              fullWidth
              variant="yellow"
              fontSize="14px"
              sx={{ height: "54px", mb: "15px" }}
            >
              Subscribe For Free
            </Button>
          ) : (
            <>
              <Button
                onClick={handleSubscribe}
                fullWidth
                variant="yellow"
                fontSize="14px"
                sx={{ height: "54px", mb: "15px" }}
              >
                {/* Subscribe: ${course.subscription_price} */}
                {isOnSale ? (
                  <>
                    <Typography component="span" fontWeight={600}>
                      {course.sale_price <= 0
                        ? "Subscribe For Free"
                        : `Subscribe: $${course.sale_price}`}
                    </Typography>

                    <Typography
                      fontSize="14px"
                      fontWeight="600"
                      color="#242424"
                      sx={{
                        textDecoration: "line-through",
                        marginLeft: 1,
                        // transform: "translateY(1px)",
                      }}
                    >
                      {course.subscription_price > 0 &&
                        `$${course.subscription_price}`}
                    </Typography>
                  </>
                ) : (
                  <Typography component="span" fontWeight={600}>
                    {course.subscription_price <= 0
                      ? "Subscribe For Free"
                      : `Subscribe: $${course.subscription_price}`}
                  </Typography>
                )}
              </Button>
              <TypographyStyled component="p" sx={{marginBottom:"20px", color:"#666666"}}>{`Valid for ${formatDateDuration(
                course.duration
              )}`}</TypographyStyled>
              <Hr sx={{color:"#666666"}}>or</Hr>
              <Button
                onClick={handleBuy}
                fullWidth
                variant="outlined"
                fontSize="14px"
                sx={{
                  height: "54px",
                  mb: "15px",
                  borderWidth: "2px",
                  "&:hover": { borderWidth: "2px" },
                }}
              >
                Buy: ${course.lifetime_price}
              </Button>
              <TypographyStyled component="p" sx={{marginBottom:"20px", color:"#666666"}}>
                Valid Forever
              </TypographyStyled>
            </>
          )}
          <TypographyStyled component="p" textAlign="left" mt="auto">
          * Please note that all purchases are final. No refunds will be provided after a course is purchased, regardless of the circumstances. Make sure to review the course content and details before proceeding with your purchase.
          </TypographyStyled>
        </Stack>

        <Box flex={1} p={{ xs: "30px 25px", ssm: "40px 60px" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            gap="30px"
          >
            <Typography
              fontSize="30px"
              lineHeight="30px"
              fontWeight="900"
              fontFamily="PloniBold"
              letterSpacing="-0.44px"
              textAlign="right"
              sx={{
                wordBreak: "break-word",
                whiteSpace: "normal",
              }}
            >
              {course.name}
            </Typography>
            <IconBtnCircular onClick={() => props.onClose()}>
              <Close color="#026670" />
            </IconBtnCircular>
          </Stack>
          <Typography
            variant="medium"
            component="p"
            textAlign="right"
            m="20px 0 60px"
            sx={{
              wordBreak: "break-word",
              whiteSpace: "normal",
            }}
          >
            {course.subtitle}
          </Typography>
          <VideoPlayer preview={course} noListen={true} id={id}/>
        </Box>
      </Stack>
    </Dialog>
  );
};

export default PreviewModal;
