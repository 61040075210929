import React, { useCallback } from "react";
import {
  Box,
  Button,
  Container,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Tabs,
  Tab,
  Tooltip,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
import Guides from "../../components/Guides";
import Header from "../../components/Header";
import VideoPlayer from "./components/VideoPlayer";
import Sidebar from "./components/Sidebar";
import CourseTabs from "./CourseTabs";
import VideosSection from "../../components/VideosSection";
import BlockBordered from "../../components/BlockBordered";
import LinkWithIcon from "../../components/LinkWithIcon";
import {
  useParams,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { getCourse, getLessons } from "../../api/course";
import { useState, useEffect, useRef } from "react";
import Toast from "../../components/Toast";
import SubscriptionFeedbackModal from "./components/SubscriptionFeedbackModal";
import FooterTabs from "../../components/courses/FooterTabs";
import Testimonial from "../../components/Testimonial";
import TextLink from "../../components/TextLink";
import { ReactComponent as Chevron } from "../../../src/images/chevron.svg";
import OverviewTab from "./OverviewTab";
import shouldNavigateToPresubscribe from "../../utils/subscription";

const Course = () => {
  const { id } = useParams();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [course, setCourse] = useState({});
  const [lessons, setLessons] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [expandedPanel, setExpandedPanel] = useState(false);
  const summaryRef = useRef(null);
  const [bookmarks, setBookmarks] = useState([]);
  const [notes, setNotes] = useState([]);

  const [playIcon, setPlayIcon] = useState(false);
  const [videoEnded, setVideoEnded] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));

  useEffect(() => {
    console.log("playIconstataus", playIcon);
  }, [playIcon]);

  // Redirection
  useEffect(() => {
    if (id && course && course.subscription_status) {
      if (shouldNavigateToPresubscribe(course.subscription_status)) {
        navigate("presubscribe");
      }
    }
  }, [id, course?.subscription_status]);

  //
  const [playingLessonId, setPlayingLessonId] = useState(null);
  const [previousLessonStates, setPreviousLessonStates] = useState({});
  const [nextLecture, setNextLecture] = useState(false);
  // const [switchLesson, setSwitchLesson] = useState(false);
  const [lastLesson, setLastLesson] = useState(false);
  const [blockAutoLectureId, setBlockAutoLectureId] = useState(false);
  const [bookmarkStartTime, setBookmarkStartTime] = useState(null);

  const [fetchCourseAgain, setFetchCourseAgain] = useState(false);
  // start: open actions feedback popups after redirection
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [success, setSuccess] = useState(null);
  const [cancel, setCancel] = useState(null);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const isAssociate = params.get("is_associate");
    const isNormalSubscription = params.get("is_normal_subscription");
    const isGifted = params.get("is_gifted");
    const successParam = params.get("success");
    const cancelParam = params.get("cancel");
    setSuccess(successParam);
    setCancel(cancelParam);

    if (successParam === "yes" || cancelParam === "yes") {
      if (isAssociate === "yes") {
        setModalTitle("Add Partner");
      } else if (isNormalSubscription === "yes") {
        setModalTitle("Course Subscription");
      } else if (isGifted === "yes") {
        setModalTitle("Gift Course");
      }
      setModalOpen(true);
    }
  }, [location]);

  const clearParams = () => {
    const newUrl = window.location.pathname;
    window.history.replaceState(null, "", newUrl);
  };

  const handleClose = () => {
    clearParams();
    setModalOpen(false);
  };
  // end open actions feedback popups after redirection

  // get lessons data
  useEffect(() => {
    const getLessonsData = async () => {
      try {
        const lessonsResponse = await getLessons(id);
        console.log("lessonsResponse", lessonsResponse);
        setLessons(lessonsResponse.data?.lessons);
        if (!blockAutoLectureId) {
          if (
            lessonsResponse.data?.lessons[0].status === "expired" ||
            lessonsResponse.data?.lessons[0].status === "paused"
          ) {
            setPlayingLessonId(null);
          } else {
            setPlayingLessonId(lessonsResponse.data?.current_lesson?.id);
          }
        }
        if (lessonsResponse.data?.lessons.length > 0) {
          const lastLessonId =
            lessonsResponse.data?.lessons[
              lessonsResponse.data?.lessons.length - 1
            ].id;
          setLastLesson(playingLessonId === lastLessonId);
        }
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setBlockAutoLectureId(false);
      }
    };
    getLessonsData();
  }, [id, playingLessonId]);

  const getCourseData = async () => {
    try {
      const courseResponse = await getCourse(id);
      const courseData = courseResponse.data;
      setCourse(courseData);
    } catch (error) {
      console.error("Error fetching course data:", error);
    }
  };

  useEffect(() => {
    getCourseData();
  }, [id, bookmarks, notes, nextLecture, playingLessonId, fetchCourseAgain]);

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState({});

  // next lecture
  const handleNextLectureClick = () => {
    setBlockAutoLectureId(true);
    if (playingLessonId !== null) {
      setBookmarkStartTime(null);
      const currentLessonIndex = lessons.findIndex(
        (lesson) => lesson.id === playingLessonId,
      );
      const nextLesson = lessons[currentLessonIndex + 1];
      if (nextLesson && nextLesson?.status !== "locked") {
        setPlayingLessonId(nextLesson.id);
      } else if (nextLesson?.status === "locked") {
        setToastOpen(true);
        setToastMessage(nextLesson.hours_remaining);
      }
    }
  };

  const openResourcesTab = () => {
    setActiveTab(4);
    setExpandedPanel("panel1");
    setTimeout(() => {
      summaryRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 650); // v>600
  };

  const [selectedLesson, setSelectedLesson] = useState(null);

  const handleLessonClick = (lesson) => {
    if (lesson?.status !== "paused" && lesson?.status !== "expired") {
      setSelectedLesson(lesson);
    }
    //alert("called here")
  };

  useEffect(()=>{
    console.log("selectedLesson",selectedLesson)
  },[selectedLesson])


  const updateLessons = (updatedLessons) => {
    setLessons(updatedLessons);
  };

  const completedCount = lessons?.filter(
    (lesson) => lesson.progress > 90,
  ).length;
  const totalCount = lessons?.length;
  const completedStatus = course.user_subscription?.id
    ? `${completedCount}/${totalCount} Completed`
    : "";

  const isCourseCompleted =
    totalCount !== 0 && completedCount / totalCount === 1;

  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const handleTabClick = (event, newValue) => {
    setOpenDropdownIndex((prevOpenDropdown) =>
      prevOpenDropdown === newValue ? null : newValue,
    );
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div role="tabpanel" hidden={value !== index} {...other}>
        {value === index && (
          <Box display="flex" flexDirection="column">
            {children}
          </Box>
        )}
      </div>
    );
  }
  const lessons_count = lessons?.length;

  useEffect(() => {
    console.log("playingLessonId:", playingLessonId);
  }, [course]);

  // bookmark jump to latest progress
  const [expiredSubToast, setExpiredSubToast] = useState(false);
  const jumpToLatestProgress = () => {
    if (course.user_subscription?.status !== "Active") {
      setExpiredSubToast(true);
      return;
    }
    setBookmarkStartTime(null);
    let latestLesson = null;
    setBlockAutoLectureId(true);

    course?.lessons.forEach((lesson) => {
      if (lesson.watch_time > 0) {
        latestLesson = lesson?.id;
      }
    });
    if (latestLesson) {
      setPlayingLessonId(latestLesson);
    }
  };
  // bookmarks
  const scrollToCourseRef = useRef(null);
  const jumpToBookMark = (timeStamp, lessonId) => {
    if (course.user_subscription?.status !== "Active") {
      setExpiredSubToast(true);
      return;
    }

    setBlockAutoLectureId(true);
    setPlayingLessonId(lessonId);
    setBookmarkStartTime(timeStamp);
    if (scrollToCourseRef && scrollToCourseRef.current) {
      scrollToCourseRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [showStickyBox, setShowStickyBox] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const containerTop = containerRef.current.getBoundingClientRect().top;

        if (containerTop <= 35 && !showStickyBox) {
          setShowStickyBox(true);
        }

        if (containerTop > 35 && showStickyBox) {
          setShowStickyBox(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showStickyBox]);

  const currentPlayingLesson = course?.lessons?.find(
    (lesson) => lesson.id === playingLessonId,
  );

  const isEmptyRichText = useCallback(
    (text) => text === "<p><br></p>" || text === "",
    [],
  );

  const hasContent =
    !isEmptyRichText(currentPlayingLesson?.summary) ||
    !isEmptyRichText(currentPlayingLesson?.refs) ||
    (currentPlayingLesson?.files && currentPlayingLesson.files.length > 0);

  console.log("MyComponent is rendering1");

  return (
    <>
      <Toast
        open={expiredSubToast}
        onClose={() => setExpiredSubToast(false)}
        message="Your enrollment is not active"
      />
      <Box position="relative" zIndex={3}>
        <Guides color="rgba(0,0,0,0.02)" zIndex={-1} pb={pxToRem(30)} />
        <Header />
        <Box className="header-padding"></Box>
      </Box>
      {showStickyBox && (
        <Box
          sx={{
            height: "35px",
            position: "fixed",
            top: 0,
            backgroundColor: "#026670",
            zIndex: "3",
            width: "100%",
            display: { ssm: "none" },
          }}
        ></Box>
      )}
      <Box
        className="header-padding"
        position={{ xs: "sticky", ssm: "relative" }}
        mt={{ md: pxToRem(40) }}
        display={"flex"}
        flexDirection={"column"}
        zIndex={{ xs: "3", ssm: "2" }}
        sx={{ top: { xs: "35px", ssm: "auto" } }}
        ref={containerRef}
      >
        <Container
          disableGutters
          sx={{ display: { xs: "none", ssm: "block" } }}
        >
          <Stack
            className="rtl-section"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="space-between"
            pb={{ xs: 0, ssm: pxToRem(36) }}
            columnGap={pxToRem(20)}
          >
            <Box
              sx={{
                width: "100%",
                backgroundColor: { xs: "#F7F6F2", ssm: "unset" },
                pb: { xs: pxToRem(10), ssm: 0 },
              }}
            >
              {course && course?.name ? (
                <Typography
                  variant="sectionTitleHebrew"
                  fontSize={{
                    xs: pxToRem(30),
                    ssm: pxToRem(35),
                    md: pxToRem(40),
                  }}
                  lineHeight={pxToRem(40)}
                >
                  {course.name}
                </Typography>
              ) : (
                <Skeleton variant="text" width="70%" height={pxToRem(50)} />
              )}
              <BlockBordered>
                {course && course?.subtitle ? (
                  <Tooltip title={mobile_ssm ? course.subtitle : ""}>
                    <Typography
                      fontFamily="PloniDBold"
                      fontSize={pxToRem(20)}
                      fontWeight="600"
                      color="#666564"
                      whiteSpace={{ xs: "nowrap", ssm: "normal" }}
                      overflow={{ xs: "hidden", ssm: "visible" }}
                      textOverflow={{ xs: "ellipsis", ssm: "clip" }}
                      paddingLeft={pxToRem(20)}
                      ref={scrollToCourseRef}
                    >
                      {course?.subtitle}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Skeleton variant="text" width="40%" height={pxToRem(30)} />
                )}
              </BlockBordered>
            </Box>
            {!mobile_ssm && (
              <LinkWithIcon to="/courses" sx={{ marginTop: pxToRem(14) }}>
                Back
              </LinkWithIcon>
            )}
          </Stack>
        </Container>
        <Container sx={{ px: { xs: 0, md: pxToRem(30) } }} disableGutters>
          <VideoPlayer
            preview={course}
            selectedLesson={selectedLesson}
            openResourcesTab={openResourcesTab}
            setPlayIcon={setPlayIcon}
            playIcon={playIcon}
            setVideoEnded={setVideoEnded}
            videoEnded={videoEnded}
            setNextLecture={setNextLecture}
            nextLecture={nextLecture}
            lastLesson={lastLesson}
            isLoading={isLoading}
            id={id}
            bookmarkStartTime={bookmarkStartTime}
            handleNextLectureClick={handleNextLectureClick}
            showStickyBox={showStickyBox}
          />
        </Container>
      </Box>
      <Container disableGutters>
        <Stack
          className="rtl-section"
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="space-between"
          pb={{ xs: 0, ssm: pxToRem(36) }}
          sx={{ display: { xs: "block", ssm: "none" } }}
        >
          <Box
            sx={{
              width: "100%",
              backgroundColor: { xs: "#F7F6F2", ssm: "unset" },
              pb: { xs: pxToRem(10), ssm: 0 },
              pt: { xs: pxToRem(10), ssm: 0 },
            }}
          >
            {course && course?.name ? (
              <Typography
                variant="sectionTitleHebrew"
                fontSize={{
                  xs: pxToRem(30),
                  ssm: pxToRem(35),
                  md: pxToRem(40),
                }}
                sx={{ wordBreak: "break-word" }}
                lineHeight={pxToRem(40)}
              >
                {course.name}
              </Typography>
            ) : (
              <Skeleton variant="text" width="70%" height={pxToRem(50)} />
            )}
            <BlockBordered>
              {course && course?.subtitle ? (
                <Tooltip title={mobile_ssm ? course.subtitle : ""}>
                  <Typography
                    fontFamily="PloniDBold"
                    fontSize={pxToRem(20)}
                    fontWeight="600"
                    color="#666564"
                    whiteSpace={{ xs: "nowrap", ssm: "normal" }}
                    overflow={{ xs: "hidden", ssm: "visible" }}
                    textOverflow={{ xs: "ellipsis", ssm: "clip" }}
                    paddingLeft={pxToRem(20)}
                    ref={scrollToCourseRef}
                  >
                    {course.subtitle}
                  </Typography>
                </Tooltip>
              ) : (
                <Skeleton variant="text" width="40%" height={pxToRem(30)} />
              )}
            </BlockBordered>
          </Box>
          {!mobile_ssm && (
            <LinkWithIcon to="/courses" sx={{ marginTop: pxToRem(14) }}>
              Back
            </LinkWithIcon>
          )}
        </Stack>
      </Container>
      <Container sx={{ order: 3, display: { ssm: "none" }, padding: 0 }}>
        <Tabs
          sx={{
            "& .MuiTabs-flexContainer": {
              columnGap: { xs: pxToRem(15), ssm: pxToRem(27) },
              justifyContent: {
                xs: "space-between",
                sssm: "space-evenly",
                sm: "flex-start",
              },
            },
            marginTop: pxToRem(12),
            borderTop: "1px solid #BFBEBB",
            borderBottom: `${
              openDropdownIndex === null ? "1px solid #BFBEBB" : ""
            }`,
            minHeight: pxToRem(48),
          }}
          value={false}
          onChange={handleTabClick}
          TabIndicatorProps={{ style: { display: "none" } }}
        >
          <Tab
            sx={{
              padding: `${pxToRem(12)} 0`,
              minHeight: pxToRem(48),
              span: {
                color:
                  openDropdownIndex === 0
                    ? "#333333"
                    : openDropdownIndex === null
                      ? "#026670"
                      : "",
              },
              alignItems: "flex-start",
            }}
            label={
              <Typography
                component="span"
                sx={{
                  fontSize: { xs: pxToRem(14), sssm: pxToRem(16) },
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "600",
                  paddingRight: pxToRem(5),
                  color: "#026670",
                  textTransform: "capitalize",
                }}
              >
                Course Details
                <Chevron
                  style={{
                    marginLeft: pxToRem(15),
                    transform: "rotate(90deg)",
                  }}
                />
              </Typography>
            }
          />
          <Tab
            sx={{
              padding: `${pxToRem(12)} 0`,
              minHeight: pxToRem(48),
              span: { color: openDropdownIndex === 1 ? "#333333" : "" },
              alignItems: "flex-start",
            }}
            label={
              <Typography
                component="span"
                sx={{
                  fontSize: { xs: pxToRem(14), sssm: pxToRem(16) },
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "600",
                  paddingRight: pxToRem(5),
                  color: "#848484",
                  textTransform: "capitalize",
                }}
              >
                Comments (28)
                <Chevron
                  style={{
                    marginLeft: pxToRem(15),
                    transform: "rotate(90deg)",
                  }}
                />
              </Typography>
            }
          />
        </Tabs>
        <TabPanel value={openDropdownIndex} index={0}>
          <OverviewTab
            description={course.description}
            length={course.length}
            release_date={course.release_date}
            subscriber_count={course.subscriber_count}
            lessons_count={lessons_count}
            rating={course.rating}
          />
        </TabPanel>
        <TabPanel value={openDropdownIndex} index={1}>
          <Typography
            fontSize={{ xs: pxToRem(20), ssm: pxToRem(35) }}
            fontWeight={700}
            my={pxToRem(20)}
          >
            Course Questions
          </Typography>
          <Stack direction={"column"} spacing={pxToRem(26)}>
            <Testimonial modal avatar reply />
            <Testimonial modal reply />
            <Testimonial modal avatar reply />
          </Stack>
          <Stack
            direction={{ xs: "column", ssm: "row" }}
            alignItems="center"
            rowGap={{ xs: pxToRem(20), ssm: "10px" }}
            justifyContent="space-between"
            mt={pxToRem(50)}
          >
            <Button
              variant="yellow"
              sx={{
                height: pxToRem(54),
                width: { xs: "100%", ssm: "auto" },
                order: { xs: 2, ssm: 1 },
              }}
            >
              Rate & Comment
            </Button>
            <TextLink href="" sx={{ order: { xs: 1, ssm: 2 } }}>
              View all 28 Comments
            </TextLink>
          </Stack>
        </TabPanel>
      </Container>
      <Box
        position="relative"
        pt={{ xs: pxToRem(25), ssm: pxToRem(80), sm: pxToRem(100) }}
        pb={{ xs: `${openDropdownIndex === null ? pxToRem(100) : ""}`, ssm: 0 }}
        mt={{ md: pxToRem(-30) }}
      >
        <Guides color="rgba(0,0,0,0.06)" zIndex={-1} />
        <Box
          position="relative"
          pt={{ md: pxToRem(150) }}
          mt={{ md: pxToRem(-110) }}
          mb={{ xs: pxToRem(40), ssm: pxToRem(100), md: pxToRem(150) }}
        >
          <Container
            sx={{ px: { xs: activeTab === 2 ? "0" : "25px", ssm: "25px" } }}
            disableGutters
          >
            <Stack
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent="space-between"
              gap="6.5%"
            >
              {openDropdownIndex === null && (
                <CourseTabs
                  course={course}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  expandedPanel={expandedPanel}
                  setExpandedPanel={setExpandedPanel}
                  summaryRef={summaryRef}
                  bookmarks={bookmarks}
                  notes={notes}
                  setBookmarks={setBookmarks}
                  setNotes={setNotes}
                  playingLessonId={playingLessonId}
                  setPlayingLessonId={setPlayingLessonId}
                  previousLessonStates={previousLessonStates}
                  setPreviousLessonStates={setPreviousLessonStates}
                  isSubscribed={course?.is_subscribed}
                  subscriptionStatus={course?.subscription_status}
                  setFetchCourseAgain={setFetchCourseAgain}
                  isCourseCompleted={isCourseCompleted}
                  jumpToLatestProgress={jumpToLatestProgress}
                  jumpToBookMark={jumpToBookMark}
                  hasContent={hasContent}
                  currentPlayingLesson={currentPlayingLesson}
                />
              )}
              {mobile_ssm ? (
                openDropdownIndex === null && (
                  <TabPanel value={activeTab} index={0}>
                    <Sidebar
                      lessons={lessons}
                      onLessonClick={handleLessonClick}
                      updateLessons={updateLessons}
                      completedStatus={completedStatus}
                      isSubscribed={course.is_subscribed}
                      subscriptionStatus={course?.subscription_status}
                      playingLessonId={playingLessonId}
                      setPlayingLessonId={setPlayingLessonId}
                      previousLessonStates={previousLessonStates}
                      setPreviousLessonStates={setPreviousLessonStates}
                      playIcon={playIcon}
                      nextLecture={nextLecture}
                      setBlockAutoLectureId={setBlockAutoLectureId}
                    />
                  </TabPanel>
                )
              ) : (
                <Sidebar
                  lessons={lessons}
                  onLessonClick={handleLessonClick}
                  updateLessons={updateLessons}
                  completedStatus={completedStatus}
                  isSubscribed={course.is_subscribed}
                  subscriptionStatus={course?.subscription_status}
                  playingLessonId={playingLessonId}
                  setPlayingLessonId={setPlayingLessonId}
                  previousLessonStates={previousLessonStates}
                  setPreviousLessonStates={setPreviousLessonStates}
                  playIcon={playIcon}
                  nextLecture={nextLecture}
                  setBlockAutoLectureId={setBlockAutoLectureId}
                />
              )}
            </Stack>
          </Container>
        </Box>
        {/* You may also like */}
        <VideosSection
          courseId={course?.id}
          categoryId={course?.category?.id}
        />
      </Box>
      <Toast
        open={toastOpen}
        onClose={() => {
          setToastOpen(false);
        }}
        message={`Next lecture will be available in ${toastMessage.hours} hours and ${toastMessage.minutes} minutes`}
      />
      <SubscriptionFeedbackModal
        open={modalOpen}
        handleClose={handleClose}
        modalTitle={modalTitle}
        success={success}
        cancel={cancel}
      />

      {openDropdownIndex === null && (
        <FooterTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          subscriptionStatus={course?.subscription_status}
          hasContent={hasContent}
        />
      )}
    </>
  );
};

export default Course;
