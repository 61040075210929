import { Stack, Typography } from "@mui/material";
import { pxToRem } from "px2rem2px";
import IconBtnCircular from "./IconBtnCircular";
import { ReactComponent as Close } from "../images/close.svg";

const ModalHeader = ({ isFeedBack, title, inModal, onClose }) => (
  <Stack
    position="relative"
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    bgcolor="#EDECE8"
    p={
      isFeedBack
        ? `${pxToRem(20)} ${pxToRem(20)} ${pxToRem(20)}`
        : {xs: `${pxToRem(30)}`, ssm: `${pxToRem(40)} ${pxToRem(50)} ${pxToRem(40)}`}
    }
    sx={{ "& button": { boxShadow: "none" } }}
  >
    <Typography
      fontSize={pxToRem(22)}
      fontWeight="bold"
      // sx={{ marginLeft: isFeedBack ? "22%" : "inherit" }}
    >
      {title}
    </Typography>
    {inModal && (
      <IconBtnCircular onClick={onClose}  sx={{width: {xs: pxToRem(35), ssm: pxToRem(40), sm: pxToRem(48)}, height: {xs: pxToRem(35), ssm: pxToRem(40), sm: pxToRem(48)}}}>
        <Close color="#026670" />
      </IconBtnCircular>
    )}
  </Stack>
);

export default ModalHeader;
