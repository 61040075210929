import { useCallback, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import { pxToRem } from "px2rem2px";
import BlockBordered from "../../components/BlockBordered";
import IconBtn from "./IconBtn";

import { ReactComponent as Chevron } from "../../images/chevron.svg";
import { ReactComponent as Download } from "../../images/writing.svg";
import { ReactComponent as Print } from "../../images/printer.svg";
import RichTextDisplay from "../../components/displayRichText";
import { useTheme } from "@mui/material/styles";
import { display, fontSize } from "@mui/system";

const ResourcesTab = ({
  expandedPanel,
  setExpandedPanel,
  summaryRef,
  lesson,
}) => {
  const handleChange = (panel) => (event, newExpanded) => {
    setExpandedPanel(newExpanded ? panel : false);
  };

  //downloads
  const getFileExtension = (url) => {
    return url.split(".").pop().split("?")[0].toUpperCase();
  };

  const formatFileSize = (sizeInBytes) => {
    const units = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let index = 0;
    let size = sizeInBytes;

    while (size >= 1024 && index < units.length - 1) {
      size /= 1024;
      index++;
    }

    return `${size?.toFixed(2)} ${units[index]}`;
  };

  const handleDownloadClick = (event, fileUrl, fileName) => {
    event.preventDefault();
    downloadFile(fileUrl, fileName);
  };

  const downloadFile = (fileUrl, fileName) => {
    fetch(fileUrl, {
      mode: "cors",
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Download error:", error));
  };
  const downloadAllFiles = (files) => {
    files.forEach((file) => {
      downloadFile(file.file, file.name);
    });
  };

  //rich text
  const isEmptyRichText = useCallback(
    (text) => text === "<p><br></p>" || text === "",
    [],
  );

  const theme = useTheme();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));

  return (
    <>
      <Box className="rtl-section" mb={pxToRem(40)}>
        <Typography
          component="p"
          variant="sectionTitleHebrew"
          fontSize={{xs: pxToRem(20), sssm: pxToRem(25), md: pxToRem(30)}}
          lineHeight={pxToRem(30)}
          mb="10px"
        >
          {lesson?.name}
        </Typography>
        <Box
          display="inline-flex"
          bgcolor="#FCE181"
          borderRadius={pxToRem(20)}
          p={`${pxToRem(3)} ${pxToRem(20)}`}
          ref={summaryRef}
        >
          <Typography
            component="p"
            fontSize={{ xs: pxToRem(15), ssm: pxToRem(18) }}
            fontWeight="700"
            color="#026670"
            textAlign="center"
          >
            Showing Resources for the active lecture only
          </Typography>
        </Box>
      </Box>
      {!isEmptyRichText(lesson?.summary) && (
        <Accordion
          className="accordion-white"
          expanded={expandedPanel === "panel1"}
          onChange={handleChange("panel1")}
          sx={{
            "& .MuiAccordionSummary-root, & .MuiAccordionSummary-root.Mui-expanded, & .MuiAccordionDetails-root":
              {
                py: 0,
                px: {xs: `${pxToRem(25)} !important`, ssm: `${pxToRem(40)} !important`},
              },
            mx: {xs: "-25px !important", ssm: "0 !important"}
          }}
        >
          <AccordionSummary expandIcon={<Chevron color="#026670" />}>
            <AccordionTitle sx={{fontSize: {xs: pxToRem(20), ssm: "20px"}}}>Lecture Summary</AccordionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              borderTop="1px solid rgba(191,190,187,0.5)"
              p={`${pxToRem(30)} 0 ${pxToRem(10)}`}
              sx={{"& p": {margin: 0}}}
            >
              <BlockBordered sx={{display: {xs: "none", ssm: "block"}}} str={lesson?.summary}>
                <RichTextDisplay str={lesson?.summary} />
              </BlockBordered>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
      {!isEmptyRichText(lesson?.refs) && (
        <Accordion
          className="accordion-white"
          expanded={expandedPanel === "panel2"}
          onChange={handleChange("panel2")}
          sx={{
            "& .MuiAccordionSummary-root, & .MuiAccordionSummary-root.Mui-expanded, & .MuiAccordionDetails-root":
              {
                py: 0,
                px: {xs: `${pxToRem(25)} !important`, ssm: `${pxToRem(40)} !important`},
              },
            mx: {xs: "-25px !important", ssm: "0 !important"}
          }}
        >
          <AccordionSummary expandIcon={<Chevron color="#026670" />}>
            <Stack
              width="100%"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              mr={pxToRem(30)}
            >
              <AccordionTitle sx={{fontSize: {xs: pxToRem(20), ssm: "20px"}}}>{mobile_ssm ? "References" : "Lecture References"}</AccordionTitle>
              <Stack
                flexDirection="row"
                gap={pxToRem(10)}
                sx={{ "& button": { backgroundColor: "#EDECE8" } }}
              >
                <Tooltip
                  title="Download Resources to text file"
                  enterDelay={0}
                  enterTouchDelay={0}
                  placement="top"
                >
                  <Box>
                    <IconBtn className="hover-green">
                      <Download color="#026670" />
                    </IconBtn>
                  </Box>
                </Tooltip>
                <Tooltip
                  title="Print Resources"
                  enterDelay={0}
                  enterTouchDelay={0}
                  placement="top"
                >
                  <Box>
                    <IconBtn className="hover-green">
                      <Print color="#026670" />
                    </IconBtn>
                  </Box>
                </Tooltip>
              </Stack>
            </Stack>
          </AccordionSummary>

          <AccordionDetails>
            <Box
              borderTop="1px solid rgba(191,190,187,0.5)"
              p={`${pxToRem(30)} 0 ${pxToRem(10)}`}
              sx={{"& p": {margin: 0}}}
            >
              <BlockBordered sx={{display: {xs: "none", ssm: "block"}}} str={lesson?.refs}>
                <RichTextDisplay str={lesson?.refs} />
              </BlockBordered>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}

      {lesson?.files?.length > 0 && (
        <Accordion
          className="accordion-white"
          expanded={expandedPanel === "panel3"}
          onChange={handleChange("panel3")}
          sx={{
            "& .MuiAccordionSummary-root, & .MuiAccordionSummary-root.Mui-expanded, & .MuiAccordionDetails-root":
              {
                py: 0,
                px: {xs: `${pxToRem(25)} !important`, ssm: `${pxToRem(40)} !important`},
              },
              "& .MuiAccordionDetails-root": { pb: `${pxToRem(10)} !important` },
              mx: {xs: "-25px !important", ssm: "0 !important"}
          }}
        >
          <AccordionSummary expandIcon={<Chevron color="#026670" />}>
            <Stack
              width="100%"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              mr={pxToRem(30)}
            >
              <AccordionTitle sx={{fontSize: {xs: pxToRem(20), ssm: "20px"}}}>Downloads</AccordionTitle>
              <Button
                variant="text"
                onClick={() => downloadAllFiles(lesson.files)}
              >
                Download All
              </Button>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              {lesson.files.map((file, index) => (
                <Stack
                  key={index}
                  component={Link}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  gap={pxToRem(15)}
                  borderTop="1px solid rgba(191,190,187,0.5)"
                  p={`${pxToRem(30)} 0`}
                  sx={{
                    "&:hover": {
                      "& .badge": {
                        backgroundColor: "#FCE181",
                        span: { color: "#026670" },
                      },
                      "& .list-item-title": { color: "#333" },
                    },
                  }}
                  onClick={(event) =>
                    handleDownloadClick(event, file.file, file.name)
                  }
                >
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    gap={pxToRem(15)}
                    component="span"
                  >
                    <Box
                      className="badge"
                      bgcolor="#EDECE8"
                      borderRadius={pxToRem(20)}
                      p={`${pxToRem(4.5)} ${pxToRem(7.3)}`}
                      component="span"
                    >
                      <Typography
                        fontSize={pxToRem(12)}
                        fontWeight={700}
                        color="#BFBEBB"
                        component="span"
                        display="block"
                      >
                        {getFileExtension(file?.file)}
                      </Typography>
                    </Box>
                    <Typography
                      className="list-item-title"
                      color="secondary"
                      fontWeight={600}
                      component="span"
                    >
                      {file?.name}
                    </Typography>
                  </Stack>
                  <Typography color="primary" fontWeight={600} component="span">
                    {formatFileSize(parseFloat(file?.size))}
                  </Typography>
                </Stack>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

const AccordionTitle = (props) => {
  return (
    <Typography fontSize="20px" fontWeight={700} color="secondary" {...props}>
      {props.children}
    </Typography>
  );
};

export default ResourcesTab;
