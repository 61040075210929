import React, { useState, useEffect, useRef } from "react";
import {
  Stack,
  Typography,
  Box,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Divider,
  Link,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { pxToRem } from "px2rem2px";
import { useForm } from "react-hook-form";
import IconBtnCircular from "../../components/IconBtnCircular";
import FormInputText from "../../components/FormInputText";
import FormInputPhone from "../../components/FormInputPhone";
import ReCAPTCHA from "react-google-recaptcha";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Close } from "../../images/close.svg";
import { ReactComponent as Google } from "../../images/google.svg";
import { ReactComponent as Info } from "../../images/info.svg";
import getSignupErrors from "../../utils/getSignUpErrors";
import { useAuth } from "../../utils/AuthContext";
import { useSearchParams } from "react-router-dom";
import { captchaKey } from "../../api/auth";
import ModalHeader from "../../components/ModalHeader";
const ToggleButtonStyled = styled(ToggleButton)({
  color: "#EDECE8",
  width: pxToRem(147),
  height: pxToRem(50),
  borderRadius: `${pxToRem(30)} !important`,
  fontSize: pxToRem(16),
  fontWeight: 600,
  textTransform: "capitalize",
  border: "none",
  "&:hover": {
    backgroundColor: "transparent",
    color: "#026670",
  },
  "&.Mui-selected": {
    color: "#026670",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
});

const ButtonGoogle = styled(Button)({
  height: `${pxToRem(54)} !important`,
  borderWidth: pxToRem(2),
  fontWeight: 500,
  marginBottom: pxToRem(35),
  boxShadow: "none",
  "&:hover": {
    borderWidth: pxToRem(2),
  },
});

const ButtonLogin = styled(Button)({
  width: pxToRem(300),
  height: `${pxToRem(54)} !important`,
  fontSize: pxToRem(14),
  fontWeight: 600,
  boxShadow: "none",
  margin: `${pxToRem(40)} 0`,
});

const Hr = styled(Divider)({
  marginBottom: pxToRem(10),
  "&::after, &::before": {
    borderTop: "1px solid #BFBEBB",
  },
  "& span": {
    paddingLeft: pxToRem(23),
    paddingRight: pxToRem(23),
  },
});

const ToggleButtonGroupStyled = styled(ToggleButtonGroup)({
  position: "relative",
  width: pxToRem(300),
  justifyContent: "space-between",
  backgroundColor: "#BFBEBB",
  borderRadius: pxToRem(30),
  padding: pxToRem(2),
  "&::before": {
    content: "''",
    width: pxToRem(147),
    height: pxToRem(50),
    position: "absolute",
    left: pxToRem(2),
    backgroundColor: "#EDECE8",
    borderRadius: pxToRem(30),
    transition: "left 0.2s ease-in-out",
  },
  "&.active::before": {
    left: pxToRem(151),
  },
});

const LoginPage = (props) => {
  let navigate = useNavigate();
  const location = useLocation();
  const [params, setParams] = useSearchParams();
  const [register, setRegister] = useState("login");
  const [phone, setPhone] = useState("");
  const [isEmailSubscribed, setIsEmailSubscribed] = useState(true);
  const captchaRef = useRef();
  const [captchaValue, setCaptchaValue] = useState(null);

  const {
    signup,
    signUpError,
    isSignedUp,
    login,
    loginError,
    requestOauth,
    oauth,
    verifyEmail,
    verificationErr,
    emailVerificationMsg,
    isLogin,
    isSigningUp,
  } = useAuth();

  const registerForm = useForm({
    values: {
      first_name: "",
      last_name: "",
      nickname: "",
      phone: "",
      signupEmail: "",
      signupPassword: "",
      signupRePassword: "",
    },
  });

  //start:gifted Course to unsubscribed user

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const email = params.get("email");
    if (email) {
      registerForm.setValue("signupEmail", email);
      loginForm.setValue("loginEmail", email);
    }
  }, [location, register]);

  //end:gifted Course to unsubscribed user

  const registerPhone = registerForm.watch("phone");
  const registerEmail = registerForm.watch("signupEmail");
  const registerPassword = registerForm.watch("signupPassword");
  const loginForm = useForm({
    values: {
      loginEmail: "",
      loginPassword: "",
      keepMeLogged: true,
    },
  });

  useEffect(() => {
    const token = params.get("token");
    const email = params.get("email");
    const state = params.get("state");
    const code = params.get("code");

    if (props?.emailVerification && token && email) {
      verifyEmail(token, email);
    }
    if (props?.oauth && state && code) {
      oauth(state, code);
    }
  }, []);

  const handleTabChange = (event, newRegister) => {
    if (newRegister !== null) {
      setRegister(newRegister);
    }
  };

  const handleSignupSubmit = (data, e) => {
    data.captchaValue = captchaValue;
    data.isEmailSubscribed = isEmailSubscribed;
    data.phone = phone;
    console.log("data", data);
    signup(data)
      .then((res) => {
        props.setVerifyEmail(data.signupEmail.toLowerCase());
        props.setAction("verifyEmail");
      })
      .catch((err) => {
        captchaRef.current.reset();
        console.log(err);
      });
  };

  const handleGoogleAuth = () => {
    localStorage.setItem("redirectAfterLogin", location.pathname);
    if (props?.onRedirectOpenModal === "previewModal") {
      localStorage.setItem("onRedirectOpenModal", "previewModal");
    }
    if (props?.onRedirectOpenModal === "giftModal") {
      localStorage.setItem("onRedirectOpenModal", "giftModal");
    }

    requestOauth();
  };

  const handleLogInSubmit = (data, e) => {
    if (props?.onRedirectOpenModal === "previewModal") {
      localStorage.setItem("onRedirectOpenModal", "previewModal");
    }
    if (props?.onRedirectOpenModal === "giftModal") {
      localStorage.setItem("onRedirectOpenModal", "giftModal");
    }
    login(
      data.loginEmail.toLowerCase(),
      data.loginPassword,
      captchaValue,
      data.keepMeLogged,
      () => captchaRef.current.reset(),
      data,
      props.setVerifyEmail,
      props.setTempEmail,
      props.setTempPass,
      props.setAction
    );
  };

  const handleCaptchaChange = (e) => {
    setCaptchaValue(captchaRef.current.getValue());
  };

  useEffect(() => {
    registerForm.reset({
      data: "",
    });
    if (isSignedUp) {
      captchaRef.current.reset();
      setRegister("login");
    }
  }, [isSignedUp, isLogin]);

  console.log("onRedirectOpenPreview", props?.onRedirectOpenPreview);

  return (
    <Box
      sx={{
        //width: "450px",
        // height: register === "login" ? "900px !important" : "",
        height: "900px !important",
        //desactivate scroll if register === login
        overflowY: register === "login" ? "hidden" : "auto",
      }}
    >
      <ModalHeader
        title={`R.Y.M. ${register === "login" ? "Log In" : "Sign Up"}`}
        inModal={props?.inModal}
        onClose={() => props.onClose()}
      />
      <Box
        bgcolor="#F7F6F2"
        p={` ${pxToRem(50)} ${pxToRem(40)}`}
        sx={{
          "@media(max-width: 400px)": {
            paddingX: "20px",
          },
        }}
        mt={pxToRem(-50)}
      >
        <Box
          position="relative"
          textAlign="center"
          mt={pxToRem(-27)}
          mb={pxToRem(40)}
        >
          <ToggleButtonGroupStyled
            className={`${register === "signup" ? "active" : ""}`}
            value={register}
            exclusive
            onChange={handleTabChange}
          >
            <ToggleButtonStyled value="login">Log In</ToggleButtonStyled>
            <ToggleButtonStyled value="signup">Sign Up</ToggleButtonStyled>
          </ToggleButtonGroupStyled>
        </Box>
        <Box maxWidth={400} position="relative" overflow="hidden" m="auto">
          <Stack
            className={`${register === "signup" ? "active" : ""}`}
            width="200%"
            flexDirection="row"
            sx={{
              transition: "transform 0.2s ease-in-out",
              "&.active": { transform: "translateX(-50%)" },
            }}
          >
            <Box width="100%" textAlign="center">
              <ButtonGoogle
                fullWidth
                variant="outlined"
                onClick={handleGoogleAuth}
                startIcon={<Google />}
              >
                Continue with Google
              </ButtonGoogle>
              <Box>
                <Hr>
                  <Typography
                    fontSize={pxToRem(14)}
                    fontWeight={600}
                    color="#BFBEBB"
                  >
                    or
                  </Typography>
                </Hr>
              </Box>
              <form
                onSubmit={loginForm.handleSubmit(handleLogInSubmit)}
                noValidate
              >
                <Stack gap={pxToRem(10)} sx={{ mt: "-25px" }}>
                  <Box mt={2}>
                    <Typography sx={{ color: "red" }}>{loginError}</Typography>
                    <Typography sx={{ color: "red" }}>
                      {verificationErr}
                    </Typography>
                    <Typography sx={{ color: "green" }}>
                      {props.successMessage}
                    </Typography>
                  </Box>

                  <FormInputText
                    name="loginEmail"
                    control={loginForm.control}
                    setValue={loginForm.setValue}
                    placeholder="Email"
                    muiProps={{ type: "email" }}
                    rules={{
                      required: "Field can't be empty",
                      maxLength: {
                        value: 100,
                        message: "Maximum 100 characters",
                      },
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Entered value does not match email format",
                      },
                    }}
                  />
                  <FormInputText
                    name="loginPassword"
                    control={loginForm.control}
                    setValue={loginForm.setValue}
                    placeholder="Password"
                    muiProps={{ type: "password" }}
                    rules={{
                      required: "Field can't be empty",
                    }}
                  />
                </Stack>

                <div>
                  <FormControlLabel
                    sx={{ mt: "30px" }}
                    name="keepMeLogged"
                    control={
                      <Checkbox
                        checked={loginForm.watch("keepMeLogged")}
                        onChange={(e) =>
                          loginForm.setValue("keepMeLogged", e.target.checked)
                        }
                      />
                    }
                    label="Keep me logged in?"
                  />
                  <InfoLabel />
                  {register === "login" && (
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="center"
                      gap={pxToRem(15)}
                      mt={pxToRem(15)}
                    >
                      <ReCAPTCHA
                        ref={captchaRef}
                        sitekey={captchaKey}
                        onChange={(e) => handleCaptchaChange(e)}
                      />
                    </Stack>
                  )}
                </div>
                <ButtonLogin disabled={isLogin} type="submit" variant="yellow">
                  Log In
                </ButtonLogin>
              </form>
              <Divider sx={{ borderColor: "#BFBEBB", my: pxToRem(20) }} />
              <Typography
                onClick={() => {
                  props.setAction("passwordResetRequest");
                }}
                underline="hover"
                sx={{
                  fontWeight: 600,
                  color: "#BFBEBB",
                  cursor: "pointer",
                  caretColor: "transparent",
                  textAlign: "center",
                  margin: "20px 0px",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                  "@media(max-width: 380px)": {
                    fontSize: "12px",
                  },
                }}
              >
                Forgot Password?
              </Typography>

              {/* <Typography
                onClick={() => {
                  props.setAction("changeTempPassword");
                }}
                underline="hover"
                sx={{
                  fontWeight: 600,
                  color: "#BFBEBB",
                  cursor: "pointer",
                  caretColor: "transparent",
                  textAlign: "center",
                  margin: "20px 0px",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                  "@media(max-width: 380px)": {
                    fontSize: "12px",
                  },
                }}
              >
                Change Temporary Password?
              </Typography> */}
            </Box>
            {/* sign up */}
            <Box width="100%" textAlign="center">
              <ButtonGoogle
                fullWidth
                onClick={handleGoogleAuth}
                variant="outlined"
                startIcon={<Google />}
              >
                Continue with Google
              </ButtonGoogle>
              <Hr>
                <Typography
                  fontSize={pxToRem(14)}
                  fontWeight={600}
                  color="#BFBEBB"
                >
                  or
                </Typography>
              </Hr>
              <form
                onSubmit={registerForm.handleSubmit(handleSignupSubmit)}
                noValidate
              >
                <Stack gap={pxToRem(10)}>
                  <FormInputText
                    name="first_name"
                    control={registerForm.control}
                    setValue={registerForm.setValue}
                    placeholder="First Name"
                    muiProps={{
                      type: "text",
                    }}
                    rules={{
                      required: "Field can't be empty",
                      minLength: {
                        value: 3,
                        message: "Minimum 3 characters",
                      },
                      maxLength: {
                        value: 50,
                        message: "Maximum 50 characters",
                      },
                    }}
                  />
                  <Typography color={"red"}>
                    {signUpError?.first_name}
                  </Typography>
                  <FormInputText
                    name="last_name"
                    control={registerForm.control}
                    setValue={registerForm.setValue}
                    placeholder="Last Name"
                    muiProps={{
                      type: "text",
                    }}
                    rules={{
                      required: "Field can't be empty",
                      minLength: {
                        value: 3,
                        message: "Minimum 3 characters",
                      },
                      maxLength: {
                        value: 50,
                        message: "Maximum 50 characters",
                      },
                    }}
                  />
                  <Typography color={"red"}>
                    {signUpError?.last_name}
                  </Typography>
                  <FormInputText
                    name="nickname"
                    control={registerForm.control}
                    setValue={registerForm.setValue}
                    placeholder="Nickname (optional)"
                    muiProps={{
                      type: "text",
                    }}
                    rules={{
                      minLength: {
                        value: 3,
                        message: "Minimum 3 characters",
                      },
                      maxLength: {
                        value: 50,
                        message: "Maximum 50 characters",
                      },
                    }}
                  />
                  <Typography color={"red"}>{signUpError?.nickname}</Typography>

                  <FormInputPhone
                    name="phone"
                    //control={registerForm.control}
                    error={signUpError?.phone_number}
                    placeholder="Phone"
                    rules={{
                      required: "Field can't be empty",
                      minLength: {
                        value: 13,
                        message: "Minimum 13 number",
                      },
                    }}
                    //setValue={registerForm.setValue}
                    value={phone}
                    onChange={(data) => setPhone(data.phone)}
                  />
                  <Typography color={"red"}>
                    {signUpError?.phone_number}
                  </Typography>

                  <FormInputText
                    name="signupEmail"
                    control={registerForm.control}
                    setValue={registerForm.setValue}
                    placeholder="Email"
                    autoComplete="off"
                    muiProps={{
                      type: "email",
                    }}
                    rules={{
                      required: "Field can't be empty",
                      maxLength: {
                        value: 100,
                        message: "Maximum 100 characters",
                      },
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Entered value does not match email format",
                      },
                    }}
                  />
                  <Typography color={"red"}>{signUpError?.email}</Typography>
                  <FormInputText
                    name="signupPassword"
                    control={registerForm.control}
                    setValue={registerForm.setValue}
                    error={signUpError?.password ? 1 : 0}
                    helperText={
                      signUpError?.password ? signUpError.password : null
                    }
                    placeholder="Password"
                    autoComplete="new-password"
                    muiProps={{
                      type: "password",
                    }}
                    rules={{
                      required: "Field can't be empty",
                      validate: (v) =>
                        v !== (registerPhone || registerEmail) ||
                        "Password can't be the same as the email address or phone number",
                      minLength: {
                        value: 6,
                        message: "Minimum 6 characters",
                      },
                      maxLength: {
                        value: 30,
                        message: "Maximum 30 characters",
                      },
                    }}
                  />
                  <Typography color={"red"}>{signUpError?.password}</Typography>
                  <FormInputText
                    name="signupRePassword"
                    control={registerForm.control}
                    setValue={registerForm.setValue}
                    placeholder="Retype Password"
                    muiProps={{ type: "password" }}
                    preventPaste
                    rules={{
                      required: "Field can't be empty",
                      validate: (v) =>
                        v.trim() === registerPassword.trim() ||
                        "Passwords must match",
                    }}
                  />
                  <Typography color={"red"}>
                    {signUpError?.comfirm_password}
                  </Typography>
                </Stack>
                <Typography color={"red"}>{signUpError?.captcha}</Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: "20px",
                  }}
                >
                  <Checkbox
                    checked={isEmailSubscribed}
                    onChange={(e) => setIsEmailSubscribed(e.target.checked)}
                  />
                  <Typography
                    sx={{
                      color: "#026670",
                      fontSize: "11px",
                      fontWeight: 600,
                    }}
                  >
                    Send me emails about updates and news.
                  </Typography>
                </Box>
                {register === "signup" && (
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    gap={pxToRem(15)}
                    mt={pxToRem(10)}
                  >
                    {/* <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="I am not a robot!"
                  /> */}
                    <ReCAPTCHA
                      sitekey={captchaKey}
                      ref={captchaRef}
                      onChange={(e) => handleCaptchaChange(e)}
                    />
                  </Stack>
                )}
                <ButtonLogin
                  disabled={isSigningUp}
                  variant="yellow"
                  type="submit"
                >
                  Create Account
                </ButtonLogin>
                <Stack alignItems="center" gap={pxToRem(15)}>
                  {/*     <Box>
                  
                    <InfoLabel />
                  </Box> */}

                  {/*  <FormControlLabel
                    control={
                      <Checkbox
                        checked={registerForm.watch("is_email_subscribed")}
                        onChange={(e) =>
                          registerForm.setValue(
                            "is_email_subscribed",
                            e.target.checked
                          )
                        }
                      />
                    }
                    label="Send me emails about updates and news."
                  /> */}
                </Stack>
              </form>
              <Divider sx={{ borderColor: "#BFBEBB", my: pxToRem(40) }} />
              <Typography
                maxWidth={pxToRem(300)}
                fontSize={pxToRem(12)}
                fontWeight={500}
                lineHeight={pxToRem(16)}
                color="#BFBEBB"
                margin="auto"
              >
                By creating an account, you are agreeing to our{" "}
                <Link href="" underline="hover" color="#026670">
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link href="" underline="hover" color="#026670">
                  Privacy Policy
                </Link>
                .
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

const InfoLabel = () => {
  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      gap="7px"
      mt="5px"
    >
      <Info color="#BFBEBB" />
      <Typography color="#BFBEBB" fontSize={pxToRem(12)}>
        Not recommended on public devices
      </Typography>
    </Stack>
  );
};

export default LoginPage;
