import React from "react";
import VideoSectionComponent from "./VideoSectionComponent";

// import video1 from "../../videos/self-esteem-1.mp4";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { pxToRem } from "px2rem2px";

const videoSectionData = [
  {
    id: 1,
    title: `וויאזוי קען איך עררייכן "סעלף-עסטיעם" אויף א געזונטע תורה'דיגע וועג - אז איך זאל נישט ווערן אויס מציאות אין די רגע וואס יענער אנערקענט נישט אין מיינע מעלות?`,
    videoSrc: "https://drive.google.com/file/d/17IthUN-Q4R03jfxo0asrtIQZRHxua3wg/preview",
  },
];

export default function VideoSection() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 6,
        paddingTop: "40px",
        paddingBottom: "40px",
      }}
    >
      <Typography
        variant="sectionTitleHebrew"
        sx={{
          fontSize: {
            xs: pxToRem(24),
            sm: pxToRem(30),
            md: pxToRem(35),
            lg: pxToRem(40),
          },
          textAlign: "center",
        }}
        className="rtl-text-temp-player"
        dir="rtl"
      >
        סעלף-עסטיעם
      </Typography>
      
      {videoSectionData?.map((v) => (
        <Box key={v.id}>
          <VideoSectionComponent title={v?.title} videoSrc={v?.videoSrc} />
        </Box>
      ))}
    </Box>
  );
}
