import { Box, Stack, Typography } from "@mui/material";
import { pxToRem } from "px2rem2px";
import { styled } from "@mui/system";
import Header from "../Header";
import Search from "./Search";
import Guides from "../Guides";
import BgImg from "../../images/courses-hero-bg.png";

const PageTitleHighlighted = styled(Typography)(({ theme }) => {
  return {
    position: "relative",
    fontFamily: "PloniBold",
    fontSize: pxToRem(70),
    lineHeight: pxToRem(65),
    "&::after": {
      content: "''",
      width: "104%",
      height: pxToRem(20),
      position: "absolute",
      left: "50%",
      bottom: pxToRem(12),
      transform: "translateX(-50%)",
      backgroundColor: "rgba(252,225,129,0.5)",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "50px",
      lineHeight: "50px",
      "&::after": {
        bottom: "6px",
      },
    },
  };
});

const Hero = ({
  title,
  onSearch,
  courses,
  setCourses,
  setIsOnSearchMode,
  isOnSearchMode,
  setPage,
}) => {
  return (
    <Box
      position="relative"
      pt={pxToRem(30)}
      pb={pxToRem(40)}
      minHeight="540px"
      mb={pxToRem(100)}
      sx={{
        backgroundImage: `url(${BgImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: { xs: 0, md: pxToRem(40) },
      }}
    >
      <Header menuVisible />

      <Stack
        className="header-padding"
        position="relative"
        maxWidth="633px"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        zIndex={1}
        margin="64px auto 0"
        padding="0 25px"
      >
        <Typography variant="sectionTitle" component="h2" mb="10px">
          {title}
        </Typography>
        <Typography
          component="h1"
          fontFamily="PloniBold"
          fontSize={{ xs: "50px", sm: pxToRem(70) }}
          lineHeight={{ xs: "50px", sm: pxToRem(65) }}
          mb="40px"
        >
          דא ווערן{" "}
          <PageTitleHighlighted component="span">
            דורכגערעדט
          </PageTitleHighlighted>{" "}
          די וויכטיגסטע יסודות אין לעב
        </Typography>
        <Search
          onSearch={onSearch}
          courses={courses}
          setCourses={setCourses}
          setIsOnSearchMode={setIsOnSearchMode}
          isOnSearchMode={isOnSearchMode}
          setPage={setPage}

        />
      </Stack>
      <Guides color="rgba(0,0,0,0.03)" zIndex="0" />
    </Box>
  );
};

export default Hero;
