export default function shouldNavigateToPresubscribe(subscriptionStatus) {
    const presubscribeStatuses = [
        "Deleted",
        "Inactive",
        "Failed",
        "Unsubscribed",
        "Anonymous"
    ];

    return presubscribeStatuses.includes(subscriptionStatus);
};
