import React, { useState, useEffect } from "react";
import { useModal } from "mui-modal-provider";
import { Box, Button, Stack, Typography, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { pxToRem } from "px2rem2px";
import BlockBordered from "../../components/BlockBordered";
import TextLink from "../../components/TextLink";
import api from "../../api/api";
import {
  createCheckoutSession,
  resolveCheckoutSession,
} from "../../api/payment";
import { getStudent } from "../../api/student";
import GiftCourseModal from "../preSubscribe/GiftCourseModal";
import { ReactComponent as Gift } from "../../images/gift.svg";
import IconBtnCircular from "../../components/IconBtnCircular";
import Toast from "../../components/Toast";
import { Share } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Info } from "../../images/info.svg";
import SelectAssociateModal from "./components/SelectAssociateModal";
import InviteAssociateModal from "../user/dashboard/InviteAssociateModal";
import PurchaseModal from "../preSubscribe/PurchaseModal";
import ThankModal from "../preSubscribe/ThankModal";
import ErrorModal from "../preSubscribe/ErrorModal";
import ProlongModal from "./components/ProlongModal";
import { isWithin21Days } from "../../utils/format";

const TypographyStyled = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(18),
  fontWeight: 600,
  lineHeight: pxToRem(30),
  "& span": {
    display: "inline-block",
    [theme.breakpoints.up("xs")]: {
      width: pxToRem(110),
    },
    [theme.breakpoints.up("sssm")]: {
      width: pxToRem(152),
    },
    color: "#BFBEBB",
    marginRight: pxToRem(20),
  },
}));

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const fetchAssociate = async () => {
  try {
    const response = await api.get("/api/student/associate/");
    return response.data;
  } catch (error) {
    console.error("Error fetching associate:", error);
    return null;
  }
};

const SubscriptionTab = ({
  my_subscription,
  course,
  progress,
  isSubscribed,
  subscriptionStatus,
  setFetchCourseAgain,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [associate, setAssociate] = useState(null);
  const [openGiftModal, setOpenGiftModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [studentInfo, setStudentInfo] = useState({});
  const [shareToastOpen, setShareToastOpen] = useState(false);
  const [errorToastOpen, setErrorToastOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  const { showModal, hideModal } = useModal();

  const showSubscriptionModal = (price, type) => {
    const modal = showModal(PurchaseModal, {
      course: course,
      type: type,
      price: price,
      openThankModal: () => {
        hideModal(modal.id);
        showModal(ThankModal);
      },
      openErrorModal: () => {
        hideModal(modal.id);
        const errorModal = showModal(ErrorModal, {
          openPurchaseModal: () => {
            hideModal(errorModal.id);
            showModal(PurchaseModal, { course: course });
          },
        });
      },
    });
  };

  const fetchStudent = () => {
    getStudent()
      .then((res) => {
        setStudentInfo(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchStudent();
  }, []);

  const handleModalOpen = async () => {
    setInviteModalOpen(true);
    setFetchCourseAgain(true);
  };

  const handleLifeLongPurchase = async (
    priceType,
    priceClass,
    prolongBy = ""
  ) => {
    try {
      const response = await createCheckoutSession(
        course.id,
        priceType,
        priceClass,
        prolongBy
      );
      window.location.href = response.data.checkout_url;
    } catch (error) {
      console.log("Error creating checkout session:", error);
      setErrorMessage(
        error.response?.data?.error ||
          "An error occurred while creating the checkout session."
      );
      setErrorToastOpen(true);
    }
  };
  const handleResolvePurchase = async (subscriptionId) => {
    try {
      const response = await resolveCheckoutSession(subscriptionId);
      window.location.href = response.data.checkout_url;
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error ||
          "An error occurred while trying to resolve the issue."
      );
      setErrorToastOpen(true);
    }
  };

  const handleOpenGiftModal = () => setOpenGiftModal(true);
  const handleCloseGiftModal = () => setOpenGiftModal(false);

  const handleShareClick = () => {
    const fullUrl = `${window.location.origin}${location.pathname}`;
    navigator.clipboard
      .writeText(fullUrl)
      .then(() => setShareToastOpen(true))
      .catch((err) => console.error("Could not copy text: ", err));
  };

  // prolong
  const [prolongModal, setProlongModal] = useState(false);
  const openProlongModal = () => {
    setProlongModal(true);
  };

  // render buttons
  const shouldShowExtendButton =
    course?.user_subscription?.status === "Active" &&
    !course?.user_subscription?.is_failed &&
    course?.is_prolong_allowed &&
    isWithin21Days(course?.user_subscription?.end_date);




  return (
    <Box>
      <Stack
        flexDirection={{ ssm: "row", md: "column" }}
        // alignItems={{ ssm: "end" }}
        justifyContent="space-between"
        flexWrap="wrap"
        columnGap={pxToRem(60)}
        rowGap={{xs: pxToRem(20), sm: 0}}
      >
        <Box sx={{ width: { sm: "50%", md: "100%" } }}>
          <Stack
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            gap={pxToRem(15)}
            mb={pxToRem(17)}
          >
            <Typography
              variant="sectionTitle"
              component="h2"
              fontSize={{xs: pxToRem(20), ssm: pxToRem(35)}}
            >
              Subscription
            </Typography>

            <Stack
              position="relative"
              flexDirection="row"
              alignItems="flex-end"
              gap={pxToRem(10)}
              display={{ xs: "none", md: "flex" }}
            >
              <Tooltip title="Share Now" placement="top">
                <Box
                  sx={{
                    "& button:hover": {
                      backgroundColor: "#00545d !important",
                    },
                  }}
                  onClick={handleShareClick}
                >
                  <IconBtnCircular className="hover-green active">
                    <Share
                      color="#026670"
                      style={{ marginRight: pxToRem(1.5) }}
                    />
                  </IconBtnCircular>
                </Box>
              </Tooltip>

              {course.subscription_price > 0 && (
                <Tooltip
                  title={
                    <span style={{ whiteSpace: "nowrap", padding: "5px 0" }}>
                      Gift Course
                    </span>
                  }
                  placement="top"
                  PopperProps={{ disablePortal: true }}
                >
                  <Box
                    sx={{
                      "& + .MuiTooltip-popper .MuiTooltip-tooltip": {
                        backgroundColor: "#fce181",
                        color: "#026670",
                      },
                      "& svg": { transition: "0.2s ease" },
                      "&:hover svg": { color: "#026670" },
                    }}
                    onClick={handleOpenGiftModal}
                  >
                    <IconBtnCircular className="yellow-ic">
                      <Gift color="#fff" />
                    </IconBtnCircular>
                  </Box>
                </Tooltip>
              )}
            </Stack>
          </Stack>
          <Stack
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="space-between"
            gap={pxToRem(25)}
            marginTop={{ xs: "20px", md: "30px" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box sx={{ width: { lg: "50%" } }}>
                <BlockBordered sx={{display: "none"}}>
                  <TypographyStyled>
                    <span>Enrolled on:</span>{" "}
                    {formatDate(my_subscription?.start_date)}
                  </TypographyStyled>

                  <TypographyStyled>
                    <span>Expires on:</span>{" "}
                    {course.subscription_price > 0
                      ? !my_subscription?.end_date
                        ? "Lifetime Access"
                        : formatDate(my_subscription?.end_date)
                      : "Lifetime Access"}
                  </TypographyStyled>

                  <TypographyStyled>
                    <span>Progress:</span> {Math.floor(progress)}% Completed
                  </TypographyStyled>
                </BlockBordered>
              </Box>
            </Box>
          </Stack>
          <TextLink
            href=""
            sx={{ marginTop: "20px", display: "flex" }}
          >
            View Purchase Details
          </TextLink>
        </Box>
        <Box sx={{ width: { sm: "40%", md: "100%" }, maxWidth: "100%", mx: {ssm: "auto", sm: 0} }}>
          {course.subscription_price > 0 && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "space-between",
                  marginTop: { md: "20px" },
                  columnGap: pxToRem(20),
                  flexWrap: {ssm: "wrap"}
                }}
              >
                {course?.is_associate_allowed && (<Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: { xs: "center", md: "start" },
                    marginTop: "auto",
                    order: { xs: 2, ssm: 1 },
                  }}
                >
                    <>
                      <Button
                        variant="yellow"
                        sx={{
                          height: pxToRem(54),
                          width: { xs: "100%", ssm: pxToRem(300) },
                        }}
                        onClick={handleModalOpen}
                      >
                        {course?.associate
                          ? "View Partner"
                          : `Add Partner for $${course.associate_price}`}
                      </Button>
                      <Box>
                        {course?.is_associate_allowed && !course?.associate && (
                          <Tooltip
                            title="Add a partner to receive a discounted checkout link. The partner can purchase the course at a lower price."
                            enterDelay={0}
                            enterTouchDelay={0}
                            PopperProps={{ disablePortal: true }}
                            placement="bottom"
                            // marginRight={pxToRem(20)}
                            sx={{
                              "& .MuiTooltip-tooltip": {
                                mt: pxToRem(4),
                                py: pxToRem(4),
                              },
                            }}
                          >
                            <Box
                              maxWidth={pxToRem(300)}
                              flexShrink={0}
                              textAlign="center"
                              mt={pxToRem(5)}
                            >
                              <Button
                                startIcon={<Info />}
                                sx={{
                                  fontSize: pxToRem(12),
                                  fontWeight: 600,
                                  color: "#BFBEBB",
                                  textAlign: "center",
                                }}
                                component={Link}
                                to="/qau"
                              >
                                How does it work?
                              </Button>
                            </Box>
                          </Tooltip>
                        )}
                      </Box>
                    </>
                </Box>)}

                {course?.user_subscription?.type !== "Lifetime" && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: { xs: "center", md: "start", lg: "end" },
                      flexDirection: "column",
                      marginRight: { lg: "17px" },
                      marginBottom: { xs: pxToRem(12), sm: 0 },
                      order: { xs: 1, ssm: 2 },
                    }}
                  >
                    {/* Lifetime subscription */}
                    {!course?.user_subscription?.is_failed && (
                      <Box sx={{width: {xs: "100%", ssm: "auto"}}}>
                        <Typography
                          component="p"
                          fontSize={pxToRem(15)}
                          fontWeight={500}
                          lineHeight={pxToRem(24)}
                          textAlign="center"
                          my={pxToRem(16)}
                          sx={{
                            maxWidth: pxToRem(300),
                            minWidth: { xs: "100%", sm: pxToRem(300) },
                          }}
                        >
                          Purchase a Lifetime Subscription with No Expiration
                          for a Slightly Higher Price
                        </Typography>

                        <Button
                          onClick={() =>
                            handleLifeLongPurchase("Lifetime", "Normal")
                          }
                          variant="outlined"
                          sx={{
                            maxWidth: pxToRem(300),
                            minWidth: { xs: "100%", sm: pxToRem(300) },
                            height: pxToRem(54),
                            borderWidth: "2px",
                            "&:hover": {
                              border: "2px solid #026670",
                            },
                            paddingLeft: {
                              xs: pxToRem(40),
                              ssm: pxToRem(20),
                              sm: pxToRem(59),
                            },
                            paddingRight: {
                              xs: pxToRem(40),
                              ssm: pxToRem(20),
                              sm: pxToRem(59),
                            },
                            mb: !shouldShowExtendButton && !course?.user_subscription?.is_failed ? pxToRem(32) : 0,
                          }}
                        >
                          {`Lifetime Access for $${course?.lifetime_price}`}
                        </Button>
                      </Box>
                    )}

                    <Box sx={{width: {xs: "100%", ssm: "auto"}}}>
                      {shouldShowExtendButton && (
                        <>
                          <Button
                            variant="yellow"
                            sx={{
                              height: pxToRem(54),
                              width: {xs: "100%", ssm: pxToRem(300)},
                              marginTop: "10px",
                            }}
                            onClick={() =>
                              openProlongModal("Normal", "Prolong")
                            }
                          >
                            {`Extend Course for $${course.prolong_price}`}
                          </Button>
                          <Box
                            sx={{
                              // width: pxToRem(300),
                              justifyContent: "center",
                              // marginRight: "17px",
                            }}
                          >
                            {shouldShowExtendButton && (
                              <Tooltip
                                title="Extend your subscription before it expires, and benefit from a discounted price."
                                enterDelay={0}
                                enterTouchDelay={0}
                                PopperProps={{ disablePortal: true }}
                                placement="bottom"
                                // marginRight={pxToRem(20)}
                                sx={{
                                  "& .MuiTooltip-tooltip": {
                                    mt: pxToRem(4),
                                    py: pxToRem(4),
                                  },
                                }}
                              >
                                <Box
                                  // maxWidth={pxToRem(300)}
                                  flexShrink={0}
                                  textAlign="center"
                                  mt={pxToRem(5)}
                                >
                                  <Button
                                    startIcon={<Info />}
                                    sx={{
                                      fontSize: pxToRem(12),
                                      fontWeight: 600,
                                      color: "#BFBEBB",
                                      textAlign: "center",
                                    }}
                                  >
                                    How does it work?
                                  </Button>
                                </Box>
                              </Tooltip>
                            )}
                          </Box>
                        </>
                      )}
                      {/* Renew subscription */}
                      {course?.user_subscription?.status === "Expired" &&
                        !course?.user_subscription?.is_failed && (
                          <>
                            <Button
                              variant="yellow"
                              sx={{
                                height: pxToRem(54),
                                width: {xs: "100%", ssm: pxToRem(300)},
                                marginTop: "10px",
                              }}
                              onClick={() =>
                                handleLifeLongPurchase("Normal", "Normal")
                              }
                            >
                              {`Resubscribe for $${course.subscription_price}`}
                            </Button>
                          </>
                        )}
                      {/* resolve payment issue */}
                      {course?.user_subscription?.is_failed && (
                        <>
                          <Typography
                            component="p"
                            fontSize={pxToRem(16)}
                            fontWeight={600}
                            lineHeight={pxToRem(24)}
                            textAlign="center"
                            my={pxToRem(16)}
                            sx={{maxWidth: {xs: "100%", ssm: pxToRem(300)}}}
                          >
                            Your payment method failed. Resolve the issue.
                          </Typography>
                          <Button
                            variant="yellow"
                            sx={{ height: pxToRem(54), width: {xs: "100%", ssm: pxToRem(300)}, mb: pxToRem(32) }}
                            onClick={() =>
                              handleResolvePurchase(
                                course?.user_subscription?.id
                              )
                            }
                          >
                            Resolve Issue
                          </Button>
                        </>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </>
          )}
        </Box>
      </Stack>

      {course?.is_prolong_allowed && (
        <ProlongModal
          open={prolongModal}
          setOpen={setProlongModal}
          endDate={my_subscription?.end_date}
          duration={course?.duration}
          prolongPrice={course?.prolong_price}
          subscriptionPrice={course?.subscription_price}
          handleLifeLongPurchase={handleLifeLongPurchase}
        />
      )}
      {course?.subscription_price > 0 && (
        <>
          <InviteAssociateModal
            courseId={course?.id}
            open={inviteModalOpen}
            setOpen={setInviteModalOpen}
            associate={course?.associate}
            setFetchCourseAgain={setFetchCourseAgain}
          />
          <Toast
            open={toastOpen}
            onClose={() => {
              setToastOpen(false);
            }}
            message="Checkout link sent successfully."
          />
        </>
      )}

      {openGiftModal && (
        <GiftCourseModal
          open={openGiftModal}
          course={course}
          handleClose={handleCloseGiftModal}
        />
      )}
      <Toast
        open={shareToastOpen}
        onClose={() => setShareToastOpen(false)}
        message="Link copied to clipboard!"
      />
      <Toast
        open={errorToastOpen}
        onClose={() => setErrorToastOpen(false)}
        message={errorMessage}
      />
    </Box>
  );
};

export default SubscriptionTab;
