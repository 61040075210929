import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getCoursePreview } from "../../api/course";
import {
  Box,
  Button,
  Container,
  LinearProgress,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  linearProgressClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { pxToRem } from "px2rem2px";
import { Link } from "react-router-dom";
import { useModal } from "mui-modal-provider";
import { useAuth } from "../../utils/AuthContext";
import Guides from "../../components/Guides";
import Header from "../../components/Header";
import Sidebar from "../course/components/Sidebar";
import BlockBordered from "../../components/BlockBordered";
import TextLink from "../../components/TextLink";
import Rating from "../../components/Rating";
import Testimonial from "../../components/Testimonial";
import IconBtnCircular from "../../components/IconBtnCircular";
import PlayBtn from "../../components/PlayBtn";
import VideosSection from "../../components/VideosSection";
import PreviewModal from "./PreviewModal";
import LoginSubscribeModal from "./LoginSubscribeModal";
import PurchaseModal from "./PurchaseModal";
import LoginModal from "../auth/LoginModal";
import ThankModal from "./ThankModal";
import ErrorModal from "./ErrorModal";
import {
  formatDate,
  formatDuration,
  isTodayBetweenDates,
} from "../../utils/format";

// import PresentationImg from "../../images/pre-subscribed-banner.jpg";
import { ReactComponent as Play } from "../../images/play.svg";
import { ReactComponent as Star } from "../../images/star.svg";
import { ReactComponent as Share } from "../../images/share.svg";
import { ReactComponent as Info } from "../../images/info.svg";
import { ReactComponent as Gift } from "../../images/gift.svg";

import GiftCourseModal from "./GiftCourseModal";
import RichTextDisplay from "../../components/displayRichText";
import Toast from "../../components/Toast";
import SubscriptionFeedbackModal from "../course/components/SubscriptionFeedbackModal";
import {
  acceptInvitation,
  getStudent,
  rejectInvitation,
} from "../../api/student";
import {
  createAssociateCheckoutSession,
  resolveCheckoutSession,
} from "../../api/payment";
import shouldNavigateToPresubscribe from "../../utils/subscription";
// import {
//   createCheckoutSession,
//   resolveCheckoutSession,
// } from "../../api/payment";

const LinearProgressStyled = styled(LinearProgress)(({ theme }) => ({
  height: pxToRem(4),
  borderRadius: pxToRem(10),
  marginLeft: pxToRem(20),
  marginRight: pxToRem(20),
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "rgba(191,190,187,0.5)",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: pxToRem(10),
    backgroundColor: "#026670",
  },
}));

const PreSubscribe = () => {
  //share
  const location = useLocation();
  const navigate = useNavigate();
  const { checkAuth } = useAuth();
  const isAuthenticated = checkAuth();

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [errorToastOpen, setErrorToastOpen] = useState(false);

  const handleCloseToast = () => {
    setToastOpen(false);
    setToastMessage("");
  };

  const handleShareClick = () => {
    const fullUrl = `${window.location.origin}${location.pathname}`;
    navigator.clipboard
      .writeText(fullUrl)
      .then(() => {
        setToastOpen(true);
        setToastMessage("Link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  //endShare

  const { showModal, hideModal } = useModal();

  const { id } = useParams();
  const [course, setCourse] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [loading, setLoading] = useState(true);

  const [openGiftModal, setOpenGiftModal] = useState(false);

  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);

  const [isAlignedRight, setIsAlignedRight] = useState(false);

  // gift course feedback start

  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  const [success, setSuccess] = useState(null);
  const [cancel, setCancel] = useState(null);

  //
  const [redirectToSubscribed, setRedirectToSubscribed] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [feedbackAssociateOpen, setFeedbackAssociateOpen] = useState(false);
  const handleInvitationAcceptance = async (invitationId) => {
    if (isAuthenticated) {
      try {
        const response = await acceptInvitation(invitationId);
        const email = response.email;

        // const email = "jelaboy846@brinkc.com"; // for testing
        const checkoutSessionResponse = await createAssociateCheckoutSession(
          id,
          email
        );
        console.log("checkoutSessionResponse", checkoutSessionResponse);
        if (
          checkoutSessionResponse &&
          checkoutSessionResponse.data &&
          checkoutSessionResponse.data.checkout_url
        ) {
          // Redirect the user to the checkout URL
          window.location.href = checkoutSessionResponse.data.checkout_url;
        } else {
          console.error("Checkout URL not found in the response.");
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.detail
        ) {
          setModalTitle("Invitation Error");
          setErrorMessage(error?.response?.data?.detail);
          setFeedbackAssociateOpen(true);
        } else {
          console.error(
            "Error handling invitation acceptance and checkout session:",
            error
          );
        }
      }
    } else {
      const modal = showModal(LoginSubscribeModal, {
        openLoginModal: () => {
          hideModal(modal.id);
          showModal(LoginModal);
          // showModal(LoginModal, { onRedirectOpenModal: "giftModal" });
        },
        loginMessage: "You need to log in first to accept the invitation.",
        hideModal: () => hideModal(modal.id),
      });
    }
  };

  const handleCloseAssociateFeedback = () => {
    setFeedbackAssociateOpen(false);
    if (!redirectToSubscribed) return;
    navigate(`/course/${id}/`);
    setRedirectToSubscribed(false);
  };

  //reject invitation
  const handleReject = async (invitationId) => {
    if (isAuthenticated) {
      try {
        const response = await rejectInvitation(invitationId);
        setModalTitle("Invitation Feedback");
        setErrorMessage(response?.data?.detail);
        setFeedbackAssociateOpen(true);
        setSuccess(true);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.detail
        ) {
          setModalTitle("Invitation Error");
          setErrorMessage(error?.response?.data?.detail);
          setFeedbackAssociateOpen(true);
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.non_field_errors &&
          error.response.data.non_field_errors.includes(
            "You are already subscribed to this course."
          )
        ) {
          setRedirectToSubscribed(true);
          setModalTitle("Partner Discount");
          setErrorMessage("You are already subscribed to this course.");
          setFeedbackAssociateOpen(true);
        } else {
          console.error(
            "Error handling invitation acceptance and checkout session:",
            error
          );
        }
      }
    } else {
      const modal = showModal(LoginSubscribeModal, {
        openLoginModal: () => {
          hideModal(modal.id);
          showModal(LoginModal);
        },
        loginMessage: "You need to log in first to reject the invitation.",
        hideModal: () => hideModal(modal.id),
      });
    }
  };

  useEffect(() => {
    console.log(`redirectToSubscribed ${redirectToSubscribed}`);
  }, [redirectToSubscribed]);

  //

  const [giftFeedbackOpen, setGiftFeedbackOpen] = useState(false);

  const handleCloseGiftFeedback = () => {
    setGiftFeedbackOpen(false);
    clearParams();
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    // start: gifted to unregistred user

    const email = params.get("email");

    if (email) {
      if (!isAuthenticated) {
        const modal = showModal(LoginSubscribeModal, {
          openLoginModal: () => {
            hideModal(modal.id);
            showModal(LoginModal);
          },
          loginMessage:
            "Sign in or create an account to access the gifted course.",
          hideModal: () => hideModal(modal.id),
        });
      } else {
        const fetchStudent = async () => {
          try {
            const response = await getStudent();
            if (response.data.user.email !== email) {
              setModalTitle("Gifted Course");
              setErrorMessage(
                "It appears you're logged into an account with a different email address. Please log in with the correct email address, or contact customer support."
              );
              setGiftFeedbackOpen(true);
            } else {
              navigate(`/course/${id}/`);
            }
          } catch (error) {
            console.error("Error fetching student data:", error);
          }
        };

        fetchStudent();
      }
    }

    // end: gifted to unregistred user

    //start: accept reject
    const invitationId = params.get("invitation_id");
    const acceptInvitation = params.get("accept");
    if (invitationId && acceptInvitation === "false") {
      handleReject(invitationId);
    } else if (invitationId && acceptInvitation === "true" && id) {
      handleInvitationAcceptance(invitationId);
    }
    //end: accept reject

    const isAssociate = params.get("is_associate");
    const isNormalSubscription = params.get("is_normal_subscription");
    const isGifted = params.get("is_gifted");
    const successParam = params.get("success");
    const cancelParam = params.get("cancel");
    setSuccess(successParam);
    setCancel(cancelParam);

    if (successParam === "yes" || cancelParam === "yes") {
      if (isAssociate === "yes") {
        setModalTitle("Add Partner");
      } else if (isNormalSubscription === "yes") {
        setModalTitle("Course Subscription");
      } else if (isGifted === "yes") {
        setModalTitle("Gift Course");
      }
      setFeedbackOpen(true);
    }
  }, [location]);

  const clearParams = () => {
    const newUrl = window.location.pathname;
    window.history.replaceState(null, "", newUrl);
  };

  const handleCloseFeedback = () => {
    clearParams();
    setFeedbackOpen(false);
  };
  // gift course feedback end

  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(course?.description, "text/html");
    const hasAlignRightClass = doc.querySelector(".ql-align-right") !== null;
    setIsAlignedRight(hasAlignRightClass);
  }, [course?.description]);

  const handleReadMoreClick = (e) => {
    e.preventDefault();
    setShowFullDescription((prev) => !prev);
  };

  const handleContentExceedsMaxHeight = (exceedsMaxHeight) => {
    setShowReadMore(exceedsMaxHeight);
  };

  const handleOpenGiftModal = () => {
    if (!isAuthenticated) {
      const modal = showModal(LoginSubscribeModal, {
        openLoginModal: () => {
          hideModal(modal.id);
          // showModal(LoginModal);
          showModal(LoginModal, { onRedirectOpenModal: "giftModal" });
        },
        loginMessage:
          "You need to log in first to gift this course to someone.",
        hideModal: () => hideModal(modal.id),
      });
      // localStorage.setItem('openModal', 'giftModal');
    } else {
      setOpenGiftModal(true);
    }
  };

  const handleCloseGiftModal = () => {
    setOpenGiftModal(false);
  };

  const showSubscriptionModal = () => {
    const modal = showModal(PreviewModal, {
      course: course,
      openSubscriptionModal: (price, type) => {
        hideModal(modal.id);
        if (1 === 1) {
          const modal = showModal(PurchaseModal, {
            course: course,
            type: type,
            price: price,
            openThankModal: () => {
              hideModal(modal.id);
              showModal(ThankModal);
            },
            openErrorModal: () => {
              hideModal(modal.id);
              const errorModal = showModal(ErrorModal, {
                openPurchaseModal: () => {
                  hideModal(errorModal.id);
                  showModal(PurchaseModal, { course: course });
                },
              });
            },
          });
        } else {
          const modal = showModal(LoginSubscribeModal, {
            openLoginModal: () => {
              hideModal(modal.id);
              showModal(LoginModal);
            },
          });
        }
      },
    });
  };

  useEffect(() => {
    const redirectModal = localStorage.getItem("onRedirectOpenModal");

    if (
      !loading &&
      course &&
      !course?.is_subscribed &&
      redirectModal === "previewModal"
    ) {
      localStorage.setItem("onRedirectOpenModal", "");
      showSubscriptionModal();
    } else if (
      !loading &&
      course &&
      !course?.is_subscribed &&
      redirectModal === "giftModal"
    ) {
      localStorage.setItem("onRedirectOpenModal", "");
      handleOpenGiftModal();
    }
  }, [course]);

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const response = await getCoursePreview(id);
        setCourse(response.data);
        setLessons(response.data.lessons);
      } catch (error) {
        console.error("Error fetching the course:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourse();
  }, [id]);

  const shouldRenderContent = !loading || course;

  const isOnSale =
    course?.is_on_sale &&
    isTodayBetweenDates(course?.sale_start_date, course?.sale_end_date);

  const filteredWhatsIncluded = course?.whats_included_preview?.filter(
    (item) => item.trim() !== ""
  );
  const filteredWhatsLearned = course?.whats_learned_preview?.filter(
    (item) => item.trim() !== ""
  );

  const handleResolvePurchase = async (subscriptionId) => {
    try {
      const response = await resolveCheckoutSession(subscriptionId);
      window.location.href = response.data.checkout_url;
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error ||
          "An error occurred while trying to resolve the issue."
      );
      setErrorToastOpen(true);
    }
  };


  // Redirection
  useEffect(() => {
    if (id && course && course.subscription_status) {
      if (!shouldNavigateToPresubscribe(course.subscription_status)) {
        navigate(`/course/${id}`);
      }
    }
  }, [id, course?.subscription_status]);


  // show 50% of name and subtitle 
  const nameRef = useRef(null);
  const containerRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const checkTextWidth = () => {
      
      console.log('containerRef.current',containerRef.current)
      if (nameRef.current && containerRef.current) {
        const nameWidth = nameRef.current.offsetWidth;
        const containerWidth = containerRef.current.offsetWidth;

        // console.log('nameWidth',nameWidth,"containerWidth",containerWidth)
        console.log("containerWidth",containerWidth/2)

        // Check if the name width exceeds 50% of the container width
        if (nameWidth > containerWidth/2){
          setIsTruncated(true);
        } else {
          setIsTruncated(false);
        }

      }
    };

    // Trigger the check on component mount and window resize
    checkTextWidth();
    window.addEventListener("resize", checkTextWidth);

    // Clean up the resize event listener
    return () => window.removeEventListener("resize", checkTextWidth);
  }, [course?.name]);



  return (
    <>
      <Box position="relative" zIndex={2} pt={{ xs: "27px", md: 0 }}>
        <Guides color="rgba(0,0,0,0.02)" zIndex={-1} pb={pxToRem(30)} />
        <Header menuVisible />
        <Box className="header-padding" mt={pxToRem(40)}>
          <Container disableGutters>
            <Box
              position="relative"
              pb={!shouldRenderContent ? "0" : "56.3%"}
              sx={
                shouldRenderContent
                  ? {
                      "&::before": {
                        content: "''",
                        position: "absolute",
                        bottom: pxToRem(-40),
                        left: pxToRem(-40),
                        width: "46.6%",
                        height: "59.7%",
                        bgcolor: "#FCE181",
                        borderRadius: pxToRem(20),
                        zIndex: -1,
                      },
                    }
                  : {}
              }
            >
              {!shouldRenderContent ? (
                <Box
                  className="player-bgr"
                  width="100%"
                  position="relative"
                  display="flex"
                  borderRadius={pxToRem(23)}
                  overflow="hidden"
                  sx={{
                    backgroundColor: "transparent",
                    paddingBottom: "56.25%", // Maintain aspect ratio (e.g., 16:9)
                    "&:hover": { "& .toggle-tooltip": { opacity: 1 } },
                  }}
                >
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="100%"
                    sx={{
                      borderRadius: pxToRem(20),
                      position: "absolute", 
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                    }}
                  />
                </Box>
              ) : (
                <>
                  <Box
                     ref={containerRef}
                    position="absolute"
                    height="100%"
                    width="100%"
                    borderRadius={pxToRem(20)}
                    sx={{
                      // background:
                      //   "linear-gradient(to right, rgba(255,255,255,0) 30%,rgba(255,255,255,1) 65%,rgba(255,255,255,1) 100%)",
                      background:
                        "linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 1) 100%)",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      height="100%"
                      textAlign="right"
                      p={`${pxToRem(67)} ${pxToRem(60)} ${pxToRem(60)}`}
                    >
                      <Box mb={pxToRem(50)}>
                        <Box
                          className="rtl-section"
                          mb={pxToRem(29)}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography
                           ref={nameRef}
                            variant="sectionTitleHebrew"
                            fontSize={pxToRem(40)}
                            // sx={{
                            //   wordBreak: "break-word",
                            //   whiteSpace: "normal",
                            // }}
                            sx={{
                              wordBreak: "break-word",
                              whiteSpace: "normal",
                              overflow: "hidden",
                              textOverflow: isTruncated ? "ellipsis" : "unset",
                              display: "-webkit-box",
                              WebkitLineClamp: isTruncated ? 1 : "unset",
                              WebkitBoxOrient: "vertical",
                            }}
                          >
                            {course?.name}
                          </Typography>
                          <Typography
                            // variant="sectionTitleHebrew"
                            variant="medium"
                            color="#333333"
                            fontSize={pxToRem(25)}
                            width="50%"
                            lineHeight={1.2}
                            sx={{
                              wordBreak: "break-word",
                              whiteSpace: "normal",
                            }}
                          >
                            {course?.subtitle}
                          </Typography>
                        </Box>
                        <Typography
                          component="p"
                          fontSize={pxToRem(14)}
                          fontWeight={600}
                          mb={pxToRem(10)}
                        >
                          {formatDuration(course?.length)} • Released{" "}
                          {formatDate(course?.release_date)}
                        </Typography>
                        <Stack
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="flex-end"
                          gap={pxToRem(10)}
                          mb={pxToRem(50)}
                        >
                          <Typography fontWeight={700} mt={pxToRem(2)}>
                            {course?.rating}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "4px",
                            }}
                          >
                            <Rating
                              value={course?.rating}
                              precision={0.1}
                              readOnly
                            />
                          </Box>
                          <Typography fontWeight={700}>
                            •&nbsp;&nbsp; {course?.subscriber_count}{" "}
                            {course?.subscriber_count === 1
                              ? "Subscriber"
                              : "Subscribers"}
                          </Typography>
                        </Stack>

                        {course?.user_subscription?.is_failed ? (
                          <Button
                            variant="yellow"
                            sx={{ height: pxToRem(54), width: pxToRem(300) }}
                            onClick={() =>
                              handleResolvePurchase(
                                course?.user_subscription?.id
                              )
                            }
                          >
                            Resolve Issue
                          </Button>
                        ) : (
                          <Button
                            onClick={showSubscriptionModal}
                            variant="green"
                            sx={{
                              height: pxToRem(54),
                              borderWidth: pxToRem(2),
                              pl: pxToRem(40),
                              pr: pxToRem(40),
                            }}
                          >
                            <Typography
                              component="span"
                              fontWeight={600}
                              sx={{
                                display: "flex",
                                justifyContent: "space_between",
                              }}
                            >
                              {isOnSale ? (
                                <>
                                  <Typography component="span" fontWeight={600}>
                                    {course?.sale_price <= 0
                                      ? "Subscribe For Free"
                                      : `Subscribe: $${course?.sale_price}`}
                                  </Typography>

                                  <Typography
                                    fontSize="13px"
                                    fontWeight="600"
                                    color="#BFBEBB"
                                    sx={{
                                      textDecoration: "line-through",
                                      marginLeft: 1,
                                      transform: "translateY(0.9px)",
                                    }}
                                  >
                                    {course?.subscription_price > 0 &&
                                      `$${course?.subscription_price}`}
                                  </Typography>
                                </>
                              ) : (
                                <Typography component="span" fontWeight={600}>
                                  {course?.subscription_price <= 0
                                    ? "Subscribe For Free"
                                    : `Subscribe: $${course?.subscription_price}`}
                                </Typography>
                              )}
                            </Typography>
                          </Button>
                        )}
                      </Box>

                      <Stack
                        position="relative"
                        alignItems="flex-end"
                        gap={pxToRem(10)}
                      >
                        {!course?.is_free && (
                          <Tooltip
                            title="Gift Course"
                            placement="left"
                            PopperProps={{ disablePortal: true }}
                          >
                            <Box
                              sx={{
                                "& + .MuiTooltip-popper .MuiTooltip-tooltip": {
                                  backgroundColor: "#fce181",
                                  color: "#026670",
                                },
                                "& svg": { transition: "0.2s ease" },
                                "&:hover svg": { color: "#026670" },
                              }}
                              onClick={handleOpenGiftModal}
                            >
                              <IconBtnCircular className="yellow-ic">
                                <Gift color="#fff" />
                              </IconBtnCircular>
                            </Box>
                          </Tooltip>
                        )}

                        <Tooltip title="Share Now" placement="left">
                          <Box
                            sx={{
                              "& button:hover": {
                                backgroundColor: "#00545d !important",
                              },
                            }}
                            onClick={handleShareClick}
                          >
                            <IconBtnCircular className="hover-green active">
                              <Share
                                color="#026670"
                                style={{ marginRight: pxToRem(1.5) }}
                              />
                            </IconBtnCircular>
                          </Box>
                        </Tooltip>
                      </Stack>
                    </Box>
                  </Box>
                  <Box
                    onClick={showSubscriptionModal}
                    position="absolute"
                    top="50%"
                    left="27.2%"
                    sx={{
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      "& .MuiStack-root": { margin: `0 auto ${pxToRem(10)}` },
                    }}
                  >
                    <PlayBtn width={pxToRem(96)} height={pxToRem(96)}>
                      <Play
                        color="#026670"
                        width={pxToRem(20)}
                        height={pxToRem(24)}
                        style={{ marginLeft: "6%" }}
                      />
                    </PlayBtn>
                    <Button
                      variant="yellow"
                      sx={{
                        height: pxToRem(32),
                        p: `${pxToRem(5)} ${pxToRem(40)}`,
                      }}
                    >
                      Preview
                    </Button>
                  </Box>
                  <Box
                    display="flex"
                    position="absolute"
                    bgcolor="#fff"
                    borderRadius={pxToRem(20)}
                    zIndex={-1}
                    height="100%"
                    width="100%"
                    overflow="hidden"
                  >
                    <img
                      src={course?.thumb_nail}
                      alt=""
                      // style={{ borderRadius: pxToRem(20) }}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                </>
              )}
            </Box>
          </Container>
        </Box>
      </Box>
      <Box position="relative" pt={pxToRem(100)} mt={pxToRem(-30)}>
        <Guides color="rgba(0,0,0,0.06)" zIndex={-1} />

        <Box
          position="relative"
          pt={pxToRem(150)}
          mt={pxToRem(-110)}
          mb={pxToRem(150)}
        >
          <Container disableGutters>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              gap="6.5%"
            >
              <Box width="100%">
                <InfoBlock title="Course Description">
                  <BlockBordered str={course?.description}>
                    {course?.description ? (
                      <>
                        <RichTextDisplay
                          str={course?.description}
                          showFull={showFullDescription}
                          onContentExceedsMaxHeight={
                            handleContentExceedsMaxHeight
                          }
                        />
                        {showReadMore && (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: isAlignedRight
                                ? "flex-end"
                                : "flex-start",
                              mt: 1,
                            }}
                          >
                            <TextLink
                              href=""
                              onClick={handleReadMoreClick}
                              sx={{ "& span": { fontSize: pxToRem(14) } }}
                              showFullDescription={showFullDescription}
                            >
                              {showFullDescription ? "Show Less" : "Read More"}
                            </TextLink>
                          </Box>
                        )}
                      </>
                    ) : (
                      <Box>
                        <Skeleton variant="text" width="80%" />
                        <Skeleton variant="text" width="90%" />
                      </Box>
                    )}
                  </BlockBordered>
                </InfoBlock>

                {!shouldRenderContent ? (
                  <></>
                ) : (
                  <>
                    {filteredWhatsIncluded?.length > 0 && (
                      <InfoBlock title="Whats Included?">
                        <BlockBordered>
                          <ul className="list-items">
                            {filteredWhatsIncluded?.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                        </BlockBordered>
                      </InfoBlock>
                    )}

                    {filteredWhatsLearned?.length > 0 && (
                      <InfoBlock title="What You’ll Learn?">
                        <BlockBordered>
                          <ul className="list-items">
                            {filteredWhatsLearned?.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                        </BlockBordered>
                      </InfoBlock>
                    )}
                    <Box mt={pxToRem(80)}>
                      <Stack
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        gap={pxToRem(15)}
                        mb={pxToRem(15)}
                      >
                        <Typography
                          fontSize={pxToRem(20)}
                          lineHeight={pxToRem(30)}
                          fontWeight={700}
                        >
                          Reviews & Ratings
                        </Typography>
                        <Tooltip
                          title="The overall rating is calculated using the average of submitted ratings. Ratings and reviews can only be submitted when learners complete at least 40% of the course. This helps us avoid fake reviews and spam."
                          enterDelay={0}
                          enterTouchDelay={0}
                          PopperProps={{ disablePortal: true }}
                          placement="top"
                          sx={{
                            "& + .MuiTooltip-popper .MuiTooltip-tooltip": {
                              py: pxToRem(4),
                            },
                          }}
                        >
                          <Box>
                            <Button
                              startIcon={<Info />}
                              sx={{
                                fontSize: pxToRem(12),
                                fontWeight: 600,
                                color: "#BFBEBB",
                              }}
                            >
                              How are ratings calculated?
                            </Button>
                          </Box>
                        </Tooltip>
                      </Stack>
                      <Stack
                        flexDirection="row"
                        alignItems="center"
                        gap={pxToRem(10)}
                      >
                        <Typography fontWeight={700} mt={pxToRem(2)}>
                          {course?.rating}
                        </Typography>
                        <Rating
                          value={course?.rating}
                          precision={0.1}
                          readOnly
                        />
                        <Typography fontWeight={700}>
                          •&nbsp;&nbsp; {course?.subscriber_count}{" "}
                          {course?.subscriber_count === 1
                            ? "Subscriber"
                            : "Subscribers"}
                        </Typography>
                      </Stack>

                      <Box
                        mt={pxToRem(40)}
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          gap: pxToRem(20),
                        }}
                      >
                        <Stack
                          flexDirection="column-reverse"
                          justifyContent="space-between"
                          bgcolor="#EDECE8"
                          borderRadius={pxToRem(20)}
                          p={`${pxToRem(40)} ${pxToRem(20)}`}
                        >
                          {[...Array(5).keys()].reverse().map((val) => {
                            // const starCount = val + 1;
                            // const ratingCount =
                            //   course?.ratings_count_by_stars[
                            //     `${starCount}_stars`
                            //   ];
                            // const totalRatings = Object.values(
                            //   course?.ratings_count_by_stars
                            // ).reduce((sum, count) => sum + count, 0);
                            // const percentage =
                            //   totalRatings > 0
                            //     ? (ratingCount / totalRatings) * 100
                            //     : 0;
                            const starCount = val + 1;
                            const ratingsCountByStars = course?.ratings_count_by_stars || {};
                            const ratingCount = ratingsCountByStars[`${starCount}_stars`] || 0;
                            const totalRatings = Object.values(ratingsCountByStars).reduce((sum, count) => sum + count, 0);
                            const percentage = totalRatings > 0 ? (ratingCount / totalRatings) * 100 : 0;
                            return (
                              <Stack
                                key={val}
                                flexDirection="row"
                                alignItems="center"
                                borderRadius={pxToRem(30)}
                                p={`${pxToRem(11)} ${pxToRem(20)}`}
                                sx={{
                                  "&:hover": { backgroundColor: "#F7F6F2" },
                                  cursor: "pointer",
                                }}
                              >
                                <Typography
                                  fontWeight={700}
                                  lineHeight={pxToRem(18)}
                                  mr={pxToRem(10)}
                                  mt={pxToRem(1)}
                                >
                                  {starCount}
                                </Typography>
                                <Star color="#FCE181" />
                                <Box sx={{ flexGrow: 1 }}>
                                  <LinearProgressStyled
                                    variant="determinate"
                                    value={percentage}
                                  />
                                </Box>{" "}
                                <Typography
                                  fontWeight={700}
                                  lineHeight={pxToRem(18)}
                                >
                                  {ratingCount}
                                </Typography>
                              </Stack>
                            );
                          })}
                        </Stack>
                        <Testimonial avatar />
                        <Testimonial avatar />
                        <Testimonial />
                        <Testimonial />
                        <Testimonial avatar />
                      </Box>
                      <Button
                        to="/"
                        component={Link}
                        variant="yellow"
                        sx={{ height: pxToRem(54), mt: pxToRem(40) }}
                      >
                        See all Reviews
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
              <Sidebar
                lessons={lessons}
                isSubscribed={course?.is_subscribed}
                subscriptionStatus={course?.subscription_status}
                isFailed={course?.user_subscription?.is_failed}
                isPresubscribed
              />
            </Stack>
          </Container>
        </Box>
        <VideosSection
          courseId={course?.id}
          categoryId={course?.category?.id}
        />
      </Box>
      {course && (
        <>
          <GiftCourseModal
            open={openGiftModal}
            handleClose={handleCloseGiftModal}
            course={course}
          />
          <Toast
            open={toastOpen}
            onClose={handleCloseToast}
            message={toastMessage}
          />

          <SubscriptionFeedbackModal
            open={feedbackOpen}
            handleClose={handleCloseFeedback}
            modalTitle={modalTitle}
            success={success}
            cancel={cancel}
            // errorMessage={errorMessage}
          />

          <SubscriptionFeedbackModal
            open={feedbackAssociateOpen}
            handleClose={handleCloseAssociateFeedback}
            modalTitle={modalTitle}
            success={success}
            cancel={cancel}
            errorMessage={errorMessage}
          />

          <SubscriptionFeedbackModal
            open={giftFeedbackOpen}
            handleClose={handleCloseGiftFeedback}
            modalTitle={modalTitle}
            success={success}
            cancel={cancel}
            errorMessage={errorMessage}
          />
          <Toast
            open={errorToastOpen}
            onClose={() => setErrorToastOpen(false)}
            message={errorMessage}
          />
        </>
      )}
    </>
  );
};

const InfoBlock = (props) => {
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRadius: pxToRem(20),
        p: `${pxToRem(36)} ${pxToRem(40)} ${pxToRem(30)}`,
        mb: pxToRem(20),
      }}
    >
      <Typography
        fontSize={pxToRem(20)}
        lineHeight={pxToRem(30)}
        fontWeight={700}
        mb={pxToRem(30)}
      >
        {props?.title}
      </Typography>
      <Box borderTop="1px solid rgba(191,190,187,0.5)" pt={pxToRem(30)}>
        {props?.children}
      </Box>
    </Box>
  );
};

export default PreSubscribe;
