import { Box, Typography, Button, TextField, Divider } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import ReCAPTCHA from "react-google-recaptcha";
import { changeTemporaryPassword } from "../../api/student";
import getErrorsArray from "../../utils/getErrorsArray";
import FormInputPhone from "../../components/FormInputPhone";
import ModalHeader from "../../components/ModalHeader";
import { captchaKey } from "../../api/auth";
import { pxToRem } from "px2rem2px";
import { ButtonLogin, TextFieldStyled } from "../../components/StyledComponent";
import { saveStudentLocal } from "../../utils/AuthContext";

export default function ChangeTempPassword({
  setAction,
  tempEmail,
  tempPass,
  fullScreen,
  ...props
}) {
  const [isloading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const captchaRef = useRef();
  const [captchaValue, setCaptchaValue] = useState(null);
  const [verifyForm, setVerifyForm] = useState({
    email: "",
    temporaryPassword: "",
    password: "",
    confirmPassword: "",
  });

  //autofill inputs
  useEffect(() => {
    if (tempEmail && tempPass) {
      setVerifyForm((prevForm) => ({ ...prevForm, email: tempEmail }));
      setVerifyForm((prevForm) => ({
        ...prevForm,
        temporaryPassword: tempPass,
      }));
    }
  }, [tempEmail, tempPass]);

  // validate inputs

  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);

  const [formErrors, setFormErrors] = useState({
    email: false,
    temporaryPassword: false,
    password: false,
    confirmPassword: { length: false, match: false },
  });

  const handlePasswordErrors = (
    password,
    confirmPassword,
    setErrors,
    setConfirmPasswordTouched = null,
  ) => {
    if (setConfirmPasswordTouched) setConfirmPasswordTouched(true);

    const newErrors = {
      password: password.length < 6,
      confirmPassword: {
        length: confirmPassword.length < 6,
        match: password !== confirmPassword,
      },
    };

    setErrors((prevErrors) => ({
      ...prevErrors,
      ...newErrors,
    }));
  };

  //njnjnjnjn

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateForm = () => {
    const newErrors = {
      email: {
        required: !verifyForm.email,
        pattern: verifyForm.email && !validateEmail(verifyForm.email),
        server: "",
      },
      temporaryPassword: {
        required: !verifyForm.temporaryPassword,
        server: "", // Initialize server error for temporary password
      },
      password: {
        length: verifyForm.password.length < 6,
      },
      confirmPassword: {
        length: verifyForm.confirmPassword.length < 6,
        match: verifyForm.password !== verifyForm.confirmPassword,
      },
    };

    setFormErrors(newErrors);
    return !Object.values(newErrors).some(
      (error) => error.required || error.pattern || error.length || error.match,
    );
  };

  useEffect(() => {
    console.log("formErrors", verifyForm.password.length);
    // validateForm();
  }, [verifyForm]);

  async function handleVerify() {
    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    const payload = {
      email: verifyForm.email,
      temporary_password: verifyForm.temporaryPassword,
      new_password: verifyForm.password,
      confirm_new_password: verifyForm.confirmPassword,
      captcha_value: captchaValue,
    };

    setIsLoading(true);

    try {
      const res = await changeTemporaryPassword(payload);
      setIsLoading(false);
      window.localStorage.setItem("accessToken", res.data.access_token);
      window.localStorage.setItem("refreshToken", res.data.refresh_token);
      saveStudentLocal().then((res) => {
        window.location.reload();
      });

      console.log("restempdnjdjdjdjdj", res);
    } catch (e) {
      const err = e.response?.data || {}; 
      const newErrors = getErrorsArray(err); 
      console.log("jdnjdnjdnjdnedfbisnei", err);

      if (err.email) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: {
            ...prevErrors.email,
            server: err.email[0],
          },
        }));
      } else if (err.non_field_errors) {
        const nonFieldError = err.non_field_errors[0];
        if (nonFieldError === "No user found with this email.") {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            email: {
              ...prevErrors.email,
              server: nonFieldError,
            },
          }));
        } else if (nonFieldError === "The temporary password is not correct.") {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            temporaryPassword: {
              ...prevErrors.temporaryPassword,
              server: nonFieldError,
            },
          }));
        }
      }

      setErrors(newErrors);
      setIsLoading(false);
    } finally {
      captchaRef.current.reset();
      setIsLoading(false);
    }
  }

  const handleCaptchaChange = (e) => {
    setCaptchaValue(captchaRef.current.getValue());
  };

  return (
    <>
      <ModalHeader
        title="Change Temporary Password"
        inModal={props?.inModal}
        onClose={() => props.onClose()}
        textAlignment="center"
      />
      <Box
        sx={{
          minWidth: "400px",
          borderRadius: "10px",
          backgroundColor: "#f7f6f2",
          display: "flex",
          //justifyContent:fullScreen? undefined:"center",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          //backgroundColor:"green",
          minHeight: fullScreen ? "100vh" : undefined,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            backgroundColor: "#f7f6f2",
            padding: "40px",
            borderRadius: "10px",

            maxWidth: "400px",
            margin: "auto",
          }}
        >
          <TextFieldStyled
            placeholder="Email"
            value={verifyForm.email}
            onChange={(e) => {
              const newEmail = e.target.value;
              setVerifyForm({ ...verifyForm, email: newEmail });

              // Optionally, reset the server error when the user starts typing again
              setFormErrors((prevErrors) => ({
                ...prevErrors,
                email: {
                  ...prevErrors.email,
                  server: "", // Reset server error when the user types again
                },
              }));
            }}
            error={
              formErrors.email?.required ||
              formErrors.email?.pattern ||
              formErrors.email?.server
            }
            helperText={
              formErrors.email?.required
                ? "Email is required"
                : formErrors.email?.pattern
                  ? "Enter a valid email address."
                  : formErrors.email?.server
                    ? formErrors.email.server // Display server error message
                    : ""
            }
          />

          <TextFieldStyled
            placeholder="Temporary Password"
            value={verifyForm.temporaryPassword}
            onChange={(e) => {
              const newTempPassword = e.target.value;
              setVerifyForm({
                ...verifyForm,
                temporaryPassword: newTempPassword,
              });

              // Reset server error when the user types again
              setFormErrors((prevErrors) => ({
                ...prevErrors,
                temporaryPassword: {
                  ...prevErrors.temporaryPassword,

                  server: "", // Clear server error
                },
              }));
            }}
            type="password"
            error={
              formErrors.temporaryPassword?.required ||
              formErrors.temporaryPassword?.server
            }
            helperText={
              formErrors.temporaryPassword?.required
                ? "Temporary password is required"
                : formErrors.temporaryPassword?.server
                  ? formErrors.temporaryPassword.server // Display server error message
                  : ""
            }
          />

          <TextFieldStyled
            placeholder={"Password"}
            value={verifyForm.password}
            onChange={(e) => {
              const newPassword = e.target.value;
              setVerifyForm({ ...verifyForm, password: newPassword });

              handlePasswordErrors(
                newPassword,
                verifyForm.confirmPassword,
                setFormErrors,
                confirmPasswordTouched ? null : setConfirmPasswordTouched,
              );
            }}
            type="password"
            autoComplete="new-password"
            error={formErrors.password?.length} // Only display error if the 'length' error is true
            helperText={
              formErrors.password?.length
                ? "Password must be at least 6 characters"
                : ""
            }
          />
          <TextFieldStyled
            placeholder={"Confirm Password"}
            value={verifyForm.confirmPassword}
            onChange={(e) => {
              const newConfirmPassword = e.target.value;
              setVerifyForm({
                ...verifyForm,
                confirmPassword: newConfirmPassword,
              });

              handlePasswordErrors(
                verifyForm.password,
                newConfirmPassword,
                setFormErrors,
                setConfirmPasswordTouched,
              );
            }}
            type="password"
            autoComplete="new-password"
            error={
              formErrors.confirmPassword.length ||
              formErrors.confirmPassword.match
            }
            helperText={
              formErrors.confirmPassword.match
                ? "Passwords do not match"
                : formErrors.confirmPassword.length
                  ? "Password must be at least 6 characters"
                  : ""
            }
          />

          <ReCAPTCHA
            ref={captchaRef}
            sitekey={captchaKey}
            onChange={handleCaptchaChange}
          />

          <ButtonLogin
            disabled={isloading}
            onClick={handleVerify}
            variant="yellow"
            color="primary"
          >
            Update Password
          </ButtonLogin>
          <Box sx={{ width: "100%" }}>
            <Divider sx={{ borderColor: "#BFBEBB", my: pxToRem(20) }} />
            <Typography
              onClick={() => setAction("login")}
              underline="hover"
              sx={{
                fontWeight: 600,
                color: "#BFBEBB",
                cursor: "pointer",
                caretColor: "transparent",
                textAlign: "center",
                margin: "20px 0px",
                "&:hover": {
                  textDecoration: "underline",
                },
                "@media(max-width: 380px)": {
                  fontSize: "12px",
                },
              }}
            >
              Go back to Login
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
