import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { pxToRem } from "px2rem2px";

import BlockHeading from "../../../components/BlockHeading";

const AccountActivity = () => {
  return (
    <>
      <BlockHeading>
        <Typography component="h3" variant="blockTitle">
          User Data & Activity
        </Typography>
      </BlockHeading>

      <Box>
        <Stack
          flexDirection={{ xs: "column", ssm: "row" }}
          alignItems={{ xs: "flex-start", ssm: "center" }}
          justifyContent="space-between"
          gap="15px"
        >
          <Box>
            <Typography fontSize={pxToRem(18)} fontWeight={600} mb="3px">
              View something here
            </Typography>
            <Typography fontSize={pxToRem(16)} fontWeight={500}>
              Sed ut perspiciatis unde omnis iste natus accusantium.
            </Typography>
          </Box>
          <Button variant="yellow" size="small">
            View Questions
          </Button>
        </Stack>

        <Divider
          sx={{ my: pxToRem(30), borderColor: "rgba(191,190,187, 0.5)" }}
        />

        <Stack
          flexDirection={{ xs: "column", ssm: "row" }}
          alignItems={{ xs: "flex-start", ssm: "center" }}
          justifyContent="space-between"
          gap="15px"
        >
          <Box>
            <Typography fontSize={pxToRem(18)} fontWeight={700} mb="3px">
              View something here
            </Typography>
            <Typography fontSize={pxToRem(16)} fontWeight={700}>
              Sed ut perspiciatis unde omnis iste natus accusantium.
            </Typography>
          </Box>
          <Button variant="yellow" size="small">
            View Questions
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default AccountActivity;
