import { useState } from "react";
import { Box, Chip, MenuItem, Stack, Tab, Tabs, TextField, Typography, Accordion, AccordionSummary, AccordionDetails, Divider } from "@mui/material";
import { pxToRem } from "px2rem2px";
import { ReactComponent as Search } from "../images/search.svg";
import { ReactComponent as close } from "../images/close.svg";
import TestimonialMenu from "./TestimonialMenu";
import course1 from "../images/course-1.jpg"
import course2 from "../images/course-2.jpg"
import course3 from "../images/course-3.jpg"
import course4 from "../images/course-4.jpg"
import videoThumbnail1 from "../images/video-thumbnail-1.jpg"
import videoThumbnail2 from "../images/video-thumbnail-2.jpg"
import playWithCircle from "../images/play-with-circle.svg"
import { ReactComponent as PlusWithCircle } from "../images/plus-with-circle.svg"
import { ReactComponent as MinusWithCircle } from "../images/minus-with-circle.svg"
import { Close } from "@mui/icons-material";

const coursesDetails = [
  {image: course1, title: "צופרידנהייט אין שטוב", subscribers: "2.50M subscribers"},
  {image: course2, title: "Kureghor", subscribers: "2.50M subscribers"},
  {image: course3, title: "Kureghor", subscribers: "2.50M subscribers"},
  {image: course4, title: "Kureghor", subscribers: "2.50M subscribers"},
  {image: course1, title: "צופרידנהייט אין שטוב", subscribers: "2.50M subscribers"},
  {image: course2, title: "Kureghor", subscribers: "2.50M subscribers"},
]

const videosDetails = [
  {image: videoThumbnail1, title: "צופרידנהייט אין שטוב", views: "1.1 M Views"},
  {image: videoThumbnail2, title: "צופרידנהייט אין שטוב", views: "1.1 M Views"},
  {image: videoThumbnail1, title: "צופרידנהייט אין שטוב", views: "1.1 M Views"},
  {image: videoThumbnail2, title: "צופרידנהייט אין שטוב", views: "1.1 M Views"},
  {image: videoThumbnail1, title: "צופרידנהייט אין שטוב", views: "1.1 M Views"},
  {image: videoThumbnail2, title: "צופרידנהייט אין שטוב", views: "1.1 M Views"},
]

const questionsAnswers = [
  {question: "How does billing work?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim  veniam, quis nostrud exercitation ullamco laboris "},
  {question: "What is the pricing?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim  veniam, quis nostrud exercitation ullamco laboris "},
  {question: "How does billing work?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim  veniam, quis nostrud exercitation ullamco laboris "},
  {question: "What is the pricing?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim  veniam, quis nostrud exercitation ullamco laboris "},
  {question: "How does billing work?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim  veniam, quis nostrud exercitation ullamco laboris "},
  {question: "What is the pricing?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim  veniam, quis nostrud exercitation ullamco laboris "},
]

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
    <Box sx={{pr: pxToRem(10), pt: pxToRem(18)}}>
      <Box sx={{maxHeight: pxToRem(530), overflow: "auto", "&::-webkit-scrollbar": {width: pxToRem(6)}, "&::-webkit-scrollbar-track": {backgroundColor: "#D9D9D9", borderRadius: pxToRem(100)}, "&::-webkit-scrollbar-thumb": {background: "#B6B6B6", borderRadius: pxToRem(100)}}}>
          {value === index && <Box sx={{ p: `0 ${pxToRem(18)}`}}>{children}</Box>}
      </Box>
    </Box>
    </div>
  );
}

const CoursesTab = ({tab}) => {

  const [showAll, setShowAll] = useState(false);

  const handleViewMore = () => {
    setShowAll((prev) => !prev);
  };

  let visibleCourses;
  if (tab === 0) {
    visibleCourses = !showAll ? coursesDetails.slice(0, 2) : coursesDetails;
  } else {
    visibleCourses = !showAll ? coursesDetails.slice(0, 4) : coursesDetails;
  }


  return (
    <>
      {visibleCourses.map((courseDetails, index)=>(
        <Stack key={index} direction="row" alignItems="center" justifyContent="space-between" sx={{padding: `${pxToRem(18)} 0 ${pxToRem(18)} ${pxToRem(12)}`, borderBottom: tab === 0 || index === visibleCourses.length - 1 ? 'none' : '1px solid #CFCFCF',}}>
          <Stack direction="row" gap={pxToRem(15)} alignItems="center" sx={{cursor: "pointer"}}>
            <Box sx={{overflow: "hidden", borderRadius: pxToRem(5), width: pxToRem(50), height: pxToRem(50)}}><img src={courseDetails.image} alt="" /></Box>
            <Box>
              <Typography variant="large" fontWeight="600" component="p">{courseDetails.title}</Typography>
              <Typography variant="small" fontWeight="400" component="p" color="#C0C0C0">{courseDetails.subscribers}</Typography>
            </Box>
          </Stack>
          <Box>
            <TestimonialMenu sx={{"& svg": {color: "#D9D9D9"}, transform: "rotate(90deg)", margin: 0}}>
              <MenuItem onClick={() => {}}>Submenu link 01</MenuItem>
              <MenuItem onClick={() => {}}>Another link 02</MenuItem>
          </TestimonialMenu>
          </Box>
        </Stack>     
      ))}

      {((tab === 0 && coursesDetails.length > 2)) && (
        <Typography
          variant="button"
          component="p"
          sx={{ cursor: 'pointer', fontWeight: '500', textTransform: "capitalize", textDecoration: "underline", "&:hover": {color: "#026670"}, p: `0 0 ${pxToRem(20)} ${pxToRem(12)}` }}
          onClick={handleViewMore}
        >
          {showAll ? 'Show Less' : 'View More'}
        </Typography>
      )}

      {((tab !== 0 && coursesDetails.length > 4)) && (
        <Stack alignItems="center">
          <Box sx={{borderRadius: pxToRem(50), backgroundColor: "#E9E9E9", p: `${pxToRem(8)} ${pxToRem(18)}`, display: "inline-block", mt: pxToRem(10)}} onClick={handleViewMore}>
            <Typography
              variant="button"
              component="p"
              sx={{ cursor: 'pointer', fontWeight: '500', textTransform: "capitalize", "&:hover": {color: "#026670"} }}
            >
              {showAll ? 'Show Less' : 'View More Results'}
            </Typography>
          </Box>
        </Stack>
      )}
    </>
  )
}

const VideosTab = ({tab}) => {

  const [showAll, setShowAll] = useState(false);

  const handleViewMore = () => {
    setShowAll((prev) => !prev);
  };

  let visibleVideos;
  if (tab === 0) {
    visibleVideos = !showAll ? videosDetails.slice(0, 2) : videosDetails;
  } else {
    visibleVideos = !showAll ? videosDetails.slice(0, 4) : videosDetails;
  }
  return (
    <>
      <Stack sx={{padding: `${pxToRem(20)} 0 ${pxToRem(20)} ${pxToRem(12)}`, rowGap: pxToRem(16)}}>
        {visibleVideos.map((videoDetails, index)=>(
          <Stack key={index} direction="row" gap={pxToRem(15)} alignItems="center" sx={{cursor: "pointer"}}>
            <Box sx={{overflow: "hidden", borderRadius: pxToRem(6), width: pxToRem(92), height: pxToRem(60), position: "relative"}}>
              <img src={videoDetails.image} alt="" />
              <Box sx={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: pxToRem(24), height: pxToRem(24) }}><img src={playWithCircle} alt="" /></Box>
            </Box>
            <Box>
              <Typography variant="large" fontWeight="600" component="p">{videoDetails.title}</Typography>
              <Typography variant="small" fontWeight="400" component="p" color="#C0C0C0">{videoDetails.views}</Typography>
            </Box>
          </Stack>
        ))}
      </Stack>
      {((tab === 0 && coursesDetails.length > 2)) && (
        <Typography
          variant="button"
          component="p"
          sx={{ cursor: 'pointer', fontWeight: '500', textTransform: "capitalize", textDecoration: "underline", "&:hover": {color: "#026670"}, p: `0 0 ${pxToRem(20)} ${pxToRem(12)}` }}
          onClick={handleViewMore}
        >
          {showAll ? 'Show Less' : 'View More'}
        </Typography>
      )}

      {((tab !== 0 && coursesDetails.length > 4)) && (
        <Stack alignItems="center">
          <Box sx={{borderRadius: pxToRem(50), backgroundColor: "#E9E9E9", p: `${pxToRem(8)} ${pxToRem(18)}`, display: "inline-block", mt: pxToRem(10)}} onClick={handleViewMore}>
            <Typography
              variant="button"
              component="p"
              sx={{ cursor: 'pointer', fontWeight: '500', textTransform: "capitalize", "&:hover": {color: "#026670"} }}
            >
              {showAll ? 'Show Less' : 'View More Results'}
            </Typography>
          </Box>
        </Stack>
      )}
    </>
  );
}

const QATab = ({tab}) => {

  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const [showAll, setShowAll] = useState(false);

  const handleViewMore = () => {
    setShowAll((prev) => !prev);
  };

  let visibleCourses;
  if (tab === 0) {
    visibleCourses = !showAll ? questionsAnswers.slice(0, 2) : questionsAnswers;
  } else {
    visibleCourses = !showAll ? questionsAnswers.slice(0, 4) : questionsAnswers;
  }

  return (
    <>
    <Box my={pxToRem(16)}>
      <Stack rowGap={pxToRem(16)}>
        {visibleCourses.map((questionAnswer, index)=>(
          <Accordion key={index}
            sx={{borderRadius: `${pxToRem(6)} !important`, minHeight: pxToRem(45), margin: "0", backgroundColor: "#E8E8E8", "&.Mui-expanded": {"&::after": { content: 'none' }, boxShadow: "none", margin: 0}, "& .MuiAccordionSummary-root, & .MuiAccordionSummary-root.Mui-expanded": {padding: {xs: `0 ${pxToRem(30)} 0 ${pxToRem(20)} !important`, ssm: `0 ${pxToRem(18)} !important`}}, "& .MuiAccordionSummary-expandIconWrapper": {transform: "none !important"}}} onChange={handleChange}>
            <AccordionSummary
              expandIcon={expanded ? <MinusWithCircle style={{width: pxToRem(18)}} /> : <PlusWithCircle style={{width: pxToRem(18)}} />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{minHeight: `${pxToRem(44)} !important`, gap: `${pxToRem(20)} !important`, "& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded" : {margin: 0}}}
            >
              <Typography fontSize={pxToRem(16)} fontWeight={500} color="primary">
                {questionAnswer.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{fontSize: pxToRem(14), lineHeight: pxToRem(20), padding: `0 ${pxToRem(20)} ${pxToRem(20)} ${pxToRem(20)} !important`}} >
              {questionAnswer.answer}
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    </Box>

    {((tab === 0 && coursesDetails.length > 2)) && (
        <Typography
          variant="button"
          component="p"
          sx={{ cursor: 'pointer', fontWeight: '500', textTransform: "capitalize", textDecoration: "underline", "&:hover": {color: "#026670"}, p: `0 0 ${pxToRem(20)} ${pxToRem(12)}` }}
          onClick={handleViewMore}
        >
          {showAll ? 'Show Less' : 'View More'}
        </Typography>
      )}

      {((tab !== 0 && coursesDetails.length > 4)) && (
        <Stack alignItems="center">
          <Box sx={{borderRadius: pxToRem(50), backgroundColor: "#E9E9E9", p: `${pxToRem(8)} ${pxToRem(18)}`, display: "inline-block", mt: pxToRem(10)}} onClick={handleViewMore}>
            <Typography
              variant="button"
              component="p"
              sx={{ cursor: 'pointer', fontWeight: '500', textTransform: "capitalize", "&:hover": {color: "#026670"} }}
            >
              {showAll ? 'Show Less' : 'View More Results'}
            </Typography>
          </Box>
        </Stack>
      )}
  </>
  )
}

const SearchTabs = () => {

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{border: "1px solid #D7D7D7", borderRadius: pxToRem(14.5), py: `${pxToRem(24)}`, backgroundColor: "#F5F5F5", maxWidth: pxToRem(559), position: "absolute", right: 0, top: 0, zIndex: 2}}>
      <Stack flexDirection="row" alignItems="center" justifyContent="space-between" columnGap={pxToRem(20)} mb={pxToRem(13)}>
        <Stack flexDirection="row" alignItems="center" sx={{pl: pxToRem(30), flexGrow: 1}}>
          <Search style={{flexShrink: 0, color: "#C0C0C0", width: pxToRem(18)}} />
          <TextField
            id="filled-search"
            type="search"
            sx={{"& .MuiInputBase-input": {padding: `0 ${pxToRem(22)}`, fontSize: pxToRem(20), fontWeight: "500"}, flexGrow: 1}}
          />
        </Stack>
        <Box sx={{pr: pxToRem(30)}}><Close style={{color: "#C0C0C0"}} /></Box>
      </Stack>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', px: pxToRem(30) }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{"& .MuiTabs-flexContainer": {columnGap: pxToRem(37)}, minHeight: pxToRem(50)}}  TabIndicatorProps={{ style: { display: "none"}}}>
            <Tab sx={{padding: 0, minWidth: "auto", color: "#C0C0C0", fontSize: pxToRem(18), textTransform: "capitalize", "&.Mui-selected .MuiChip-root": {color: "#ffffff", backgroundColor: "#333333"}, minHeight: pxToRem(50)}} label="All" />
            <Tab sx={{padding: 0, minWidth: "auto", color: "#C0C0C0", fontSize: pxToRem(18), textTransform: "capitalize", "&.Mui-selected .MuiChip-root": {color: "#ffffff", backgroundColor: "#333333"}, minHeight: pxToRem(50)}} label={<Stack direction="row" alignItems="center">Courses <Chip variant="solid" sx={{color: "#949494", fontSize: pxToRem(13), fontWeight: "500", flexShrink: 0, background: "#E3E3E3", px: pxToRem(8), py: pxToRem(1), ml: pxToRem(5), borderRadius: pxToRem(8), "& .MuiChip-label": {padding: 0}, height: "auto"}} label="5" /></Stack>} />
            <Tab sx={{padding: 0, minWidth: "auto", color: "#C0C0C0", fontSize: pxToRem(18), textTransform: "capitalize", "&.Mui-selected .MuiChip-root": {color: "#ffffff", backgroundColor: "#333333"}, minHeight: pxToRem(50)}} label={<Stack direction="row" alignItems="center">Q&A <Chip variant="solid" sx={{color: "#949494", fontSize: pxToRem(13), fontWeight: "500", flexShrink: 0, background: "#E3E3E3", px: pxToRem(8), py: pxToRem(1), ml: pxToRem(5), borderRadius: pxToRem(8), "& .MuiChip-label": {padding: 0}, height: "auto"}} label="3" /></Stack>} />
            <Tab sx={{padding: 0, minWidth: "auto", color: "#C0C0C0", fontSize: pxToRem(18), textTransform: "capitalize", "&.Mui-selected .MuiChip-root": {color: "#ffffff", backgroundColor: "#333333"}, minHeight: pxToRem(50)}} label={<Stack direction="row" alignItems="center">Videos <Chip variant="solid" sx={{color: "#949494", fontSize: pxToRem(13), fontWeight: "500", flexShrink: 0, background: "#E3E3E3", px: pxToRem(8), py: pxToRem(1), ml: pxToRem(5), borderRadius: pxToRem(8), "& .MuiChip-label": {padding: 0}, height: "auto"}} label="0" /></Stack>} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Typography variant="large" fontWeight="700" component="h4" lineHeight={pxToRem(25)} pl={pxToRem(12)}>Courses</Typography>
          <CoursesTab tab={0} />
          <Divider sx={{borderColor: "#D7D7D7"}} />
          <Typography variant="large" fontWeight="700" component="h4" lineHeight={pxToRem(25)} pl={pxToRem(12)} pt={pxToRem(22)}>Q&A</Typography>
          <QATab tab={0} />
          <Divider sx={{borderColor: "#D7D7D7"}} />
          <Typography variant="large" fontWeight="700" component="h4" lineHeight={pxToRem(25)} pl={pxToRem(12)} pt={pxToRem(22)}>Videos</Typography>
          <VideosTab tab={0} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <CoursesTab />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
            <QATab />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <VideosTab />
        </CustomTabPanel>
      </Box>
    </Box>
)
}

export default SearchTabs;