import React, { useState, useEffect, useRef } from "react";
import {
  createBookmark,
  deleteBookmark,
  listBookmarks,
} from "../../api/course";
import { Badge, Box, Button, Stack, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { pxToRem } from "px2rem2px";
import TextLink from "../../components/TextLink";
import BlockBordered from "../../components/BlockBordered";
import Note from "./Note";
import IconBtn from "./IconBtn";
import DeleteBtn from "./DeleteBtn";
import { ReactComponent as Download } from "../../images/writing.svg";
import { ReactComponent as Print } from "../../images/printer.svg";
import api from "../../api/api";
import Chevron from "../../images/chevron.svg";
import { useTheme } from "@emotion/react";

const Block = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: pxToRem(25),
  [theme.breakpoints.up("ssm")]: {
    padding: `${pxToRem(30)} ${pxToRem(40)} ${pxToRem(40)} ${pxToRem(40)}`,
    borderRadius: pxToRem(20),
  }
}));

const NotesBookmarks = ({
  course,
  bookmarks,
  setBookmarks,
  notes,
  setNotes,
  jumpToLatestProgress,
  jumpToBookMark,
  isCourseCompleted,
}) => {
  const theme = useTheme();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));


  const bookmarksRef = useRef(null);
  const [bookmarksAccordianIsOpen, setBookmarksAccordianIsOpen] = useState(false);
  const handleBookmarksClick = () => {
    setBookmarksAccordianIsOpen(!bookmarksAccordianIsOpen);
  };

  const courseNotesRef = useRef(null);
  const [courseNotesAccordianIsOpen, setCourseNotesAccordianIsOpen] = useState(false);
  const handleCourseNotesClick = () => {
    setCourseNotesAccordianIsOpen(!courseNotesAccordianIsOpen);
  };

  useEffect(() => {
    const getBookmarks = async () => {
      try {
        const response = await listBookmarks(course?.id);
        console.log("bookmarksresponse",response);
      } catch (error) {
        console.log(error);
      }
    };
    getBookmarks()
  }, []);

  const formatTimestamp = (timestamp) => {
    const minutes = Math.floor(timestamp / 60);
    const seconds = Math.floor(timestamp % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleAddBookmark = async (lessonId, timestamp) => {
    try {
      const response = await createBookmark(lessonId, timestamp);
      const newBookmark = response.data;
      setBookmarks([...bookmarks, newBookmark]);
    } catch (error) {
      console.error("Error adding bookmark:", error);
    }
  };

  const handleRemoveBookmark = async (bookmarkId) => {
    try {
      await api.delete(`/api/courses/bookmarks/${bookmarkId}/`);
      setBookmarks((prevBookmarks) =>
        prevBookmarks.filter((bookmark) => bookmark?.id !== bookmarkId),
      );
    } catch (error) {
      console.error("Error removing bookmark:", error);
    }
  };

  const downloadNotes = (notes) => {
    const notesContent = notes
      ?.map((note) => `${note?.title}\n\n${note?.content}\n\n\n\n`)
      ?.join("");

    const element = document.createElement("a");
    const file = new Blob([notesContent], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `${course.name}_notes.txt`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const printNotes = (notes) => {
    const notesContent = notes
      .map(
        (note) => `
          <div style="margin-bottom: 20px;">
            <div style="border: 1px solid #ccc; padding: 10px; margin-bottom: 10px;">
              <div style="border: 1px solid #ccc; padding: 10px; margin-bottom: 10px;">
                <div>
                ${note?.title}
                </div>
                <div>
                ${formatTimestamp(note?.timestamp)}
                </div>
              </div>
              ${note?.content}
            </div>
          </div>
        `,
      )
      .join("");

    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>${course?.name} Notes</title>
          <style>
            body {
              font-family: Arial, sans-serif;
            }
            .note {
              margin-bottom: 20px;
            }
            .note-content {
              border: 1px solid #ccc;
              padding: 10px;
              margin-bottom: 10px;
            }
          </style>
        </head>
        <body>
          <h1>${course?.name} Note</h1>
          ${notesContent}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  console.log(bookmarks, notes);

  return (
    <>
      <Box>
        <Block>
          <Stack
            width="100%"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            onClick={handleBookmarksClick}
          >
            <Stack flexDirection="row">
              <Badge
                badgeContent={
                  <Typography
                    fontSize={pxToRem(12)}
                    fontWeight={700}
                    color="#BFBEBB !important"
                  >
                    {bookmarks?.length} / 10
                  </Typography>
                }
                sx={{
                  mr: {ssm: pxToRem(90)},
                  "& .MuiBadge-badge": {
                    right: pxToRem(-33),
                    height: pxToRem(27),
                    borderRadius: pxToRem(20),
                    backgroundColor: "#EDECE8",
                    p: `${pxToRem(5)} ${pxToRem(10)}`,
                  },
                }}
              >
                <Typography
                  position="relative"
                  fontSize={pxToRem(20)}
                  fontWeight={700}
                >
                  Bookmarks
                </Typography>
              </Badge>
            </Stack>
            <Stack flexDirection="row" columnGap={{xs: "12px", ssm: 0}}>
              {!isCourseCompleted && (
                <Button
                  variant="yellow"
                  sx={{
                    height: `${pxToRem(40)} !important`,
                    fontSize: pxToRem(14),
                    p: {xs: `${pxToRem(20)}`, ssm: `${pxToRem(20)} ${pxToRem(30)}`},
                    boxShadow: "none",
                  }}
                  onClick={jumpToLatestProgress}
                >
                  {mobile_ssm? "Latest" : "Jump to Latest Progress"}
                </Button>
              )}
              <Box sx={{ display:{ xs: "flex", ssm: "none" }, alignItems:"center", justifyContent:"center", paddingRight: pxToRem(4)}}>
                <img
                  src={Chevron}
                  style={{
                    transform: bookmarksAccordianIsOpen
                      ? "rotate(-90deg)"
                      : "rotate(90deg)",
                    transition: "all 0.3s ease-in-out",
                    height: pxToRem(15),
                  }}
                  alt="Chevron"
                />
              </Box>
            </Stack>
          </Stack>
          <Box
            ref={bookmarksRef}
            sx={{
              overflow: "hidden",
              maxHeight: {
                xs: bookmarksAccordianIsOpen
                  ? `${bookmarksRef.current.scrollHeight}px`
                  : "0px",
                ssm: "100%",
              },
            }}
            style={{ transition: "max-height 0.3s ease-in-out" }}
          >
            <Box sx={{mt: pxToRem(24),
              display: "grid",
              gridTemplateColumns: {ssm: bookmarks.length > 4 ? "1fr 1fr" : "1fr"},
              gap: `0 ${pxToRem(40)}`,}}>
              {bookmarks.map((bookmark) => (
                <Stack
                  key={bookmark.id}
                  // to="/"
                  // component={Link}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="10px"
                  py={pxToRem(10)}
                  sx={{
                    position: "relative",
                    "&::after": {
                      content: "''",
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: "100vw",
                      height: "1px",
                      backgroundColor: "#DFDEDD",
                    },
                    "&:hover": {
                      "& .text": {
                        color: "#333",
                      },
                      "& .bookmark-number": {
                        backgroundColor: "#FCE181",
                        span: {
                          color: "#026670",
                        },
                      },
                      "& .deleteBtn": {
                        display: "flex",
                      },
                    },
                  }}
                >
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    columnGap={pxToRem(15)}
                    onClick={() =>
                      jumpToBookMark(bookmark?.timestamp, bookmark?.lesson_id)
                    }
                    sx={{cursor:"pointer"}}
                  >
                    <Box
                      className="bookmark-number"
                      width={pxToRem(27)}
                      height={pxToRem(27)}
                      borderRadius="100%"
                      bgcolor="#EDECE8"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexShrink={0}
                    >
                      <Typography
                        component="span"
                        fontSize={pxToRem(12)}
                        fontWeight={700}
                        color="#BFBEBB"
                      >
                        {bookmarks?.indexOf(bookmark) + 1}
                      </Typography>
                    </Box>
                    <Typography
                      className="text"
                      display="block"
                      fontSize={pxToRem(16)}
                      lineHeight={pxToRem(20)}
                      fontWeight={600}
                      color="secondary"
                    >
                      {bookmark?.lesson}
                    </Typography>
                  </Stack>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    gap="5px"
                    sx={{ "& svg": { mb: "1px" } }}
                  >
                    <DeleteBtn
                      onDelete={() => handleRemoveBookmark(bookmark?.id)}
                    />
                    <Typography
                      fontSize={pxToRem(16)}
                      fontWeight={600}
                      color="primary"
                    >
                      {formatTimestamp(bookmark?.timestamp)}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
            </Box>
          </Box>
        </Block>
        <Box display="flex" justifyContent="flex-end">
          <TextLink
            href="/"
            sx={{ marginTop: pxToRem(20), marginRight: {xs: pxToRem(25), ssm: pxToRem(40)} }}
          >
            Add Bookmark
          </TextLink>
        </Box>
      </Box>

      <Box mt={{xs: pxToRem(30), ssm: pxToRem(60)}}>
        <Block>
          <Stack
            width="100%"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            onClick={handleCourseNotesClick}
          >
            <Stack flexDirection="row">
              <Typography fontSize={pxToRem(20)} fontWeight={700}>
                Course Notes
              </Typography>
            </Stack>
            <Stack flexDirection="row" columnGap={{xs: "20px", ssm: 0}}>
              <Stack
                flexDirection="row"
                gap={pxToRem(10)}
                sx={{ "& button": { backgroundColor: "#EDECE8" } }}
              >
                <Tooltip
                  title="Download notes to text file"
                  enterDelay={0}
                  enterTouchDelay={0}
                  placement="top"
                >
                  <Box>
                    <IconBtn
                      className="hover-green"
                      onClick={() => downloadNotes(notes)}
                    >
                      <Download color="#026670" />
                    </IconBtn>
                  </Box>
                </Tooltip>
                <Tooltip
                  title="Print Notes"
                  enterDelay={0}
                  enterTouchDelay={0}
                  placement="top"
                >
                  <Box>
                    <IconBtn
                      className="hover-green"
                      onClick={() => printNotes(notes)}
                    >
                      <Print color="#026670" />
                    </IconBtn>
                  </Box>
                </Tooltip>
              </Stack>
              <Box sx={{ display:{ xs: "flex", ssm: "none" }, alignItems:"center", justifyContent:"center", paddingRight: pxToRem(4)}}>
                <img
                  src={Chevron}
                  style={{
                    transform: courseNotesAccordianIsOpen
                      ? "rotate(-90deg)"
                      : "rotate(90deg)",
                    transition: "all 0.3s ease-in-out",
                    height: pxToRem(15),
                  }}
                  alt="Chevron"
                />
              </Box>
            </Stack>
          </Stack>
          {notes?.map((note) => (
            <Stack key={note?.id} 
              ref={courseNotesRef} sx={{maxHeight: {
              xs: courseNotesAccordianIsOpen
                ? `${courseNotesRef.current.scrollHeight}px`
                : "0px",
              ssm: "100%",
              mt: pxToRem(24)
            }}}
            style={{ transition: "max-height 0.3s ease-in-out" }}
            >
              <Note note={note}>
                <BlockBordered mb={pxToRem(20)}>
                  <Box className="note-edited">
                    <Typography variant="medium" lineHeight={pxToRem(22)}>
                      {note?.content}
                    </Typography>
                  </Box>
                </BlockBordered>
                <TextLink
                  href=""
                  sx={{
                    marginBottom: pxToRem(10),
                    "& span": { fontSize: pxToRem(14) },
                  }}
                >
                  Save Note
                </TextLink>
              </Note>
            </Stack>
          ))}
        </Block>
        <Box display="flex" justifyContent="flex-end">
          <TextLink
            href="/"
            sx={{ marginTop: pxToRem(20), marginRight: {xs: pxToRem(25), ssm: pxToRem(40)} }}
          >
            Add Note
          </TextLink>
        </Box>
      </Box>
    </>
  );
};

export default NotesBookmarks;
