import { Box, useMediaQuery,  useTheme } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import { pxToRem } from "px2rem2px";
import ModalProvider from "mui-modal-provider";
import Footer from "./Footer";
import { useEffect } from "react";

const Layout = () => {
  const theme = useTheme();
  const location = useLocation();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));

  const shouldHideFooter = mobile_ssm && location.pathname.startsWith('/course/');

  useEffect(() => {
    console.log(`Current path: ${location.pathname}`); 
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <ModalProvider>
      <Box
        position="relative"
        maxWidth={pxToRem(1920)}
        m="0 auto"
        p={{ xs: 0, md: pxToRem(30) }}
        sx={{ overflowX: {ssm: "hidden"} }}
      >
        <Outlet />
        {!shouldHideFooter && <Footer />}
      </Box>
    </ModalProvider>
  );
};

export default Layout;
