import { createBrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import UserLayout from "./components/user/UserLayout";
import LoginPage from "./pages/auth";
import PasswordResetRequest from "./pages/auth/PasswordResetRequest";
import VerifyEmail from "./pages/auth/VerifyEmail";

import Home from "./pages/home";
import Course from "./pages/course";
import PreSubscribe from "./pages/preSubscribe";
import Dashboard from "./pages/user/dashboard";
import Notifications from "./pages/user/notifications";
import AccountSettings from "./pages/user/accountSettings";
import UserCourses from "./pages/user/courses";
import Favorites from "./pages/user/favorites";
import PurchaseHistory from "./pages/purchaseHistory";
import VerifyPhone from "./pages/auth/VerifyPhone";
import CompleteGoogleSignUp from "./pages/auth/CompleteGoogleSignUp";
// import CompleteGoogleSignUpDummyData from "./pages/auth/CompleteGoogleSignUpDummyData";
import ResetPassword from "./pages/auth/ResetPassword";
import AuthRedirect from "./pages/auth/AuthRedirect";
import Courses from "./pages/courses";
import Parsha from "./pages/parsha";
import Playback from "./pages/user/playBack";
import AccountActivity from "./pages/user/accountActivity";
import Contact from "./pages/contact";
import Faq from "./pages/faq";
import VideoSection from "./pages/videoSection";

const useRouter = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        /*   {
          path: "/login",
          element: <LoginPage />,
        }, */
        {
          path: "/complete-google-signup",
          element: <CompleteGoogleSignUp />,
        },
        // {
        //   path: "/complete-google-signup-dummy-data",
        //   element: <CompleteGoogleSignUpDummyData />,
        // },
        {
          path: "/verify-email",
          element: <VerifyEmail />,
        },

        {
          path: "/verify-phone",
          element: <VerifyPhone />,
        },

        {
          path: "/request-reset-password",
          element: <PasswordResetRequest />,
        },
        {
          path: "/reset-password",
          element: <ResetPassword />,
        },
        {
          path: "/google-auth",
          element: <AuthRedirect />,
        },
      ],
    },
    {
      path: "/course",
      element: <Layout />,
      children: [
        {
          path: "/course",
          children: [
            {
              path: "/course/:id",
              element: <Course />,
            },
            {
              path: "/course/:id/presubscribe",
              element: <PreSubscribe />,
            },
          ],
        },
      ],
    },
    {
      path: "/video-section",
      element: <VideoSection />,
    },
    {
      path: "/pre-subscribe",
      element: <Layout />,
      children: [
        {
          path: "/pre-subscribe",
          element: <PreSubscribe />,
        },
      ],
    },
    {
      path: "/courses",
      element: <Layout />,
      children: [
        {
          path: "/courses",
          element: <Courses />,
        },
      ],
    },
    {
      path: "/parsha",
      element: <Layout />,
      children: [
        {
          path: "/parsha",
          element: <Parsha />,
        },
      ],
    },
    {
      path: "/user/",
      element: <UserLayout />,
      children: [
        {
          path: "/user/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/user/notifications",
          element: <Notifications />,
        },
        {
          path: "/user/activity",
          element: <AccountActivity />,
        },
        {
          path: "/user/account-settings",
          element: <AccountSettings />,
        },
        {
          path: "/user/purchase-history",
          element: <PurchaseHistory />,
        },
        {
          path: "/user/courses",
          element: <UserCourses />,
        },
        {
          path: "/user/favorites",
          element: <Favorites />,
        },
        {
          path: "/user/playback",
          element: <Playback />,
        },
      ],
    },
    {
      path: "/contact",
      element: <Layout />,
      children: [
        {
          path: "/contact",
          element: <Contact />,
        },
      ],
    },
    {
      path: "/qa",
      element: <Layout />,
      children: [
        {
          path: "/qa",
          element: <Faq />,
        },
      ],
    },
  ]);

  return router;
};

export default useRouter;
