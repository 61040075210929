import { useTheme, useMediaQuery, Dialog } from "@mui/material";
import { pxToRem } from "px2rem2px";
import LoginPage from ".";
import VerifyEmail from "./VerifyEmail";
import PasswordResetRequest from "./PasswordResetRequest";
import ChangeTempPassword from "./ChangeTempPassword";
import { useEffect, useState } from "react";
import Toast from "../../components/Toast";

const LoginModal = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { ...restProps } = props;
  const [action, setAction] = useState("login");
  const [successMessage, setSuccessMessage] = useState("");
  const [verifyEmail, setVerifyEmail] = useState("");
  const [tempEmail, setTempEmail] = useState("");
  const [tempPass, setTempPass] = useState("");
  // const [openSuccess, setOpenSuccess] = useState(false);

  console.log("onRedirectOpenModal",props?.onRedirectOpenModal)

  // useEffect(()=>{
  //   alert(action)
  // },[action])
  
  return (
    <>
    <Dialog
      {...restProps}
      fullScreen={fullScreen}
      maxWidth="xs"
      scroll="body"
      disableEscapeKeyDown
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          return;
        }
      }}
      sx={{
        "& .MuiPaper-root": {
          // width: "400px",
          // width: fullWidth?"100%":fullScreen?"400px":"400px",
          width: fullScreen?"100%":"400px",
          maxWidth: pxToRem(600),
          borderRadius: fullScreen ? 0 : pxToRem(20),
          verticalAlign: "top",
        },
        "& .MuiDialog-container": {
          overflowY: "scroll",
        },
        zIndex: 1500,
      }}
    >
      {action === "login" && (
        <LoginPage
          inModal={true}
          setAction={setAction}
          {...restProps}
          setVerifyEmail={setVerifyEmail}
          setTempEmail={setTempEmail}
          setTempPass={setTempPass}
          successMessage={successMessage}
          onRedirectOpenModal={props?.onRedirectOpenModal}
        />
      )}
      {action === "verifyEmail" && (
        <VerifyEmail
          inModal={true}
          {...restProps}
          setAction={setAction}
          verifyEmail={verifyEmail}
          setSuccessMessage={setSuccessMessage}
          onRedirectOpenModal={props?.onRedirectOpenModal}
        />
      )}
      {action === "passwordResetRequest" && (
        <PasswordResetRequest
          {...restProps}
          inModal={true}
          setAction={setAction}
        />
      )}
      {/* {action === "passwordResetRequest" && (
        <ResetPassword
          inModal={true}
          {...restProps}
          setAction={setAction}
          setSuccessMessage={setSuccessMessage}
        />
      )} */}
      {action === "changeTempPassword" && (
        <ChangeTempPassword
          inModal={true}
          {...restProps}
          setAction={setAction}
          setSuccessMessage={setSuccessMessage}
          tempPass={tempPass}
          tempEmail={tempEmail}
          fullScreen={fullScreen}
        />
      )}
    </Dialog>
    

  </>
  );
};

export default LoginModal;
