import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { MediaPlayButton } from "@vidstack/react";

import { ReactComponent as Replay } from "../../images/replay.svg";
import { ReactComponent as NextLecture } from "../../images/play-video.svg";
import { ReactComponent as Check } from "../../images/check.svg";

import { ReactComponent as Close } from "../../images/close.svg";
import { pxToRem } from "px2rem2px";
import { useEffect, useState } from "react";
import { padding, width } from "@mui/system";

const EndView = ({
  isPreview,
  content,
  setPlayIcon,
  setPlayTriggered,
  lastLesson,
  isFixed,
  setIsFixed,
  showStickyBox,
  handleNextLectureClick,
}) => {
  const filteredWhatsIncluded = content?.whats_included_preview?.filter(
    (item) => item.trim() !== "",
  );

  return (
    <Stack
      className="end-overlay"
      alignItems="center"
      justifyContent="center"
      position="absolute"
      width="100%"
      height="100%"
      bgcolor="#026670"
      zIndex={28}
      borderRadius={{ xs: 0, md: 4 }}
      p="15px 40px"
      pb={showStickyBox ? { xs: "50px", ssm: "15px" } : "15px"}
      sx={{
        inset: 0,
        display: "none",
        "& media-play-button": { width: "auto", height: "auto", m: 0 },
      }}
    >
      {isFixed && (
        <IconButton className="pip-close" onClick={() => setIsFixed(false)}>
          <Close color="#fff" width={pxToRem(10)} height={pxToRem(10)} />
        </IconButton>
      )}

      {isPreview ? (
        <>
          <Stack spacing={4} alignItems="center">
            <MediaPlayButton>
              <Button
                variant="yellow"
                startIcon={<Replay />}
                sx={{ fontSize: "16px", height: "54px", maxWidth: "225px" }}
              >
                Replay Preview
              </Button>
            </MediaPlayButton>
          </Stack>
          {filteredWhatsIncluded?.length > 0 && (
            <>
              <Divider
                sx={{
                  backgroundColor: "rgba(255,255,255,0.3)",
                  my: "30px",
                  alignSelf: "stretch",
                  display: { xs: "none", ssm: "block" },
                }}
              />
              <Box textAlign="center">
                <Typography
                  component="p"
                  fontSize="16px"
                  fontWeight={600}
                  color="#fff"
                  mb="10px"
                  mt={{ xs: "10px", md: 0 }}
                >
                  What's included?
                </Typography>
                <Stack
                  flexDirection={{ xs: "column", lg: "row" }}
                  alignItems="center"
                  justifyContent="center"
                  flexWrap="wrap"
                  gap="7px 30px"
                >
                  {filteredWhatsIncluded.map((item, index) => (
                    <CourseFeature key={index}>{item}</CourseFeature>
                  ))}
                </Stack>
              </Box>
            </>
          )}
        </>
      ) : (
        <Stack spacing={4} alignItems="center">
          {lastLesson && (
            <Typography
              sx={{
                fontSize: "26px",
                color: "#fce181",
                textAlign: "center",
                fontWeight: 600,
              }}
            >
              Congrats! You've Completed This Course!
            </Typography>
          )}
          <MediaPlayButton style={{ width: "100%", padding: 0 }}>
            <Button
              variant="yellow"
              startIcon={<Replay />}
              sx={{
                fontSize: "16px",
                height: { xs: "44px", ssm: "54px" },
                maxWidth: "225px",
              }}
              onClick={() => {
                setPlayTriggered(true);
                // setPlayIcon(true);
                if (setPlayIcon) setPlayIcon(true);
              }}
            >
              Replay Lecture
            </Button>
          </MediaPlayButton>
          {!lastLesson && (
            <Button
              variant="yellow"
              startIcon={<NextLecture width={pxToRem(28)} />}
              sx={{
                fontSize: "16px",
                height: { xs: "44px", ssm: "54px" },
                maxWidth: "225px",
                width: "100%",
                mt: { xs: "20px !important", ssm: "32px !important" },
              }}
              onClick={handleNextLectureClick}
            >
              Next Lecture
            </Button>
          )}
        </Stack>
      )}
    </Stack>
  );
};

const CourseFeature = (props) => {
  return (
    <Stack flexDirection="row" alignItems="baseline" gap="10px">
      <Check color="#FCE181" style={{ flexShrink: 0 }} />
      <Typography fontSize="14px" fontWeight={500} color="#F7F6F2">
        {props.children}
      </Typography>
    </Stack>
  );
};

export default EndView;
