import {
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import ModalHeader from "../../../components/ModalHeader";
import { pxToRem } from "px2rem2px";
import { styled } from "@mui/material/styles";

const NumberContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: pxToRem(10),
  "& button": {
    minWidth: pxToRem(40),
    height: pxToRem(40),
    borderRadius: "50%",
    fontSize: pxToRem(20),
    lineHeight: 1,
    padding: 0,
  },
});



function calculateExpirationDate(endDate, duration, prolongBy) {
  const end = new Date(endDate);
  end.setDate(end.getDate() + duration * prolongBy);

  const month = String(end.getMonth() + 1).padStart(2, '0');
  const day = String(end.getDate()).padStart(2, '0');
  const year = end.getFullYear();
  // Return formatted date as MM/DD/YYYY
  return `${month}-${day}-${year}`;
}

function daysUntilExpiration(expirationDate) {
  const today = new Date();
  const expiration = new Date(expirationDate);
  const diffTime = Math.abs(expiration - today);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

export default function ProlongModal({
  open,
  setOpen,
  endDate,
  duration,
  prolongPrice,
  handleLifeLongPurchase,
}) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [touched, setTouched] = useState(false);
  const [prolongedBy, setProlongedBy] = useState(1);
  const [expirationDate, setExpirationDate] = useState("");
  const [daysUntilExpire, setDaysUntilExpire] = useState(0);

  useEffect(()=>{
    console.log("datatodebubghggnf",daysUntilExpire,expirationDate,prolongedBy)
  }, [endDate, duration, prolongedBy]);

  useEffect(() => {
    console.log("djnjdnjnhrfunisnjcndsc",endDate,duration,prolongedBy)
    if (endDate && duration && prolongedBy) {
      const newExpirationDate = calculateExpirationDate(
        endDate,
        duration,
        prolongedBy
      );
      setExpirationDate(newExpirationDate);
      setDaysUntilExpire(daysUntilExpiration(newExpirationDate));
    }
  }, [endDate, duration, prolongedBy,open]);




  const handleBlur = () => {
    setTouched(true);
  };

  const handleIncrement = () => {
    setProlongedBy((prev) => prev + 1);
  };

  const handleDecrement = () => {
    if (prolongedBy > 1) {
      setProlongedBy((prev) => prev - 1);
    }
  };

  const handleSubmit = () => {
    handleLifeLongPurchase("Normal", "Prolong", prolongedBy);
  };

  const handleClose = () => {
    setOpen(false);
    setTouched(false);
    setErrorMessage("");
    setLoading(false);
    setProlongedBy(1);
    setExpirationDate("");
    setDaysUntilExpire(0);
  };

  return (
    <Dialog
      fullWidth
      open={open}
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "600px",
          minHeight: "300px",
          borderRadius: "15px",
          margin: {xs: "20px !important" , ssm: `${pxToRem(32)} !important`}
        },
      }}
    >
      <ModalHeader title="Prolong Subscription" inModal onClose={handleClose} />

      <DialogContent sx={{ backgroundColor: "#f7f6f2", p: {xs: pxToRem(30), ssm: pxToRem(50) } }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            marginBottom: "1rem",
          }}
        >
          <Typography color="gray" sx={{ textAlign: "center" }}>
            Prolong the subscription by {duration * prolongedBy} days for a
            lower price ${prolongPrice * prolongedBy}.
            <br />
            Your subscription will expire in {daysUntilExpire} days (
            {expirationDate}).
          </Typography>
        </Box>

        <NumberContainer>
          <Button
            variant="outlined"
            onClick={handleDecrement}
            disabled={prolongedBy <= 1}
          >
            -
          </Button>
          {/* <Typography>{prolongedBy}</Typography> */}
          <Typography sx={{ width: "2ch", textAlign: "center" }}>{prolongedBy}</Typography>
          <Button variant="outlined" onClick={handleIncrement}>
            +
          </Button>
        </NumberContainer>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          pb: "30px",
          backgroundColor: "#f7f6f2",
          px: {xs: pxToRem(30), ssm: pxToRem(50) }
        }}
      >
        <Button disabled={loading} onClick={handleSubmit} variant="yellow">
          Prolong Subscription for ${prolongPrice * prolongedBy}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

