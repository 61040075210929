import { IconButton, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { pxToRem } from "px2rem2px";

import { ReactComponent as Like } from "../images/like.svg";

const IconButtonStyled = styled(IconButton)(() => {
  return {
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
      "& svg": {
        color: "#026670",
      },
    },
  };
});

const Likes = ({ number, isLiked, disabled, onClick }) => {
  return (
    <Stack flexDirection="row" alignItems="flex-end" columnGap={pxToRem(10)}>
      <Typography
        fontSize={pxToRem(14)}
        fontWeight={600}
        color={isLiked ? "#026670" : "#BFBEBB"}
      >
        {number}
      </Typography>
      <Stack flexDirection="row" alignItems="center" columnGap={pxToRem(7)}>
        <IconButtonStyled onClick={onClick} sx={{ marginBottom: pxToRem(4) }} disabled={disabled}>
          <Like color={isLiked ? "#026670" : "#BFBEBB"} />
        </IconButtonStyled>
      </Stack>
    </Stack>
  );
};

export default Likes;
