import React, { useEffect, useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import PropTypes from "prop-types";


const RichTextDisplay = ({ str, showFull = false, onContentExceedsMaxHeight = () => {} }) => {
  const contentRef = useRef(null);
  const [contentExceedsMaxHeight, setContentExceedsMaxHeight] = useState(false);

  useEffect(() => {
    if (contentRef.current) {
      const exceedsMaxHeight = contentRef.current.scrollHeight > 318; 
      setContentExceedsMaxHeight(exceedsMaxHeight);
      onContentExceedsMaxHeight(exceedsMaxHeight);
    }
  }, [str, onContentExceedsMaxHeight]);

  if (!str) {
    return null;
  }
  const content = str;

  return (
    <div
      ref={contentRef}
      className={`rich-text-display ${showFull ? "expanded" : ""}`}
      dangerouslySetInnerHTML={{ __html: content }}
      style={{fontWeight: 500}}
    />
  );
};

RichTextDisplay.propTypes = {
  str: PropTypes.string.isRequired,
  showFull: PropTypes.bool,
  onContentExceedsMaxHeight: PropTypes.func,
};

export default RichTextDisplay;
