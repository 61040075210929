// import { Stack } from "@mui/material";

// const Sidebar = (props) => {
//   return (
//     <Stack
//       gap="30px"
//       width="230px"
//       backgroundColor="#EDECE8"
//       borderRadius="0 40px 40px 0"
//       flexShrink={0}
//       padding="50px 0"
//       sx={{
//         "&::before": {
//           content: "''",
//           width: "21%",
//           position: "absolute",
//           bottom: 0,
//           left: 0,
//           right: 0,
//           height: "100%",
//           backgroundColor: "#EDECE8",
//           borderRadius: "40px 0 0 40px",
//           zIndex: -1,
//         },
//       }}
//     >
//       {props.children}
//     </Stack>
//   );
// };

// export default Sidebar;

import React from "react";
import { Stack, useMediaQuery, useTheme } from "@mui/material";

const Sidebar = (props) => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack
      gap="30px"
      width={isMediumScreen ? "100%" : "30%"}
      // width={isMediumScreen ? "100%" : "320px"}
  
      backgroundColor="#EDECE8"
      borderRadius="40px"
      flexShrink={0}
      padding={isMediumScreen ? "50px" : "50px 30px 50px 120px"}
      marginBottom={isMediumScreen?"40px":"0"}
        // position="relative"
      // sx={{
      //   "&::before": {
      //     content: "''",
      //     width: "21%",
      //         maxWidth:"50px",
      //     position: "absolute",
      //     bottom: 0,
      //     left: 0,
      //     right: 0,
      //     height: "100%",
      //     backgroundColor: "#EDECE8",
      //     borderRadius: "40px 0 0 40px",
      //     zIndex: -1,
      //     display: isMediumScreen ? "none" : "block", 
      //   },
      // }}
    >
      {props.children}
    </Stack>
  );
};

export default Sidebar;

