import { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Link,
  Stack,
  Typography,
  Skeleton,
  Rating,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { pxToRem } from "px2rem2px";
import { styled } from "@mui/material/styles";
import BlockBordered from "../../components/BlockBordered";
import LikeBtn from "../../components/courses/LikeBtn";
import { isTodayBetweenDates } from "../../utils/format";
import { StarIcon, StarIconContainer } from "./StarIcon";
import shouldNavigateToPresubscribe from "../../utils/subscription";

import CheckMarkSvg from "../../images/Group_1676.svg";

const Duration = styled(Typography)`
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #026670;
  border-radius: 0 5px 0 0;
  padding: 3px 11px;
  transition: 0.2s ease;
  opacity: 0;

  @media (max-width: 899px) {
    opacity: 1;
  }
`;

const Label = styled(Typography)(({ children }) => {
  return {
    display: "flex",
    alignItems: "center",
    height: "24px",
    fontSize: "14px",
    fontWeight: 600,
    color: "#026670",
    backgroundColor: "#f8eab9",
    border: "2px solid #f5e093",
    borderRadius: "calc(infinity * 1px)",
    padding: "0 25px",
  };
});

const ClampedText = styled(Typography)(({ showBlur }) => ({
  position: "relative",
  maxHeight: "3.6em", // 2 lines * line-height (1.8em)
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  lineClamp: 2,
  lineHeight: "1.8em",
  ":before": showBlur
    ? {
        content: '""',
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        height: "1em",
        background: "linear-gradient(to bottom, transparent, #ffffff)",
      }
    : {},
}));

const CourseSubtitle = ({ subtitle, isAlignedRight }) => {
  const textRef = useRef(null);
  const [showBlur, setShowBlur] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      setShowBlur(textRef.current.scrollHeight > textRef.current.clientHeight);
    }
  }, [subtitle]);

  return (
    <Box
      position="relative"
      overflow="hidden"
      textAlign={isAlignedRight ? "right" : "left"}
    >
      <ClampedText
        ref={textRef}
        showBlur={showBlur}
        variant="medium"
        component="p"
        color="#333333"
        sx={{
          direction: isAlignedRight ? "rtl" : "ltr",
        }}
      >
        {subtitle}
      </ClampedText>
    </Box>
  );
};

const Course = ({
  isAuthenticated,
  course,
  onFavoriteClick,
  loading,
  passRef,
  ...props
}) => {
  const [delayedLoading, setDelayedLoading] = useState(loading);

  // console.log("courseajnjanjanjnarating", course, course.rating);

  useEffect(() => {
    if (!loading) {
      const timer = setTimeout(() => {
        setDelayedLoading(false);
      }, 600);
      return () => clearTimeout(timer);
    } else {
      setDelayedLoading(true);
    }
  }, [loading]);

  const isOnSale =
    course?.is_on_sale &&
    isTodayBetweenDates(course?.sale_start_date, course?.sale_end_date);

  return (
    <Box
      // className={`fade-in ${!loading ? "show" : ""}`}
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        ref={passRef}
        position="relative"
        bgcolor="#fff"
        borderRadius={pxToRem(20)}
        sx={{
          transition: "0.2s ease",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          "&::before": {
            content: "''",
            position: "absolute",
            top: "50%",
            left: "50%",
            width: `calc(100% - ${pxToRem(40)})`,
            height: "100%",
            backgroundColor: "#FCE181",
            borderRadius: pxToRem(20),
            transform: "translate(-50%,-50%)",
            zIndex: -1,
            transition: "0.2s ease",
          },
          "& .MuiButton-root": {
            position: "static",
            fontSize: "16px",
            height: "54px",
            opacity: 0,
            transition: "0.2s ease",
            zIndex: 1,
          },
          "&:hover": {
            boxShadow: {
              xs: "none",
              sm: `0px ${pxToRem(20)} ${pxToRem(24)} rgba(0,0,0,0.1)`,
            },
            "&::before": {
              height: `calc(100% + ${pxToRem(40)})`,
            },
            "& .slideTitle": {
              color: "primary.main",
            },
            "& .MuiButton-root, .duration": {
              opacity: 1,
            },
          },
        }}
      >
        {delayedLoading ? (
          <Box borderRadius={`${pxToRem(20)} ${pxToRem(20)}`}>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={200}
              sx={{ borderRadius: "20px 20px 0px 0px" }}
            />
            <Stack spacing={1} p="26px 36px">
              <Skeleton variant="text" width="60%" />
              <Skeleton variant="text" width="40%" />
              <Skeleton variant="text" width="80%" />
              <Skeleton variant="text" width="50%" />
            </Stack>
          </Box>
        ) : (
          <Box>
            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                "& > *": { gridRow: "1 / -1", gridColumn: "1 / -1" },
              }}
            >
              <Button
                to={props.linkUrl}
                component={NavLink}
                variant="yellow"
                sx={{ padding: "0 40px", minWidth: "150px" }}
              >
                {shouldNavigateToPresubscribe(props.subscriptionStatus)
                  ? "View"
                  : props.subscriptionStatus === "Expired"
                    ? "Resubscribe"
                    : "Continue Watching"}
              </Button>

              <Box
                position="relative"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius={`${pxToRem(20)} ${pxToRem(20)} 0 0`}
                height="200px"
                width="100%"
                overflow="hidden"
              >
                {isAuthenticated ? (
                  <LikeBtn
                    courseId={course.id}
                    isFavorite={course.is_favorite}
                    onFavoriteClick={onFavoriteClick}
                  />
                ) : (
                  <></>
                )}
                <Duration className="duration">{props.duration}</Duration>

                <img
                  src={course?.thumb_nail}
                  alt={props.title}
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                />
              </Box>
              <Stack
                width="calc(100% - 60px)"
                flexDirection="row"
                alignContent="center"
                justifyContent="flex-end"
                overflow="hidden"
                flexWrap="nowrap"
                gap="8px"
                position="absolute"
                top="190px"
              >
                {props.labels.map((label, labelIndex) => (
                  <Label key={labelIndex}>{label}</Label>
                ))}
              </Stack>
            </Box>

            <Box
              p="26px 36px"
              bgcolor="#fff"
              borderRadius={`0 0 ${pxToRem(20)} ${pxToRem(20)}`}
              overflow="hidden"
              display="flex"
              flexDirection="column"
              flexGrow={1}
            >
              <Box sx={{ margin: "15px 0" }}>
                <Typography
                  className="slideTitle"
                  fontSize={pxToRem(22)}
                  fontWeight={700}
                  lineHeight={pxToRem(30)}
                  color="secondary.main"
                  mb={pxToRem(20)}
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                    textAlign: props?.isTitleRtl ? "right" : "left",
                  }}
                >
                  {props?.title}
                </Typography>
                <BlockBordered isAlignedRight={props?.isSubtitleRtl}>
                  <CourseSubtitle
                    subtitle={props?.subtitle}
                    isAlignedRight={props?.isSubtitleRtl}
                  />
                </BlockBordered>
              </Box>

              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    marginBottom: "15px",
                  }}
                >
                  <Stack>
                    {isOnSale ? (
                      <Box
                        sx={{ display: "flex", gap: 2, alignItems: "center" }}
                      >
                        <Typography
                          fontSize="25px"
                          lineHeight="25px"
                          fontWeight="700"
                          color="#026670"
                        >
                          {course.sale_price <= 0
                            ? "Free"
                            : `$${course.sale_price}`}
                        </Typography>

                        <Typography
                          fontSize="16px"
                          fontWeight="600"
                          color="#BFBEBB"
                          sx={{
                            textDecoration: "line-through",
                            transform: "translateY(2px)",
                          }}
                        >
                          {course.subscription_price > 0 &&
                            `${course.subscription_price}`}
                        </Typography>
                      </Box>
                    ) : (
                      <Typography
                        fontSize="25px"
                        lineHeight="25px"
                        fontWeight="700"
                        color="#026670"
                      >
                        {course.subscription_price <= 0
                          ? "Free"
                          : `$${course.subscription_price}`}
                      </Typography>
                    )}
                  </Stack>

                  <Typography
                    component="div"
                    fontSize="14px"
                    fontWeight={600}
                    color="#fff"
                    display="flex"
                    alignItems="center"
                    sx={{
                      backgroundColor: "#026670",
                      borderRadius: "14px",
                      padding: "0px 10px 0px 5px",
                      whiteSpace: "nowrap",
                      gap: 1,
                    }}
                  >
                    {props.subscriptionStatus === "Expired" ? (
                      <>
                        <img src={CheckMarkSvg} alt="Enrolled" />
                        <span>Expired</span>
                      </>
                    ) : (
                      !shouldNavigateToPresubscribe(
                        props.subscriptionStatus,
                      ) && (
                        <>
                          <img src={CheckMarkSvg} alt="Enrolled" />
                          <span>Enrolled</span>
                        </>
                      )
                    )}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",

                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",

                      flexGrow: 1,
                      marginTop: "2px",
                    }}
                  >
                    <Typography
                      component="p"
                      fontSize="12px"
                      fontWeight={600}
                      color="#BFBEBB"
                      whiteSpace="nowrap"
                    >
                      Posted {new Date(props.date).toLocaleDateString()}&nbsp; •
                      &nbsp;
                      {props.subscribers}{" "}
                      {props.subscribers === 1 ? "Subscriber" : "Subscribers"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      component="p"
                      fontSize="14px"
                      fontWeight={600}
                      color="#BFBEBB"
                      whiteSpace="nowrap"
                      marginRight="2px"
                    >
                      {course.rating}
                    </Typography>

                    <Rating
                      name="read-only"
                      value={course.rating}
                      readOnly
                      size="small"
                      icon={<StarIcon color="#fce181" size={13} />}
                      emptyIcon={<StarIcon color="#dfdedd" size={13} />}
                      sx={{ marginLeft: "2px", marginTop: "-2px" }}
                      IconContainerComponent={StarIconContainer}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Course;
