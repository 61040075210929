import { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Stack,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
import BlockHeading from "../../../components/BlockHeading";
import Switcher from "../../../components/Switcher";
import { editPlayback, getPlayback, getUserInfo } from "../../../api/student";
import Toast from "../../../components/Toast";

import { ReactComponent as Chevron } from "../../../images/chevron.svg";

const Playback = () => {
  const userInfo = getUserInfo();

  const [settings, setSettings] = useState({
    is_newsletter_subscribed: false,
    playing_mode: "ask",
    default_play_speedd: 0,
    autoplay: false,
    fullscreen_on_rotate: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage,setToastMessage]=useState("")
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isSelectOpen2, setIsSelectOpen2] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const data = await getPlayback(userInfo.id);
        setSettings(data);
        console.log("data", data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchSettings();
  }, [userInfo.id]);

  //   console.log("settings", settings);

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  console.log("settings", settings);

  const handleSaveSettings = async () => {
    setIsSubmitting(true);
    try {
      const response = await editPlayback(settings);
      console.log("Save response:", response);
      setToastOpen(true);
      setToastMessage("Changes saved successfully.")
    } catch (error) {
      console.log(error);
      setToastMessage("Failed to save changes!")
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <BlockHeading>
        <Typography component="h3" variant="blockTitle" mb={pxToRem(5)}>
          Media Settings
        </Typography>
        <Typography component="p" variant="medium">
          Sed ut perspiciatis unde omnis iste natus accusantium.
        </Typography>
      </BlockHeading>

      <Stack
        gap={pxToRem(20)}
        sx={{
          "& label": {
            alignItems: "flex-start",
            "& .MuiTypography-root": {
              fontSize: pxToRem(18),
              fontWeight: 600,
              color: "#333",
              // ml: "20px",
            },
          },
          "& .MuiSwitch-root": {
            mt: "4px !important",
          },
        }}
      >
        <FormControl sx={{marginLeft:'-15px'}}>
          <InputLabel
            sx={{
              lineHeight: 1.5,
              fontSize: "1.5rem",
              fontWeight: 600,
              color: "#333",

            }}
          >
            Playing Mode
          </InputLabel>
          <Select
            value={settings.playing_mode}
            onChange={handleChange}
            name="playing_mode"
            // sx={{width:"100%"}}
            sx={{ width: "220px", textAlign: "left" }}
            IconComponent={(props) => (
              <Chevron
                {...props}
                style={{
                  transition: 'transform 0.3s',
                  transform: isSelectOpen ? 'rotate(-90deg)' : 'rotate(90deg)',
               
                }}
              />
            )}
            onOpen={() => setIsSelectOpen(true)}   // Set open state
            onClose={() => setIsSelectOpen(false)}
          >
            <MenuItem value="watch">Watch</MenuItem>
            <MenuItem value="listen">Listen</MenuItem>
            <MenuItem value="ask">Ask Me Everytime</MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{marginLeft:'-15px'}}>
          <Select
            value={settings.default_play_speedd}
            onChange={handleChange}
            name="default_play_speedd"
            
            IconComponent={(props) => (
              <Chevron
                {...props}
                style={{
                  transition: 'transform 0.3s',
                  transform: isSelectOpen2 ? 'rotate(-90deg)' : 'rotate(90deg)',
                }}
              />
            )}
            onOpen={() => setIsSelectOpen2(true)}   // Set open state
            onClose={() => setIsSelectOpen2(false)}
            sx={{ width: "220px", textAlign: "left" }}
          >
            <MenuItem value={0.25}>0.25</MenuItem>
            <MenuItem value={0.5}>0.5</MenuItem>
            <MenuItem value={0.75}>0.75</MenuItem>
            <MenuItem value={1.0}>Normal</MenuItem>
            <MenuItem value={1.25}>1.25</MenuItem>
            <MenuItem value={1.5}>1.5</MenuItem>
            <MenuItem value={1.75}>1.75</MenuItem>
            <MenuItem value={2.0}>2</MenuItem>
          </Select>
          <InputLabel
            sx={{
              lineHeight: 1.5,
              fontSize: "1.5rem",
              fontWeight: 600,
              color: "#333",
            }}
          >
            Default Play Speed
          </InputLabel>
        </FormControl>

        <FormControl >
          <Switcher
            checked={settings.autoplay}
            onChange={handleChange}
            label="Autoplay (for free videos)"
            name="autoplay"
          />
        </FormControl>

        <FormControl >
          <Switcher
            checked={settings.fullscreen_on_rotate}
            onChange={handleChange}
            label="Rotate On Fullscreen"
            name="fullscreen_on_rotate"
          />
        </FormControl>
      </Stack>

      <Divider
        sx={{ my: pxToRem(30), borderColor: "rgba(191,190,187, 0.5)" }}
      />

      <Button
        variant={isSubmitting ? "outlined" : "yellow"}
        size="small"
        onClick={handleSaveSettings}
        disabled={isSubmitting}
      >
        Save Settings
      </Button>

      
      <Toast
        open={toastOpen}
        onClose={() => setToastOpen(false)}
        message={toastMessage}
      />
    </>
  );
};

export default Playback;
