import { useState } from "react";
import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import ModalHeader from "../../components/ModalHeader";

const LoginSubscribeModal = (props) => {
  const { openLoginModal, hideModal, loginMessage ,onRedirectOpenModal } = props;
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    hideModal();
  };

  console.log("onRedirectOpenModal",onRedirectOpenModal)

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "15px",
          },
          zIndex: 1500,
        }}
      >
        <ModalHeader
          title="Authentication Required"
          inModal={true}
          onClose={handleClose}
          isFeedBack
        />

        <DialogContent
          sx={{
            width: "500px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px 17px 55px 17px",
            backgroundColor: "#f7f6f2",
          }}
        >
          <Typography
            variant="medium"
            component="p"
            fontWeight={600}
            fontSize="16px"
            mb="40px"
            textAlign="center"
          >
            {loginMessage}
          </Typography>
          <Button
            variant="yellow"
            onClick={() => {
              handleClose();
              openLoginModal();
            }}
            sx={{ width: "300px" }}
          >
            Sign In or Create Account
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LoginSubscribeModal;
