import React, { useEffect, useState } from "react";
import { getNotifications, hideNotification } from "../../../api/student";
import { Box, Grid, Typography, IconButton, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function Notification() {
  const [notifications, setNotifications] = useState([]);
  const [nextPage, setNextPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    fetchNotifications(nextPage);
  }, []);

  const fetchNotifications = async (page) => {
    setLoading(true);
    try {
      const response = await getNotifications({ page });
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        ...response.data.results.filter(
          (newNotification) =>
            !prevNotifications.some(
              (prevNotification) => prevNotification.id === newNotification.id
            )
        ),
      ]);
      setHasMore(response.data.next !== null);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleHide = async (notificationId) => {
    try {
      await hideNotification(notificationId);
      setNotifications((prevNotifications) =>
        prevNotifications.filter(
          (notification) => notification.id !== notificationId
        )
      );
    } catch (error) {
      console.error("Error hiding notification:", error);
    }
  };

  const handleViewMore = () => {
    if (hasMore) {
      const nextPageNumber = nextPage + 1;
      setNextPage(nextPageNumber);
      fetchNotifications(nextPageNumber);
    }
  };

  return (
    <>
      {notifications.map((notification) => (
        <Box
          key={notification.id}
          sx={{
            padding: "20px",
            borderRadius: "10px",
            backgroundColor: "#f7f6f2",
            m: "20px 0",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Typography sx={{ fontWeight: 500, fontSize: "16px", color: "#333" }}>
                {notification.title}
              </Typography>
              <Typography sx={{ fontWeight: 300, fontSize: "14px", color: "#666" }}>
                {notification.message}
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ textAlign: "right" }}>
              <IconButton
                variant="contained"
                color="error"
                onClick={() => handleHide(notification.id)}
                sx={{
                  backgroundColor: "#e03e3e",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#b22222",
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      ))}
      {hasMore && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleViewMore}
          disabled={loading}
        >
          {loading ? "Loading..." : "View More"}
        </Button>
      )}
    </>
  );
}
