import React, { useEffect, useState } from "react";
import {
  getInvitations,
  acceptInvitation,
  rejectInvitation,
} from "../api/student";
import {
  Box,
  Grid,
  Avatar,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import Philosophyimage from "../images/philosophy-img.png";
import { pxToRem } from "px2rem2px";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { toast } from "react-toastify";
import { createAssociateCheckoutSession } from "../api/payment";
import Toast from "./Toast";
import { dateToDays } from "../utils/format";
import SubscriptionFeedbackModal from "../pages/course/components/SubscriptionFeedbackModal";

export default function InvitationCard() {
  const [invitations, setInvitations] = useState([]);
  const [feedbackAssociateOpen, setFeedbackAssociateOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const [toastOpen, setToastOpen] = useState("");

  

  useEffect(() => {
    fetchInvitations();
  }, [feedbackAssociateOpen,toastOpen]);

  const fetchInvitations = async () => {
    try {
      const response = await getInvitations();
      setInvitations(response.data.results);
    } catch (error) {
      console.error("Error fetching invitations:", error);
    }
  };

  useEffect(() => {
    console.log("invitations", invitations);
  }, [invitations]);

  const handleAccept = async (invitationId) => {
    try {
      const response = await acceptInvitation(invitationId);
      const email = response.email;
      const id = response.course_id;

      const checkoutSessionResponse = await createAssociateCheckoutSession(
        id,
        email
      );
      console.log("checkoutSessionResponse", checkoutSessionResponse);
      if (
        checkoutSessionResponse &&
        checkoutSessionResponse.data &&
        checkoutSessionResponse.data.checkout_url
      ) {
        // Redirect the user to the checkout URL
        window.location.href = checkoutSessionResponse.data.checkout_url;
      } else {
        console.error("Checkout URL not found in the response.");
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        setModalTitle("Invitation Error");
        setErrorMessage(error?.response?.data?.detail);
        setFeedbackAssociateOpen(true);
      } else {
        console.error(
          "Error handling invitation acceptance and checkout session:",
          error
        );
      }
    }
  };

  const handleReject = async (invitationId) => {
    try {
      await rejectInvitation(invitationId);
      setToastOpen(true)
      setErrorMessage("Inviation was rejected successfully ")
    } catch (error) {
      console.error("Error rejecting invitation:", error);
    }
  };

  // const svgToDataURL = (svgData) => {
  //   const svg = svgData.join("");
  //   const blob = new Blob([svg], { type: "image/svg+xml" });
  //   const url = URL.createObjectURL(blob);
  //   return url;
  // };

  const handleCloseAssociateFeedback = () => {
    setFeedbackAssociateOpen(false);
    setErrorMessage("");
    setModalTitle("");
    //forToast
    setToastOpen(false)

  };


  return (
    <>
      <SubscriptionFeedbackModal
        open={feedbackAssociateOpen}
        handleClose={handleCloseAssociateFeedback}
        modalTitle={modalTitle}
        success={false}
        cancel={false}
        errorMessage={errorMessage}
      />
       <Toast
        open={toastOpen}
        onClose={handleCloseAssociateFeedback}
        message={errorMessage}
      />
      {invitations.map((invitation) => (
        <Box
          key={invitation?.id}
          sx={{
            padding: "30px",
            borderRadius: "10px",
            backgroundColor: "#f7f6f2",
            m: "20px 0px",
          }}
        >
          <Grid container>
            <Grid item xs={2}>
              {/* <Avatar sx={{ width: pxToRem(80), height: pxToRem(80) }}>
                <img src={svgToDataURL(invitation?.sender_avatar)} alt="" />
              </Avatar> */}
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ fontWeight: 500, fontSize: "16px" }}>
                You've Received an Invitation
              </Typography>
              <Typography sx={{ fontWeight: 300, fontSize: "14px" }}>
                Will expire in {dateToDays(invitation?.expires_at)}{" "}
                {dateToDays(invitation?.expires_at) === 1 ? "Day" : "Days"}
              </Typography>
              <Typography sx={{ fontWeight: 300, fontSize: "14px" }}>
                {invitation?.inviter} wants to be your partner
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <IconButton
                variant="contained"
                color="green"
                onClick={() => handleAccept(invitation?.id)}
                sx={{
                  backgroundColor: "green",
                  color: "white",
                  height: "40px",
                  width: "40px",
                  "&:hover": {
                    backgroundColor: "#006400",
                  },
                }}
              >
                <CheckIcon />
              </IconButton>
              <IconButton
                variant="contained"
                color="error"
                onClick={() => handleReject(invitation?.id)}
                sx={{
                  backgroundColor: "#e03e3e",
                  color: "white",
                  height: "40px",
                  width: "40px",
                  "&:hover": {
                    backgroundColor: "#b22222",
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      ))}
    </>
  );
}
