import { useState } from "react";
import {
  Box,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import CourseImg1 from "../../images/course-img1.png";
import Parsha from "./Parsha";
import { SwiperSlide } from "swiper/react";

const marriage = [
  {
    id: 0,
    image: CourseImg1,
    title: "Qui dolorem ipsum quia",
    text: "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit.",
    linkUrl: "/",
    date: "11/31/23",
    subscribers: 547,
    duration: "57:00",
    labels: ["New", "Featured"],
    likes: 204,
  },
  {
    id: 1,
    image: CourseImg1,
    title: "Qui dolorem ipsum quia",
    text: "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit.",
    linkUrl: "/",
    date: "11/31/23",
    subscribers: 547,
    duration: "40:00",
    labels: ["Popular"],
    likes: 245,
  },
  {
    id: 2,
    image: CourseImg1,
    title: "Qui dolorem ipsum quia",
    text: "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit.",
    linkUrl: "/",
    date: "11/31/23",
    subscribers: 547,
    duration: "35:00",
    labels: ["New", "Featured"],
    likes: 359,
  },
];

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  gap: "2px",
  marginBottom: "30px",
  "&::before": {
    content: "''",
    position: "absolute",
    width: "100%",
    height: "2px",
    borderRadius: "calc(infinity * 1px)",
    left: 0,
    bottom: -2,
    backgroundColor: "#026670",
  },
  "& button": {
    maxWidth: "220px",
    height: "64px",
    width: "100%",
    fontSize: "14px",
    fontWeight: 600,
    color: "#333",
    backgroundColor: "#E6E5E1",
    border: "none !important",
    borderRadius: "10px 10px 0 0 !important",
    textTransform: "capitalize",
    margin: "0 !important",
    "&:hover": {
      color: "#FCE181",
      backgroundColor: "#026670",
    },
  },
  "& .Mui-selected": {
    color: "#FCE181 !important",
    fontSize: "16px",
    backgroundColor: "#026670 !important",
    "&:hover": {
      color: "#FCE181",
      backgroundColor: "#026670",
    },
  },
});

const Books = styled(ToggleButtonGroup)({
  width: "100%",
  height: "54px",
  backgroundColor: "#BFBEBB",
  borderRadius: "calc(infinity * 1px)",
  padding: "2px 20px",
  "& button": {
    width: "25%",
    fontSize: "16px",
    fontWeight: 600,
    color: "#EDECE8",
    backgroundColor: "transparent",
    borderRadius: "calc(infinity * 1px) !important",
    textTransform: "capitalize",
    border: "none !important",
    "&:hover": {
      color: "#EDECE8",
      backgroundColor: "transparent",
    },
  },
  "& .Mui-selected": {
    color: "#026670",
    backgroundColor: "#EDECE8",
    "&:hover": {
      color: "#026670",
      backgroundColor: "#EDECE8",
    },
  },
});

const ParshaSection = () => {
  const [tabs, setTabs] = useState("parsha");
  const [books] = useState("2");

  const handleTabs = (event, newTab) => {
    if (newTab !== null) {
      setTabs(newTab);
    }
  };

  return (
    <Box mt="60px">
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        gap="15px"
        mb="25px"
      >
        <Typography
          variant="sectionTitle"
          component="h2"
          fontSize="35px"
          mb={0}
        >
          <span className="highlighted">Parsha</span>
        </Typography>
        <Typography fontSize="12px" fontWeight={600} color="#BFBEBB">
          {marriage.length} Parshas
        </Typography>
      </Stack>

      <StyledToggleButtonGroup value={tabs} exclusive onChange={handleTabs}>
        <ToggleButton value="parsha">Parsha</ToggleButton>
        <ToggleButton value="holidays">Holidays</ToggleButton>
        <ToggleButton value="seasonal">Seasonal</ToggleButton>
      </StyledToggleButtonGroup>

      <Books value={books} exclusive>
        <ToggleButton value="1">Submenu 1</ToggleButton>
        <ToggleButton value="2">Submenu 2</ToggleButton>
        <ToggleButton value="3">Submenu 3</ToggleButton>
        <ToggleButton value="4">Submenu 4</ToggleButton>
        <ToggleButton value="5">Submenu 5</ToggleButton>
      </Books>

      <Stack gap="40px" mt="30px">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "30px",
            justifyContent: "space-between",
            // backgroundColor: "green",
          }}
        >
          {marriage.map((v) => (
            <Box
              sx={{
                width: "calc(50% - 15px)", // Adjust width to take half the space minus the gap
                boxSizing: "border-box", // Ensure padding and border are included in the width
                // margin:"20px"
              }}
            >
              <Parsha
                key={v.id}
                image={v.image}
                title={v.title}
                text={v.text}
                linkUrl={v.linkUrl}
                date={v.date}
                subscribers={v.subscribers}
                duration={v.duration}
                labels={v.labels}
                likes={v.likes}
              />
            </Box>
          ))}
        </Box>
      </Stack>
    </Box>
  );
};

export default ParshaSection;
