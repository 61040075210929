import React, { useState } from "react";
import { Divider, useMediaQuery } from "@mui/material";
import { Button, Stack, Typography, Menu, MenuItem } from "@mui/material";
import { ReactComponent as Chevron } from "../../images/chevron.svg";
import { pxToRem } from "px2rem2px";
import { useTheme } from "@emotion/react";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";

const NavGroup = (props) => {
  const navigate = useNavigate();
  const [showAllLinks, setShowAllLinks] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [arrowRotation, setArrowRotation] = useState(false);

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleShowAllLinks = () => {
    setShowAllLinks(!showAllLinks);
  };



  const handleButtonClick = () => {
    if (props?.isSubscribedCourses) {
      navigate("/user/courses");
    } else {
      toggleShowAllLinks();
    }
  };

  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setArrowRotation(true);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
    setArrowRotation(false);
  };

  const childrenArray = Array.isArray(props.children)
    ? props.children
    : [props.children];
  const linksToShow = showAllLinks ? childrenArray : childrenArray.slice(0, 4);




  console.log("isArray",Array.isArray(props.children))

  return (
    <Stack
  
      gap="20px"
      // borderBottom={isMediumScreen ? "none" : "1px solid rgba(191,190,187,0.5)"}
      borderRight={
        isSmallScreen
          ? "none"
          : isMediumScreen
          ? "1px solid rgba(191,190,187,0.5)"
          : "none"
      }
      pb="20px"
      pr={isSmallScreen?"0px":isMediumScreen ? "30px" : "0px"}
    >
      {isMediumScreen ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        
        >
          <Box sx={{ display: isSmallScreen ? "none" : "block" }}>
            <Typography
              fontSize="22px"
              fontWeight="bold"
              color="#026670"
              whiteSpace="nowrap"
            >
              {props.title}
            </Typography>
          </Box>

          <Box
          
          sx={{
            ...(isSmallScreen && {
              width: "100%",
              display: "flex",
              justifyContent: "center",
            })
          }}
          >
            <Button
              onClick={handleDropdownOpen}
              variant="contained"
              sx={{
                // width:isSmallScreen?"100%":"50%",
                backgroundColor: "#F7F6F2",
                border: "1px solid gray",
                color: "#333",
                display: "flex",
                alignItems: "center",
                padding:isSmallScreen?"20px 70px":"20px 50px",
                "&:hover": {
                  backgroundColor: "#F7F6F2",
                  borderColor: "gray",
                  color: "#333",
                  border: "1px solid gray",
                },
              }}
              color="primary"
            >
              Select Option
              <Chevron
                style={{
                  marginLeft: "8px",
                  transform: arrowRotation ? "rotate(270deg)" : "rotate(90deg)",
                  transition: "transform 0.3s",
                }}
              
              />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleDropdownClose}
              MenuListProps={{
                sx: {
                
                  backgroundColor: "#F7F6F2", 
                  minWidth: anchorEl ? anchorEl.clientWidth : "auto", 
                },
              }}
             
           
            >
              {childrenArray.map((child, index) => (
                <MenuItem key={index} onClick={handleDropdownClose}>
                  {child}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Stack>
      ) : (
        <>
          <Typography fontSize="22px" fontWeight="bold" color="#026670">
            {props.title}
          </Typography>
          <Stack gap="8px" pl="20px" sx={{ "& a": { fontSize: "15px" } }}>
            {linksToShow}
          </Stack>
          {props.itemsLength > 4 && (
            <Button
            onClick={handleButtonClick}
            endIcon={<Chevron />}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              color: "#BFBEBB",
              fontSize: "15px",
              pl: "20px",
              "& .MuiButton-endIcon": { ml: pxToRem(12) },
              "& svg": {
                transform: showAllLinks ? "rotate(270deg)" : "rotate(90deg)",
                mt: pxToRem(0), // Adjust this value to align the icon correctly
              },
            }}
          >
            {showAllLinks ? "View Less" : "View More"}
          </Button>
          )}
        </>
      )}

      {!isMediumScreen && (<Divider sx={{width:"90%"}}/>) }
    </Stack>
  );
};

export default NavGroup;
