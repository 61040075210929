import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Hero from "./Hero";
import TagsCloud from "../../components/TagsCloud";
import Presentation from "./Presentation";
import Philosophy from "./Philosophy";
import BlogNews from "./BlogNews";
import FAQ from "./FAQ";
import Parsa from "./Parsa";
import Guides from "../../components/Guides";
import Courses from "./Courses";
import Promotion from "./Promotion";
import VideoSlider from "./VideoSlider";
import {getStudent} from "../../api/student";
import { useAuth } from "../../utils/AuthContext";

const Home = () => {

  const { checkAuth, logout } = useAuth();
  const isAuthenticated = checkAuth();


  console.log("isAuthenticated", isAuthenticated);

  const [student, setStudent] = useState(null);


  useEffect(() => {
    const fetchStudent = async () => {
      try {
        const response = await getStudent();
        setStudent(response.data);
      } catch (error) {
        console.error("Failed to fetch student data:", error);
      }
    };

    fetchStudent();
  }, []);

  console.log("VideoSliderstudent",student)


  return (
    <Box position="relative" overflow="hidden">
      <Guides color="rgba(0,0,0,0.06)" zIndex={-1} />
      <Hero />
      <TagsCloud />
      {isAuthenticated ? ( <VideoSlider me={student?.nickname || student?.user?.first_name} />) : (<Box height={120} /> )}
      <Presentation />
      <Philosophy />
      <Courses/>
      <BlogNews />
      <FAQ />
      <Parsa />
      <Promotion />
    </Box>
  );
};

export default Home;
