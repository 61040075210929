import api from "./api";
import { checkAuth } from "../utils/checkAuth";
import axios from "axios";
import { url } from "./config";

export async function getPaymentHistory() {
    if (checkAuth()) {
      const res = await api.get(`/api/subscription/payment-history/`);
      return res;
    } 
    return false
  }


 