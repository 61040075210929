
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import IconBtnCircular from "../../components/IconBtnCircular";
import { ReactComponent as Close } from "../../images/close.svg";
import { Stack, Box, Typography } from '@mui/material';
import { pxToRem } from 'px2rem2px';
import FormInputText from '../../components/FormInputText';
import { useForm, Controller } from "react-hook-form";
import { ReactComponent as Check } from "../../images/check.svg";

const courseFeature = ["16 lecture", "1 project fil", "download resource file", "Exercise file"]

const CourseFeature = ({feature}) => {
  return (
    <Stack flexDirection="row" alignItems="baseline" gap={pxToRem(22)} sx={{mb: pxToRem(10)}}>
      <Check color="#FFFFFF" style={{flexShrink: 0}} />
      <Typography fontSize="medium" color="#FFFFFF" sx={{textWrap: "nowrap", fontWeight: "500"}}>
        {feature}
      </Typography>
    </Stack>
  );
};

export default function NewsletterSignUpDialog({open, handleClose}) {
  const {
    control,
    // handleSubmit,
    setValue,
    // setError,
    // clearErrors,
    // formState: { errors },
  } = useForm({
    values: {
      name: "",
    },
  });

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="body"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{"& .MuiDialog-paper": {borderRadius: pxToRem(20), maxWidth: {xs: "calc(100% - 40px) !important", ssm: "calc(100% - 64px) !important", md: ` ${pxToRem(1060)} !important`}, margin: {xs: "20px !important" , ssm: `${pxToRem(32)} !important`}}}}
      >
        <Stack sx={{position: "relative"}}>
          <Box sx={{position: "absolute", right: {xs: pxToRem(30), ssm: pxToRem(40), md: pxToRem(60)}, top: {xs: pxToRem(30), md: pxToRem(40)}}}>
            <IconBtnCircular sx={{backgroundColor: {xs: "rgba(255,255,255,0.5)", sm: "rgba(255,255,255,0.3)"},"& svg":{color: {xs:"#026670", sm: "#FFFFFF"}}, "&:hover svg": {color: "#026670"}, width: {xs: pxToRem(35), ssm: pxToRem(40), sm: pxToRem(48)}, height: {xs: pxToRem(35), ssm: pxToRem(40), sm: pxToRem(48)},}} onClick={() => handleClose()}>
              <Close />
            </IconBtnCircular>
          </Box>
          <Stack flexDirection={{sm: "row"}}>
            <DialogContent sx={{padding: {xs: `${pxToRem(30)} ${pxToRem(30)} ${pxToRem(40)} ${pxToRem(30)}`, ssm: pxToRem(40), md: pxToRem(60)}, backgroundColor: "#F7F6F2"}}>
              <Typography variant="blockTitle" component="h4" sx={{borderBottom: "1px solid #BFBEBB80", paddingBottom: pxToRem(30), fontSize: pxToRem(20), pr: {xs: pxToRem(50), md: pxToRem(70)}}}>Sign up for the R.Y.M. Newsletter</Typography>
              <Typography variant="medium" component="p" sx={{paddingTop: pxToRem(30), paddingBottom: pxToRem(23)}}>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto.
              </Typography>
              <form>
                <FormInputText
                  name="name"
                  control={control}
                  setValue={setValue}
                  placeholder="Enter your email address"
                  sx= {{flexGrow: "1", width: "100%", "& input": {"&:focus": {outline: "2px solid #333"}, "&::placeholder": {opacity: "1", color: "#BFBEBB", fontWeight: "500"}}}}
                  rules={{
                    required: "Field can't be empty",
                    minLength: {
                      value: 3,
                      message: "Minimum 3 characters",
                    },
                    maxLength: {
                      value: 50,
                      message: "Maximum 50 characters",
                    },
                  }}
                  muiProps={{
                    type: "text",
                  }}
                />
              </form>
              <Typography variant="small" component="p" sx={{paddingTop: pxToRem(24), color: "#BFBEBB", fontSize: pxToRem(12), lineHeight: pxToRem(16)}}>
                * Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.
              </Typography>
            </DialogContent>
            <Box sx={{backgroundColor: "#026670", color: "#ffffff", padding: {xs: `${pxToRem(40)} ${pxToRem(30)} ${pxToRem(30)} ${pxToRem(30)}`, ssm: pxToRem(40), sm: `${pxToRem(128)} ${pxToRem(50)} ${pxToRem(44)} ${pxToRem(50)}`, md: `${pxToRem(128)} ${pxToRem(60)} ${pxToRem(44)} ${pxToRem(60)}`}}}>
              {courseFeature.map((feature) => (<CourseFeature feature={feature} />))}
              <Box sx={{textAlign: "center", marginTop: {xs: pxToRem(30), ssm: pxToRem(40)}}}>
                <Button sx={{width: "100%"}} variant="yellow">
                  Sign Up!
                </Button>
              </Box>
              <Box sx={{textAlign: "center", marginTop: pxToRem(10)}}>
                <Button sx={{width: "100%", color: "#BFBEBB", fontSize: pxToRem(12), fontWeight: "600", "&:hover": {color: "#ffffff"}}} variant="text" size="small">
                  No thanks
                </Button>
              </Box>
            </Box>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
}
