import React from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
import ModalHeader from "../../../components/ModalHeader";
import { ReactComponent as Check } from "../../../images/check.svg";
import { ReactComponent as Close } from "../../../images/cross-white.svg";
import { Stack } from "@mui/system";

const SubscriptionFeedbackModal = ({
  open,
  handleClose,
  success,
  cancel,
  modalTitle,
  errorMessage,
}) => {
  const handleStartWatching = () => {
    handleClose();
  };

  const handleTryAgain = () => {
    console.log("clicked");
  };
  // alert(errorMessage)
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "15px",
          },
        }}
      >
        <ModalHeader
          title={modalTitle}
          inModal={true}
          onClose={() => handleClose()}
          // isFeedBack
        />

        <DialogContent
          sx={{
            width: "400px",
            display: "flex",
            flexDirection: "column",
            padding: "20px 17px",
            backgroundColor: "#f7f6f2",
          }}
        >
          <Box textAlign="center">
            <Stack
              position="absolute"
              top="58px"
              left="50%"
              alignItems="center"
              justifyContent="center"
              bgcolor={success ? "#026670" : "#C92727"}
              width={54}
              height={54}
              borderRadius="100%"
              sx={{ transform: "translate(-50%,50%)" }}
            >
              {success ? (
                <Check width={16} height={16} color="#FCE181" />
              ) : (
                <Close width={16} height={16} color="#FCE181" />
              )}
            </Stack>
            <Box sx={{ marginTop: "30px" }}>
              <Typography
                component="p"
                fontSize="20px"
                fontWeight={700}
                mb="20px"
              >
                {success ? (
                  modalTitle !== "Invitation Feedback" ? (
                    <>
                      Thank you.
                      <br />
                      Payment Successful!
                    </>
                  ) : (
                    <>
                      {/* Thank you.
                      <br /> */}
                      Invitaion was rejected successful!
                    </>
                  )
                ) : (
                  <>
                    Ooops!
                    <br />
                    That didn’t work!
                  </>
                )}
              </Typography>
              {success && modalTitle === "Gift Course"&&(
                <Typography
                variant="medium"
                component="p"
                fontSize="16px"
                mb="40px"
              >
               An email invitation has been sent to the recipient, and a receipt has been sent to your email.
              </Typography>
              
              )}
              {success && modalTitle === "Add Partner"&&(
                <Typography
                variant="medium"
                component="p"
                fontSize="16px"
                mb="40px"
              >
              An email confirmation was sent.
              </Typography>
              
              )}

              {errorMessage && (
                <Typography
                  variant="medium"
                  component="p"
                  fontSize="16px"
                  mb="40px"
                  lineHeight="1.5" 
                >
                  {errorMessage}
                </Typography>
              )}

              {success &&
                modalTitle !== "Add Partner" &&
                modalTitle !== "Gift Course" &&
                modalTitle !== "Invitation Feedback" && (
                  <Button
                    variant="yellow"
                    sx={{ width: "300px", marginBottom: "30px" }}
                    onClick={handleStartWatching}
                  >
                    Start Watching
                  </Button>
                )}
              {cancel && (
                <Button
                  // onClick={openPurchaseModal}
                  variant="yellow"
                  sx={{ width: "300px", marginBottom: "30px" }}
                  onClick={handleTryAgain}
                >
                  Try Again
                </Button>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SubscriptionFeedbackModal;
