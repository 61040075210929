// import React, { useEffect, useRef, useState } from "react";
// import {
//   MediaPlayer,
//   MediaPlayButton,
//   MediaFullscreenButton,
//   MediaSeekButton,
//   MediaTimeSlider,
//   MediaTime,
//   MediaPoster,
//   MediaOutlet,
//   MediaVolumeSlider,
//   MediaSliderValue,
//   MediaTooltip, // Import Volume Slider
// } from "@vidstack/react";

// import "vidstack/styles/defaults.css";
// import "vidstack/styles/community-skin/video.css";

// import video1 from "../../videos/self-esteem-1.mp4";
// import { Box, styled, useMediaQuery } from "@mui/system";
// import FullscreenIcon from "@mui/icons-material/Fullscreen";
// import Forward10Icon from "@mui/icons-material/Forward10";
// import Replay10Icon from "@mui/icons-material/Replay10";
// import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
// import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
// import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
// import { IconButton, Typography } from "@mui/material";
// import { pxToRem } from "px2rem2px";
// import BlockBorderedRight from "./BlockBorderedRight";

// import { ReactComponent as Volume } from "../../images/volume.svg";
// import { useTheme } from "@emotion/react";
// import { useParams } from "react-router-dom";

// const IconButtonStyled = styled(IconButton)(() => {
//   return {
//     padding: 0,
//     "&:hover": {
//       backgroundColor: "transparent",
//       "& svg": {
//         color: "#FCE181",
//       },
//     },
//   };
// });

// export default function VideoSectionComponent({ videoSrc, title }) {
//   // const { id } = useParams();

//   const [currentTime, setCurrentTime] = useState(0);
//   const [duration, setDuration] = useState(0);
//   const [isPaused, setIsPaused] = useState(true); // State to track play/pause
//   const [showCenterIcon, setShowCenterIcon] = useState(false); // State to manage play/pause icon visibility
//   const [isFullscreen, setIsFullscreen] = useState(false); // State to track fullscreen mode
//   const videoRef = useRef(null);

//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//   const handleTimeUpdate = (event) => {
//     setCurrentTime(event.detail.currentTime);
//   };

//   const handleCanPlay = (event) => {
//     setDuration(event.detail.duration);
//   };

//   const handlePlay = () => {
//     setIsPaused(false);
//   };

//   const handlePause = () => {
//     setIsPaused(true);
//   };

//   const [isHovered, setIsHovered] = useState(false);
//   const [isMouseMoving, setIsMouseMoving] = useState(false);
//   let mouseMoveTimeout = null;

//   // entering the player
//   const [isMouseLeaving, setIsMouseLeaving] = useState(false);
//   let leaveTimeout = null;

//   const handleMouseEnter = () => {
//     if (leaveTimeout) {
//       clearTimeout(leaveTimeout);
//     }
//     setIsHovered(true);
//     setIsMouseLeaving(false);
//   };

//   const handleMouseLeave = () => {
//     setIsMouseLeaving(true);
//     leaveTimeout = setTimeout(() => {
//       setIsHovered(false);
//     }, 2000);
//   };

//   const handleFullscreenChange = () => {
//     if (document.fullscreenElement) {
//       setIsFullscreen(true);
//     } else {
//       setIsFullscreen(false);
//     }
//   };

//   const handleMouseMove = () => {
//     setIsMouseMoving(true);
//     if (mouseMoveTimeout) {
//       clearTimeout(mouseMoveTimeout);
//     }
//     mouseMoveTimeout = setTimeout(() => {
//       setIsMouseMoving(false);
//     }, 3000); // After 3 seconds of no movement, hide the green box
//   };

//   // handle play pause full screen
//   const [clickTimeout, setClickTimeout] = useState(null);

//   const handleVideoClick = () => {
//     // Clear any existing timeout for double-click detection
//     if (clickTimeout) {
//       clearTimeout(clickTimeout);
//       setClickTimeout(null);
//     }

//     // Set a timeout to handle single-click as play/pause
//     const timeout = setTimeout(() => {
//       togglePlayPause(); // Only called if it's a single-click
//     }, 250); // Wait to determine if it's a single or double click

//     setClickTimeout(timeout);
//   };

//   const handleVideoDoubleClick = () => {
//     // Clear the single-click timeout to prevent play/pause on double-click
//     if (clickTimeout) {
//       clearTimeout(clickTimeout);
//       setClickTimeout(null);
//     }

//     toggleFullscreen(); // Double-click triggers fullscreen
//   };

//   const togglePlayPause = () => {
//     const player = videoRef.current;
//     if (player.paused) {
//       player.play();
//       setIsPaused(false);
//     } else {
//       player.pause();
//       setIsPaused(true);
//     }

//     setShowCenterIcon(true);
//     setTimeout(() => {
//       setShowCenterIcon(false); // Hide icon after 1 second
//     }, 1000);
//   };

//   const toggleFullscreen = () => {
//     const player = videoRef.current;
//     if (!document.fullscreenElement) {
//       player.requestFullscreen();
//     } else {
//       document.exitFullscreen();
//     }
//   };

//   useEffect(() => {
//     document?.addEventListener("fullscreenchange", handleFullscreenChange);

//     const video = videoRef?.current;
//     video?.addEventListener("mousemove", handleMouseMove);

//     return () => {
//       document?.removeEventListener("fullscreenchange", handleFullscreenChange);
//       video?.removeEventListener("mousemove", handleMouseMove);
//       clearTimeout(mouseMoveTimeout);
//     };
//   }, []);

//   return (
//     <>
//       <Box
//         sx={{
//           width: "100%",
//           maxWidth: "1280px",
//           margin: "0 auto",
//           display: "flex",
//           justifyContent: "center",
//         }}
//         onMouseEnter={handleMouseEnter}
//         onMouseLeave={handleMouseLeave}
//       >
//         <Box
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             gap: 2,
//             width: isMobile ? "100%" : "80%",
//           }}
//         >
//           <BlockBorderedRight>
//             <Typography
//               fontFamily="PloniDBold"
//               sx={{
//                 fontSize: {
//                   xs: pxToRem(16),
//                   sm: pxToRem(18),
//                   md: pxToRem(20),
//                 },
//                 fontWeight: "600",
//                 color: "#666564",
//                 paddingLeft: pxToRem(20),
//                 textAlign: "right",
//               }}
//               className="rtl-text-temp-player"
//               dir="rtl"
//             >
//               {title}
//             </Typography>
//           </BlockBorderedRight>
//         </Box>
//       </Box>

//       <Box
//         sx={{
//           position: "relative",
//           width: "100%",
//           maxWidth: "1280px",
//           margin: "0 auto",
//           aspectRatio: "16 / 9",
//           display: "flex",
//           justifyContent: "center",
//         }}
//         onMouseEnter={handleMouseEnter}
//         onMouseLeave={handleMouseLeave}
//       >
//         {videoSrc ? (
//           <MediaPlayer
//             src={videoSrc}
//             ref={videoRef}
//             onTimeUpdate={handleTimeUpdate}
//             onCanPlay={handleCanPlay}
//             onPlay={handlePlay}
//             onPause={handlePause}
//             aspectRatio={16 / 9}
//             crossorigin=""
//             style={{
//               width: isMobile ? "100%" : "80%",
//               height: isMobile ? "100%" : "80%",
//               borderRadius: isMobile ? "0px" : "20px",
//               overflow: isMobile ? "" : "hidden",
//               margin: "0 auto",
//             }}
//             onClick={handleVideoClick}
//             onDoubleClick={handleVideoDoubleClick}
//           >
//             <MediaPoster alt="Video Poster" />
//             <MediaOutlet />

//             {/* {showCenterIcon && (
//           <Box
//             sx={{
//               position: "absolute",
//               top: "50%",
//               left: "50%",
//               transform: "translate(-50%, -50%)",
//               color: "#fff",
//               opacity: 0.8,
//               zIndex: 10,
//             }}
//           >
//             {isPaused ? (
//               <PlayCircleOutlineIcon sx={{ fontSize: "15vw", color: "#026670" }} />
//             ) : (
//               <PauseCircleOutlineIcon sx={{ fontSize: "15vw", color: "#026670" }} />
//             )}
//           </Box>
//         )} */}

//             {((isHovered && !isFullscreen) || isMouseMoving) && (
//               <Box
//                 onClick={(e) => e.stopPropagation()}
//                 onDoubleClick={(e) => e.stopPropagation()}
//                 sx={{
//                   position: "absolute",
//                   bottom: "5%",
//                   left: "5%",
//                   right: "5%",
//                   backgroundColor: "#FFFFFF",
//                   display: "flex",
//                   alignItems: "center",
//                   width: "90%",
//                   padding: {
//                     xs: "4px",
//                     sm: "10px",
//                   },
//                   borderRadius: "100px",
//                   justifyContent: "space-between",
//                   gap: 2,
//                   boxShadow: `0px ${pxToRem(12)} ${pxToRem(24)} rgba(0,0,0,0.16)`,
//                 }}
//               >
//                 <Box
//                   sx={{
//                     display: "flex",
//                     alignItems: "center",
//                     gap: { xs: 1, sm: 2, md: 3 },
//                     marginLeft: "30px",
//                   }}
//                 >
//                   <MediaSeekButton seconds={-10}>
//                     <MediaTooltip position="top center">
//                       <span>Seek -10s</span>
//                     </MediaTooltip>
//                     <Replay10Icon
//                       sx={{
//                         fontSize: { xs: 24, sm: 30, md: 36 },
//                         color: "#026670",
//                         "&:hover": { color: "#FCE181" },
//                       }}
//                     />
//                   </MediaSeekButton>

//                   <MediaPlayButton>
//                     {isPaused ? (
//                       <>
//                         <MediaTooltip position="top center">
//                           <span>Play</span>
//                         </MediaTooltip>
//                         <PlayCircleOutlineIcon
//                           sx={{
//                             fontSize: { xs: 24, sm: 30, md: 36 },
//                             color: "#026670",
//                             "&:hover": { color: "#FCE181" },
//                           }}
//                         />
//                       </>
//                     ) : (
//                       <>
//                         <MediaTooltip position="top center">
//                           <span>Pause</span>
//                         </MediaTooltip>
//                         <PauseCircleOutlineIcon
//                           sx={{
//                             fontSize: { xs: 24, sm: 30, md: 36 },
//                             color: "#026670",
//                             "&:hover": { color: "#FCE181" },
//                           }}
//                         />
//                       </>
//                     )}
//                   </MediaPlayButton>

//                   <MediaSeekButton seconds={10}>
//                     <MediaTooltip position="top center">
//                       <span>Seek +10s</span>
//                     </MediaTooltip>
//                     <Forward10Icon
//                       sx={{
//                         fontSize: { xs: 24, sm: 30, md: 36 },
//                         color: "#026670",
//                         "&:hover": { color: "#FCE181" },
//                       }}
//                     />
//                   </MediaSeekButton>
//                 </Box>

//                 <MediaTimeSlider
//                   style={{
//                     flex: 1,
//                     margin: "0 15px",
//                     display: { xs: "none", sm: "block" },
//                   }}
//                 >
//                   <MediaSliderValue
//                     type="pointer"
//                     format="time"
//                     slot="preview"
//                   />
//                 </MediaTimeSlider>

//                 <Box
//                   sx={{
//                     display: "flex",
//                     alignItems: "center",
//                     gap: { xs: 1, sm: 2, md: 3 },
//                     marginRight: "30px",
//                   }}
//                 >
//                   <Box
//                     sx={{
//                       display: { xs: "none", md: "flex" },
//                       alignItems: "center",
//                     }}
//                   >
//                     <IconButtonStyled
//                       sx={{
//                         "& svg": {
//                           width: { xs: 18, sm: 20, md: 22 },
//                           transition: "color 0.3s ease-in-out",
//                         },
//                         "&:hover": {
//                           "& svg": {
//                             color: "#026670",
//                           },
//                           "& media-volume-slider": {
//                             display: "block",
//                             opacity: 1,
//                           },
//                         },
//                         "& media-volume-slider": {
//                           display: "none",
//                           opacity: 0,
//                           transition: "opacity 0.3s ease-in-out",
//                         },
//                       }}
//                     >
//                       <Volume color="#026670" />
//                       <MediaVolumeSlider>
//                         <MediaSliderValue
//                           type="pointer"
//                           format="percent"
//                           slot="preview"
//                         />
//                       </MediaVolumeSlider>
//                     </IconButtonStyled>
//                   </Box>

//                   <Box
//                     sx={{
//                       display: "flex",
//                       alignItems: "center",
//                       fontSize: {
//                         xs: pxToRem(14),
//                         sm: pxToRem(16),
//                         md: pxToRem(18),
//                       },
//                     }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: {
//                           xs: pxToRem(10),
//                           sm: pxToRem(11),
//                           md: pxToRem(12),
//                         },
//                         fontWeight: "bold",
//                         color: "#026670",
//                         display: "flex",
//                         gap: pxToRem(4),
//                         "& span": {
//                           fontFamily: "Roboto Mono",
//                         },
//                       }}
//                     >
//                       <MediaTime type="current" /> <span>/</span>{" "}
//                       <MediaTime type="duration" />
//                     </Typography>
//                   </Box>

//                   <MediaFullscreenButton>
//                     {isFullscreen ? (
//                       <>
//                         <MediaTooltip position="top center">
//                           <span>Exit Fullscreen</span>
//                         </MediaTooltip>
//                         <FullscreenExitIcon
//                           sx={{
//                             fontSize: { xs: 24, sm: 30, md: 36 },
//                             color: "#026670",
//                             "&:hover": { color: "#FCE181" },
//                           }}
//                         />
//                       </>
//                     ) : (
//                       <>
//                         <MediaTooltip position="top center">
//                           <span>Enter Fullscreen</span>
//                         </MediaTooltip>
//                         <FullscreenIcon
//                           sx={{
//                             fontSize: { xs: 24, sm: 30, md: 36 },
//                             color: "#026670",
//                             "&:hover": { color: "#FCE181" },
//                           }}
//                         />
//                       </>
//                     )}
//                   </MediaFullscreenButton>
//                 </Box>
//               </Box>
//             )}
//           </MediaPlayer>
//         ) : (
//           <Typography
//             fontFamily="PloniDBold"
//             sx={{
//               fontSize: {
//                 xs: pxToRem(16),
//                 sm: pxToRem(18),
//                 md: pxToRem(20),
//               },
//               fontWeight: "600",
//               color: "#666564",
//               paddingLeft: pxToRem(20),
//               textAlign: "right",
//             }}
//           >
//             Not Found
//           </Typography>
//         )}
//       </Box>
//     </>
//   );
// }

// const styles = {
//   videoContainer: {
//     position: "relative",
//     height: "90vh",
//     width: "calc(90vh * 16 / 9)",
//     // backgroundColor: "#000",
//     margin: "0 auto",
//     // borderRadius: "20px",
//   },
//   mediaPlayer: {
//     position: "absolute",
//     top: 0,
//     left: 0,
//     width: "100%",
//     height: "100%",
//   },
//   centerIcon: {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     color: "#fff",
//     opacity: 0.8,
//     zIndex: 10,
//   },
// };
import React from "react";
import { Box, Typography } from "@mui/material";
import { styled, useMediaQuery } from "@mui/system";
import { useTheme } from "@emotion/react";
import BlockBorderedRight from "./BlockBorderedRight";
import { pxToRem } from "px2rem2px";

const IframeStyled = styled("iframe")({
  border: "none",
  borderRadius: "20px",
  width: "100%",
  aspectRatio: "16 / 9", // Using aspect-ratio CSS property for a 16:9 ratio
  overflow: "hidden",
});

export default function VideoSectionComponent({ videoSrc, title }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box
        sx={{
          width: "100%",
          maxWidth: "1280px",
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: isMobile ? "100%" : "80%",
          }}
        >
          <BlockBorderedRight>
            <Typography
              fontFamily="PloniDBold"
              sx={{
                fontSize: {
                  xs: pxToRem(16),
                  sm: pxToRem(18),
                  md: pxToRem(20),
                },
                fontWeight: "600",
                color: "#666564",
                paddingLeft: pxToRem(20),
                textAlign: "right",
              }}
              className="rtl-text-temp-player"
              dir="rtl"
            >
              {title}
            </Typography>
          </BlockBorderedRight>
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          maxWidth: "1280px",
          margin: "20px auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: isMobile ? "100%" : "80%",
          }}
        >
          <IframeStyled
            src={videoSrc}
            allow="autoplay; fullscreen"
            title="Google Drive Video"
          />
        </Box>
      </Box>
    </>
  );
}
