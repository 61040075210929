import { Box } from "@mui/material";
import { pxToRem } from "px2rem2px";

const BlockBorderedRight = (props) => {
  return (
    <Box
      sx={{ 
        display: "flex", 
        columnGap: { xs: pxToRem(22), sm: pxToRem(28) }, 
        order: "3", 
        justifyContent: "flex-end" 
      }}
      mb={props.mb}
    >
      <Box width="100%" sx={{ textAlign: "right", direction: "rtl" }}>
        {props.children}
      </Box>
      <Box
        sx={{
          width: { xs: 0, sm: pxToRem(3) },
          backgroundColor: "#BFBEBB",
          ...(props.mr && { marginRight: "20px" }),
          ...props.sx,
        }}
      />
    </Box>
  );
};

export default BlockBorderedRight;
