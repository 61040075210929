import api from "./api";

export function logout() {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("studentData");
  localStorage.removeItem("user");
}

export const captchaKey = "6LfYy6UpAAAAAFnClLDFNzbuJoKrjKyUTcVGj-Hf";
