import { Avatar, Box, Button, Divider, Stack, Typography } from "@mui/material";
import { pxToRem } from "px2rem2px";
import InputAccountEdit from "./InputAccountEdit";
import BlockHeading from "../../../components/BlockHeading";

import { getStudent } from "../../../api/student";
import { useEffect, useState } from "react";
import EditProfilePicture from "./EditProfilePicture";
import ChangePasswordModal from "./ChangePasswordModal";

import EmailInputEdit from "./EmailInputEdit";
const AccountSettings = () => {
  const [openProfilePictureModal, setOpenProfilePictureModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  // const [emailUpdated,setEmailUpdated]=useState(false)
  const [studentInfo, setStudentInfo] = useState({
    id: "",
    user: {
      id: "",
      first_name: "",
      last_name: "",
      email: "",
      is_active: true,
    },
    phone_number: "",
    spouse: null,
    avatar: "",
  });

 
  function fetchStudent() {
    getStudent()
      .then((res) => {
        console.log("res", res);
        setStudentInfo(res.data);
        return res.data
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    fetchStudent();
  }, [openEmailModal]);

  useEffect(() => {
    setPhoneNumber(studentInfo.phone_number || "");
  }, [studentInfo]);
  return (
    <>
      <BlockHeading>
        <Typography component="h3" variant="blockTitle">
        Account settings
        </Typography>
      </BlockHeading>

      <Stack
        flexDirection={{ xs: "column-reverse", ssm: "row" }}
        justifyContent="space-between"
        alignItems={"center"}
        gap={{ xs: "30px", ssm: "15px" }}
        mb={pxToRem(60)}
      >
        <Stack flex={1} gap={pxToRem(20)}>
          <InputAccountEdit
            label="First Name"
            editLabel="first_name"
            value={studentInfo.user.first_name}
            type="text"
            rules={{
              required: "Field can't be empty",
              minLength: {
                value: 3,
                message: "Minimum 3 characters",
              },
              maxLength: {
                value: 50,
                message: "Maximum 50 characters",
              },
            }}
          />

          <InputAccountEdit
            label="Last Name"
            editLabel="last_name"
            value={studentInfo.user.last_name}
            type="text"
            rules={{
              required: "Field can't be empty",
              minLength: {
                value: 3,
                message: "Minimum 3 characters",
              },
              maxLength: {
                value: 50,
                message: "Maximum 50 characters",
              },
            }}
          />
          <InputAccountEdit
            label={"Nickname"}
            editLabel="nickname"
            value={studentInfo.nickname}
            type="text"
            rules={{
              minLength: {
                value: 3,
                message: "Minimum 3 characters",
              },
              maxLength: {
                value: 50,
                message: "Maximum 50 characters",
              },
            }}
          />

          <InputAccountEdit
            phone
            label="Phone"
            editLabel="phone_number"
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            rules={{
              required: "Field can't be empty",
            }}
          />

          <EmailInputEdit
            label="Email Address"
            editLabel="email"
            value={studentInfo.user.email}
            type="email"
            rules={{
              required: "Field can't be empty",
              maxLength: {
                value: 100,
                message: "Maximum 100 characters",
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              },
            }}
            fetchStudent={fetchStudent}
            originalEmail={studentInfo.user.email}
          />
        </Stack>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Avatar sx={{ width: pxToRem(150), height: pxToRem(150) }}>
            <img src={studentInfo.avatar} alt="" />
          </Avatar>{" "}
          <Button
            size="small"
            variant="outlined"
            sx={{ mt: pxToRem(15), width: pxToRem(150), fontWeight: 500 }}
            onClick={() => {
              setOpenProfilePictureModal(true);
            }}
          >
            Update Picture
          </Button>
          <Button
            size="small"
            variant="outlined"
            sx={{ mt: pxToRem(10), width: pxToRem(150), fontWeight: 500 }}
            onClick={() => setOpenPasswordModal(true)}
          >
            Change Password
          </Button>
        </Box>
      </Stack>
      <EditProfilePicture
        open={openProfilePictureModal}
        setOpen={setOpenProfilePictureModal}
        update={fetchStudent}
      />
      <ChangePasswordModal
        open={openPasswordModal}
        setOpen={setOpenPasswordModal}
      />
    </>
  );
};

export default AccountSettings;
