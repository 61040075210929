import { useState, useEffect } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { pxToRem } from "px2rem2px";
import FormInputText from "../../../components/FormInputText";
import { useForm, Controller } from "react-hook-form";

import FormInputPhone from "../../../components/FormInputPhone";
import { editProfile } from "../../../api/student";
import { saveStudentLocal } from "../../../utils/AuthContext";

const InputAccountEdit = (props) => {
  const inputStyles = {
    "& .MuiInputBase-root": {
      borderRadius: pxToRem(30),
      height: pxToRem(54),
      outline: "2px solid #333",
      outlineOffset: "-2px",
      backgroundColor: "#fff",
      width: props.phone ? "100%" : "268px",
      "&.Mui-disabled": {
        backgroundColor: "#EDECE8",
        outline: "none",
      },
      "&.Mui-error": {
        outlineColor: "#d32f2f",
      },
    },
    "& input": {
      borderRadius: `${pxToRem(30)} !important`,
      padding: `${pxToRem(15)} ${pxToRem(40)}`,
      color: "#333",
      fontWeight: 600,
      "&.Mui-disabled": {
        color: "#BFBEBB",
      },
    },
  };

  const [savedValue, setSavedValue] = useState(props.value || "");
  const [disabled, setDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setSavedValue(props.value);
  }, [props.value]);

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    values: {
      value: props.value || "",
    },
  });

  const onSubmit = (data) => {
    setIsSubmitting(true);
    let payload = {};
    if (props.editLabel === "nickname") {
      payload[props.editLabel] = data.value.toLowerCase();
    } else if (props.phone) {
      payload = { phone_number: props.phoneNumber || "" };
    } else {
      payload = { user: {} };
      payload.user[props.editLabel] = data.value;
    }
    // console.log("payload is", payload);
    editProfile(payload)
      .then((res) => {
        setIsSubmitting(false);
        setSavedValue(data.value);
        setDisabled(true);
        saveStudentLocal().then((res) => {
          if (
            props.editLabel === "first_name" ||
            props.editLabel === "last_name"
          ) {
            window.location.href = "/user/account-settings";
          }
        });
      })
      .catch((err) => {
        setIsSubmitting(false);

        // console.log('error',err)

        if (err.response && err.response.data) {
          // console.log("Error response data:", err.response.data);
          if (err.response.data.phone_number) {
            setError("phoneNumber", {
              type: "manual",
              message: err.response.data.phone_number[0],
            });
          } else if (err.response.data.nickname) {
            setError("value", {
              type: "manual",
              message: err.response.data.nickname[0],
            });
          }
        } else {
          console.log("Error encountered:", err.message);
        }
      });
  };

  const resetForm = () => {
    setValue("value", savedValue, { shouldValidate: true });
    setDisabled(true);
    clearErrors();
  };

  return (
    <Stack
      flexDirection={{ xs: "column", lg: "row" }}
      alignItems={{ xs: "flex-start", lg: "flex-start" }}
      gap={{ xs: "10px", lg: 0 }}
    >
      <Typography
        width={{ xs: "auto", lg: "130px" }}
        mt={{ xs: 0, lg: "13px" }}
        variant="medium"
      >
        {props.label}
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack flex={1} flexDirection="row" gap={pxToRem(20)}>
          <Box maxWidth="268px">
            {props?.phone ? (
              <FormInputPhone
                placeholder="Phone"
                rules={{
                  required: "Field can't be empty",
                }}
                disabled={disabled}
                value={props.phoneNumber}
                onChange={(data) => {
                  props.setPhoneNumber(data.phone);
                  clearErrors("phoneNumber");
                }}
                muiProps={{
                  disabled: disabled,
                  sx: inputStyles,
                }}
                error={!!errors?.phoneNumber}
                helperText={
                  errors?.phoneNumber ? errors?.phoneNumber?.message : ""
                }
              />
            ) : (
              <FormInputText
                name="value"
                control={control}
                setValue={setValue}
                rules={props.rules}
                muiProps={{
                  type: props.type,
                  disabled: disabled,
                  sx: inputStyles,
                }}
                error={!!errors?.value}
                helperText={errors?.value ? errors?.value?.message : ""}
              />
            )}
          </Box>

          <Stack alignItems="flex-start" width="42px">
            {disabled ? (
              <Button
                onClick={() => setDisabled(false)}
                sx={{ fontSize: "12px", mt: "16px" }}
              >
                Edit
              </Button>
            ) : (
              <Stack alignItems="flex-start" mt="10px">
                <Button
                  onClick={resetForm}
                  sx={{ color: "#BFBEBB", fontSize: "12px" }}
                >
                  Cancel
                </Button>
                <Button type="submit" sx={{ fontSize: "12px" }}>
                  Save
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};

export default InputAccountEdit;
