import axios from "axios";
import api from "./api";
import { url } from "./config";



export const createCheckoutSession = async (courseId, priceType, priceClass,prolongBy) => {
  try {
    
    const response = await api.get( `/api/subscription/get-checkout-link/?course_id=${courseId}&price_type=${priceType}&price_class=${priceClass}&prolonged_by=${prolongBy}`)
    return response;
  } catch (error) {
    console.error('Error creating checkout session:', error);
    throw error;
  }
};

export const resolveCheckoutSession = async (subscriptionId) => {
  try {
    const response = await api.get( `/api/subscription/get_resolve_failure_checkout?subscription_id=${subscriptionId}`)
    return response;
  } catch (error) {
    console.error('Error creating checkout session:', error);
    throw error;
  }
};



  export const createAssociateCheckoutSession = async (courseId, email) => {
    try {
      
      const response = await api.get( `/api/subscription/get-associate-checkout-link/?course_id=${courseId}&price_type=Normal&price_class=Associate&associate_email=${email}`)
      return response;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      throw error;
    }
  };