import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Tooltip,
  Typography,
  keyframes,
} from "@mui/material";
import Guides from "../../components/Guides";
import Hero from "../../components/courses/Hero";
import Sidebar from "../../components/courses/Sidebar";
import CoursesSlider from "../../components/courses/CoursesSlider";
import ContentSlider from "../../components/courses/ContentSlider";
import CoursesSection from "./CoursesSection";
import NavGroup from "../../components/courses/NavGroup";
import MenuDropdownLink from "../../components/menuDropdown/MenuDropdownLink";
import { useCallback, useEffect, useRef, useState } from "react";
import { pxToRem } from "px2rem2px";
import BlockHeading from "../../components/BlockHeading";
import Course from "../../components/user/Course";

import { ReactComponent as Chevron } from "../../images/chevron.svg";
import { ReactComponent as FilterMenu } from "../../images/filter-menu.svg";
import { ReactComponent as Arrows } from "../../images/down-arrow.svg";
import SlideVideo1 from "../../images/video-slide2.jpg";
import SlideVideo2 from "../../images/video-slide3.jpg";
import { listCourses, listCategories } from "../../api/course";
import Loader from "../../components/Loader";
import { addFavorite, removeFavorite } from "../../api/course";
import VideoSlider from "../../pages/home/VideoSlider";
import { useAuth } from "../../utils/AuthContext";
import { useTheme, useMediaQuery } from "@mui/material";
import { useCourses } from "../../utils/CoursesContext";

const Courses = () => {
  const { checkAuth, logout } = useAuth();
  const isAuthenticated = checkAuth();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  console.log("isAuthenticated", isAuthenticated);

  const {
    courses,
    setCourses,
    page,
    setPage,
    selectedCategory,
    setSelectedCategory,
  } = useCourses();



  const [isLoading, setIsLoading] = useState(false);

  const [subscribedCourses, setSubscribedCourses] = useState([]);
  const [latestCourses, setLatestCourses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [hasMore, setHasMore] = useState(true);
  const [courseCount, setCoursesCount] = useState("");

  const [isOnSearchMode, setIsOnSearchMode] = useState(false);

  const observer = useRef();

  const lastCourseElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          console.log("Last course is visible, loading more courses...");
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );

  

  useEffect(() => {
    if (isOnSearchMode) {
      setCourses([]);
      setPage(1);
      setSelectedCategory("");
    }
    console.log(
      "Fetching courses for page:",
      page,
      "search:",
      searchQuery,
      "category:",
      selectedCategory
    );
    setIsLoading(true);
    console.log("before fetch", courses);
    const searchParam = isOnSearchMode ? searchQuery : "";

    listCourses(4, page, searchParam, selectedCategory, "", "", "")
      .then((res) => {
        if (res?.data?.results) {
          setCourses((prevCourses) => {
            const uniqueCourses = [
              ...new Map(
                [...prevCourses, ...res?.data?.results].map((item) => [
                  item.id,
                  item,
                ])
              ).values(),
            ];
            return uniqueCourses;
          });
          setHasMore(!!res.data.next);
          setCoursesCount(res.data.count);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [page, searchQuery, selectedCategory, isOnSearchMode]);

  useEffect(() => {
    listCategories()
      .then((res) => {
        if (res?.data?.results) {
          setCategories(res?.data?.results);
          console.log("categories", res.data.results);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //////////::::
  const [totalCourses, setTotalCourses] = useState(0);
  const [subscribedCoursesPageSize, setSubscribedCoursesPageSize] =
    useState(100);
  // const [subscribedCourses, setSubscribedCourses] = useState([]);

  // const [prevPage, setPrevPage] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    loadCourses(100);
  }, [subscribedCoursesPageSize]);

  const loadCourses = (page) => {
    if (isFetching) {
      console.log("loadCourses called while fetching, returning early");
      return;
    }

    console.log("loadCourses called with page:", page);
    setIsFetching(true);
    setIsLoading(true);
    listCourses(subscribedCoursesPageSize, 1, "", "", "true")
      .then((res) => {
        console.log("API response received:", res);
        if (res?.data?.results) {
          setSubscribedCourses(res.data.results);
          setTotalCourses(res?.data?.count || 0);
        }
        setIsLoading(false);
        setIsFetching(false);
      })
      .catch((err) => {
        console.log("API error:", err);
        setIsLoading(false);
        setIsFetching(false);
      });
  };

  useEffect(() => {
    const currentDate = new Date();
    const startDate = new Date();
    startDate.setDate(currentDate.getDate() - 60);

    const formattedStartDate = startDate.toISOString().slice(0, 10);
    const formattedEndDate = currentDate.toISOString().slice(0, 10);

    setIsLoading(true);
    listCourses(4, 1, "", "", "", "", "", "", formattedStartDate,formattedEndDate)
      .then((res) => {
        if (res?.data?.results) {
          setLatestCourses(res?.data?.results);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleCategoryClick = (category) => {
    setCoursesCount("");
    setPage(1);
    setCourses([]);
    setSelectedCategory(category);
  };
  const handleFavoriteClick = (courseId) => {
    const course = courses.find((c) => c.id === courseId);
    if (course.is_favorite) {
      // Remove from favorites
      removeFavorite(courseId)
        .then((res) => {
          console.log("Favorite bookmark removed successfully");
          // Update the course's is_favorite attribute
          setCourses(
            courses.map((c) =>
              c.id === courseId ? { ...c, is_favorite: false } : c
            )
          );
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // Add to favorites
      addFavorite(courseId)
        .then((res) => {
          console.log("Favorite bookmark added successfully");
          // Update the course's is_favorite attribute
          setCourses(
            courses.map((c) =>
              c.id === courseId ? { ...c, is_favorite: true } : c
            )
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    console.log("searchQuery", searchQuery);
  }, [searchQuery]);

  return (
    <Box position="relative">
      <Guides color="rgba(0,0,0,0.06)" zIndex={-1} />
      <Hero
        title="Courses"
        onSearch={handleSearch}
        courses={courses}
        setCourses={setCourses}
        setIsOnSearchMode={setIsOnSearchMode}
        isOnSearchMode={isOnSearchMode}
        setPage={setPage}
      />

      <Box position="relative">
        <Box
          sx={{
            width: isMediumScreen ? "95%" : "90%",
            margin: isMediumScreen ? "auto" : undefined,
            // backgroundColor:"green"
          }}
        >
          <Stack
            flexDirection={isMediumScreen ? "column" : "row"}
            justifyContent="space-between"
            gap="6.5%"
            mb="100px"
            // backgroundColor="pink"
            width="100%"
            maxWidth="1367px"
          >
            <Sidebar display="flex">
              <Box
                sx={{
                  display: "flex",

                  flexDirection: isSmallScreen
                    ? "column"
                    : isMediumScreen
                    ? "row"
                    : "column",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <NavGroup title="Categories" itemsLength={categories.length}>
                    {categories.map((category) => (
                      <MenuDropdownLink
                        to="#"
                        onClick={(event) => handleCategoryClick(category.id)}
                        className="overflow-ellipsis"
                        key={category?.id}
                      >
                        {category.name}
                      </MenuDropdownLink>
                    ))}
                  </NavGroup>

                  {isAuthenticated ? (
                    <>
                      {subscribedCourses.length > 0 && (
                        <NavGroup
                          title="My Courses"
                          itemsLength={subscribedCourses?.length}
                          isSubscribedCourses
                        >
                          {subscribedCourses.map((course) => (
                            <MenuDropdownLink
                              key={course.slug}
                              to={`/course/${course.slug}`}
                              className="overflow-ellipsis"
                            >
                              {course.name}
                            </MenuDropdownLink>
                          ))}
                        </NavGroup>
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                  {latestCourses?.length > 0 && (
                    <NavGroup
                      title="Recently Added"
                      itemsLength={latestCourses.length}
                    >
                      {latestCourses.map((course) => (
                        <MenuDropdownLink
                          to={`/course/${course.slug}`}
                          className="overflow-ellipsis"
                        >
                          {course.name}
                        </MenuDropdownLink>
                      ))}
                    </NavGroup>
                  )}
                </Box>
                <Box sx={{width:"90%"}}>
                  <Typography
                    fontSize="14px"
                    lineHeight="18px"
                    fontWeight={600}
                    mb="20px"
                    textAlign="center"
                  >
                    Receive notifications for new courses!
                  </Typography>
                  <Button
                    fullWidth
                    variant="outlined"
                    size="small"
                    sx={{ fontSize: "14px !important" }}
                  >
                    Get Notified
                  </Button>
                </Box>

                {!isMediumScreen && (<Divider sx={{width:"90%",margin:"20px 0"}}/>) }

                <Box
                  sx={{
                    marginLeft: isSmallScreen
                      ? "0px"
                      : isMediumScreen
                      ? "30px"
                      : "0",
                  }}
                >
                  <Stack
                    gap="15px"
                    sx={{
                      display: "flex",
                      flexDirection: isSmallScreen ? "row" : "column",
                      justifyContent: "center",
                      "& a": { fontSize: "14px" },
                    }}
                  >
                    <MenuDropdownLink to="/">Account</MenuDropdownLink>
                    <MenuDropdownLink to="/">FAQ</MenuDropdownLink>
                    <MenuDropdownLink to="/">Help</MenuDropdownLink>
                  </Stack>
                </Box>
              </Box>
            </Sidebar>

            <Box flex={1} width={isMediumScreen ? "100%" : "70%"}>
              {isAuthenticated ? <CoursesSlider /> : <></>}
              <CoursesSection
                courses={courses}
                onFavoriteClick={handleFavoriteClick}
                isAuthenticated={isAuthenticated}
                lastCourseElementRef={lastCourseElementRef}
                loading={isLoading}
                courseCount={courseCount}
                isOnSearchMode={isOnSearchMode}
                categoriesList={categories}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                setCourses={setCourses}
                setPage={setPage}

                // ref={scrollToCourses}
              />
            </Box>
          </Stack>
        </Box>
      </Box>

      <Container disableGutters>
        <ContentSlider />
      </Container>
    </Box>
  );
};

export default Courses;
