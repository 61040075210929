import { useEffect, useState } from "react";
import api from "../../api/api";
import {
  Box,
  Typography,
  LinearProgress,
  linearProgressClasses,
  Link,
  useMediaQuery,
  useTheme,
  Skeleton,
} from "@mui/material";
import { SwiperSlide } from "swiper/react";
import Slider from "../Slider";
import Course from "./Course";
import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import { pxToRem } from "px2rem2px";
import TextLink from "../../components/TextLink";
import PlayBtn from "../../components/PlayBtn";

import SlideWide from "../../images/video-slide-wide.jpg";
import SlideVideo1 from "../../images/video-slide2.jpg";
import SlideVideo2 from "../../images/video-slide3.jpg";
import { ReactComponent as Play } from "../../images/play.svg";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: "100%",
  position: "absolute",
  bottom: 0,
  height: 5,
  borderRadius: 0,
  zIndex: 1,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#717171",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: "#FCE181",
  },
}));



const HoverBox = styled(Box)(({ theme }) => ({
  transition: "0.2s ease",
  padding: "10px 10px 25px 10px",
  borderRadius: "20px",
  height: "85%", 
  backgroundColor:"rgb(237, 236, 232,0.3)",
  "&:hover": {
    backgroundColor: "rgb(237, 236, 232)",
  },
}));

const CoursesSlider = () => {
  const [videos, setVideos] = useState([]);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("djndjndjnd", videos);
  }, [videos]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    const fetchLatestWatchedCourses = async () => {
      try {
        const response = await api.get("/api/courses/latest-watched-courses/", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        console.log("fetchLatestWatchedCourses",response.data.results)
        setVideos(response.data.results);
      } catch (error) {
        console.error("Error fetching latest watched courses:", error);
      }
    };

    fetchLatestWatchedCourses();
  }, []);

  return (
    <>
      {videos?.length > 0 && (
        <>
          <Typography
            variant="sectionTitle"
            component="h2"
            fontSize="35px"
            mb="25px"
          >
            <span className="highlighted">Continue</span> Watching
          </Typography>

          <Box
            margin={{ xs: "0px", sm: "0px" }}
            sx={{
              backgroundColor: "#fff",
              borderRadius: "20px",
              p: { xs: "10px", sm: "30px 35px" },

              "& .MuiContainer-root": {
                p: 0,
              },
              "& .swiper": {
                p: { xs: "0 0 0 0", sm: "10px" },
                m: { xs: 0, sm: "-10px -10px 0" },
                "@media(max-width: 1330px)": {
                  pb: "10px !important",
                },
              },
              "& .swiper-slide": { width: "auto" },
              "& .arrow-prev": { left: "-70px", zIndex: 1 },
              "& .arrow-next": { right: "-70px", zIndex: 1 },
            }}
          >
            <Slider
              slidesPerView={1}
              spaceBetween={10}
              arrows
              breakpoints={{
                300: {
                  slidesPerView: 2,
                },
                406: {
                  slidesPerView: 3,
                },
                537: {
                  slidesPerView: 4,
                },
                654: {
                  slidesPerView: 5,
                },
                767: {
                  slidesPerView: 4,
                },
                900: {
                  slidesPerView: 3,
                },
                1017: {
                  slidesPerView: 4,
                },
                1200: {
                  slidesPerView: 4,
                },
              }}
            >
              {Array.isArray(videos) &&
                videos.map((v) => (
                  <SwiperSlide
                    key={v.id}
                    style={{
                      display:"flex",
                      alignItems: "stretch",
                      height: mobile? "160px":"200px",
                    }}
                  >
                    {/* Make the SwiperSlide a flex container */}
                    <HoverBox>
                      {loading ? (
                        <Skeleton
                          variant="rounded"
                          animation="wave"
                          sx={{ borderRadius: "20px", flexGrow: 1 }}
                        >
                          <VideoSlide
                            key={v.id}
                            image={v.thumb_nail}
                            videoLink={`/course/${v.slug}`}
                            progress={v.progress}
                            title={v.name}
                            isMobile={mobile}
                          />
                        </Skeleton>
                      ) : (
                        <>
                          <VideoSlide
                            key={v.id}
                            image={v.thumb_nail}
                            videoLink={`/course/${v.slug}`}
                            progress={v.progress}
                            isMobile={mobile}
                          />
                        </>
                      )}

                      <Typography
                        className="slideTitle"
                        fontSize={pxToRem(14)}
                        lineHeight={pxToRem(20)}
                        fontWeight={600}
                        mt={pxToRem(14)}
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: "2",
                          WebkitBoxOrient: "vertical",
                          textAlign: "center",
                        }}
                      >
                        {v.name}
                      </Typography>
                    </HoverBox>
                  </SwiperSlide>
                ))}
            </Slider>
          </Box>
        </>
      )}
    </>
  );
};

const VideoSlide = (props) => {
  return (
    <>
      <Box
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
        width={{ xs: "100%", sm: "auto" }}
        height={{ xs: "100px", sm: pxToRem(150) }} // Adjust the height values here
        borderRadius="19px"
        overflow="hidden"
      >
        <Link
          to={props.videoLink}
          component={NavLink}
          sx={{ position: "absolute", zIndex: 1 }}
        >
          <PlayBtn
            width={{ xs: "40px", sm: pxToRem(66) }}
            height={{ xs: "40px", sm: pxToRem(66) }}
          >
            <Play
              color="#026670"
              width={pxToRem(20)}
              height={pxToRem(24)}
              style={{ marginLeft: "6%" }}
            />
          </PlayBtn>
        </Link>
        <Box position="absolute" width="0.1px">
          <PlayBtn />
        </Box>
        <BorderLinearProgress
          className="video-progress-bar"
          variant="determinate"
          value={props.progress}
        />
        <img
          style={{
            objectFit: "cover",
            width: "100%",
            height: "100%",
            borderRadius: pxToRem(20),
            clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
          }}
          src={props.image}
          alt=""
        />
      </Box>
    </>
  );
};

export default CoursesSlider;
